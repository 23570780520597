import {
    UPDATE_AUTHENTICATING,
    UPDATE_AUTH_STATUS,
    SET_AUTH_USER,
    SET_AUTH_GROUPS,
    SWITCH_PLAYER_MODE,
    UPDATE_USER_FILTERS,
    CLEAR_USER_FILTERS,
    UPDATE_USER_VOCAL_TYPE,
    UPDATE_USER_BPM,
    UPDATE_USER_DURATION,
    UPDATE_CURRENT_ROUTE,
    LOAD_LOCAL_PLAYLIST,
    UPDATE_LOCAL_PLAYLIST,
    UPDATE_FILTER_DRAWER_STATUS,
    UPDATE_SAVED_PLAYLIST_COUNT
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';

export const updateAuthenticating = (status) => dispatch => {
    dispatch({
        type: UPDATE_AUTHENTICATING,
        payload: status
    })
}

export const updateAuthStatus = (status) => dispatch => {
    dispatch({
        type: UPDATE_AUTH_STATUS,
        payload: status
    })
}

export const setAuthUser = (user) => dispatch => {
    dispatch({
        type: SET_AUTH_USER,
        payload: user
    })
}

export const setAuthGroups = (groups) => dispatch => {
    dispatch({
        type: SET_AUTH_GROUPS,
        payload: groups
    })
}

export const updateUserFilters = (type, value) => dispatch => {
    var updateData = { type: value };
    dispatch({
        type: UPDATE_USER_FILTERS,
        payload: updateData
    })
}

export const clearUserFilters = () => dispatch => {
    dispatch({
        type: CLEAR_USER_FILTERS,
    })
}

export const updateUserVocalType = (vocaltype) => dispatch => {
    dispatch({
        type: UPDATE_USER_VOCAL_TYPE,
        payload: vocaltype
    })
}

export const updateUserBpm = (bpm) => dispatch => {
    dispatch({
        type: UPDATE_USER_BPM,
        payload: bpm
    })
}

export const updateUserDuration = (duration) => dispatch => {
    dispatch({
        type: UPDATE_USER_DURATION,
        payload: duration
    })
}

export const updateCurrentRoute = (route) => dispatch => {
    dispatch({
        type: UPDATE_CURRENT_ROUTE,
        currentRoute: route
    })
}

export const switchPlayerMode = (mode) => dispatch => {
    dispatch({
        type: SWITCH_PLAYER_MODE,
        payload: mode
    })
}

export const addToPlaylist = (track) => dispatch => {

    var currentUser = FlikTraxStore.getState().user;
    var isAuthenticated = currentUser.isAuthenticated;
    switch (isAuthenticated) {
        default:
            var userID = currentUser.user.username;
            axios({
                baseURL: apiconfig.api.APIURL + 'userplaylist',
                method: 'post',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                data: {
                    cmd: 'add',
                    userid: userID,
                    trackid: track
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    //console.log(response.data.playlist)
                })
    }

}

export const removeFromPlaylist = (track) => dispatch => {


    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    if (userID !== null) {
        axios({
            baseURL: apiconfig.api.APIURL + 'userplaylist',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'delete',
                userid: userID,
                trackid: track
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                console.log(response.data)
            })
    }
}

export const clearDefaultPlaylist = () => dispatch => {


    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    if (userID !== null) {
        axios({
            baseURL: apiconfig.api.APIURL + 'userplaylist',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'clear',
                userid: userID,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                console.log(response.data)
            })
    }
}

export const loadLocalPlaylist = () => dispatch => {

    
    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    dispatch(apiIsLoading(true));

    axios({
        baseURL: apiconfig.api.APIURL + 'userplaylist',
        method: 'post',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        data: {
            userid: userID,
        },
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            var oPlaylist = Object.keys(response.data.playlist);
            dispatch({
                type: LOAD_LOCAL_PLAYLIST,
                payload: oPlaylist
            });

            dispatch(apiIsLoading(false));
        })
}

export const updatePlaylistFromLocal = (data) => dispatch => {
    dispatch({
        type: UPDATE_LOCAL_PLAYLIST,
        payload: data
    })
}

export const updateFilterDrawerStatus = (status) => dispatch => {
    dispatch({
        type: UPDATE_FILTER_DRAWER_STATUS,
        payload: status
    })
}

export const apiIsLoading = (bool) => {
    return {
        type: "AXIOS_IS_LOADING",
        payload: {
            isLoading: bool
        }
    };
};

export const updateSavedPlaylistCount = (count) => dispatch => {
    dispatch({
        type: UPDATE_SAVED_PLAYLIST_COUNT,
        payload: count
    })
}

export const saveDefaultPlaylist = (playlisttitle) => dispatch => {

    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    if (userID !== null) {
        axios({
            baseURL: apiconfig.api.APIURL + 'userplaylist',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'save',
                userid: userID,
                track_title: playlisttitle
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                dispatch({
                    type: UPDATE_SAVED_PLAYLIST_COUNT,
                    payload: response.data.playlistCount
                })
            })
    }
}

export const loadSavedPlaylistCount = () => dispatch => {
    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    if (userID !== null) {
        axios({
            baseURL: apiconfig.api.APIURL + 'userplaylist',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'countsp',
                userid: userID,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                dispatch({
                    type: UPDATE_SAVED_PLAYLIST_COUNT,
                    payload: response.data.playlist_count
                })
            })
    }

}

export const deleteSavedPlaylist = (playlistId) => dispatch => {

    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;

    if (userID !== null) {
        axios({
            baseURL: apiconfig.api.APIURL + 'userplaylist',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'remove',
                userid: userID,
                trackid: playlistId
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                console.log(response.data)
            })
    }
}

export const downloadPlaylist = (playlistId) => dispatch => {

    var currentUser = FlikTraxStore.getState().user;
    var userID = currentUser.user.username;
    var userEmail = currentUser.user.attributes.email;

    axios({
        baseURL: apiconfig.api.APIURL + 'userplaylist',
        method: 'post',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        data: {
            cmd: 'download',
            userid: userID,
            useremail:userEmail,
            playlistid: playlistId,
            template: 'production'
        },
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            console.log(response.data)
        })
}

export const smsNotify = (subject, message) => dispatch => {
    axios({
        baseURL: apiconfig.api.APIURL + 'services',
        method: 'post',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        data: {
            service: 'notify-service',
            'subject': subject,
            'message': encodeURIComponent(message)
        },
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            console.log(response.data)
        })
}