import React, { Component } from 'react'
import '../css/Pricing.css';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


export class Pricing extends Component {

    constructor(props) {
        super(props);

        const cardcontent = [
            {
                type: "PERSONAL USE",
                price: "$2.95",
                use: "Home Videos, Social Media Videos (YouTube, Vimeo etc)",
                description: "Personal content that does not advertise or promote a product or service."
            },
            {
                type: "CREATIVE",
                price: "$29.95",
                use: "Includes student films, corporate videos, podcasts, wedding videos, educational videos, webisodes etc.",
                description: "*This license does not cover Advertising or Television Broadcasts"
            },
            {
                type: "ADVERTISING",
                price: "Includes TV, Radio, Web/Digital Advertising and In Store Usages.",
                use: "",
                description: '<a style="text-decoration:underline" href="mailto:info@fliktrax.com">Contact FlikTrax</a> for rates. FlikTrax offers a number of rate packages for all areas of professional media.'
            },
        ];

        this.cardcontent = cardcontent;



    }

    render() {
        return (
            <section id="pricing">
                <header>

                    <Typography component="h2">
                        <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                            <h2>PRICING</h2>
                        </Box>
                    </Typography>
                </header>

                <div className="content">
                    <Grid container spacing={5}>
                        {this.cardcontent.map((card, index) => (
                            <Grid item xs={12} sm={4} >
                                <Card container style={{ minWidth: 250, minHeight: 450, textAlign: "center" }}>
                                    <CardHeader style={{ backgroundColor: "#66A0D2", color: "white" }} title={card.type} />
                                    <CardContent >
                                        <span style={{ color: "black", fontFamily: "Gibson W01 SemiBold", fontSize: "1.5em", fontWeight: "900" }}>{card.price}</span>

                                        <p style={{ marginTop: 20, fontFamily: "Gibson W01 Regular", fontWeight: "500" }}>{card.use}</p>

                                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                        <p style={{ marginTop: 20, fontWeight: "500" }}>{renderHTML(card.description)}</p>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                </div>
                <a href="#footer" className="goto-next scrolly">Next</a>
            </section>

        )
    }
}

export default Pricing