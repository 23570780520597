import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchFilterData } from '../../../FlikTraxStore/Actions/filterActions';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Moods extends Component {

    constructor(props) {
        super(props);
        this.currentMoods = [];
    }

    handleMoodCheckboxChange = (event) => {
        var selectedMood = event.target.value.toString();
        var inArray = require('in-array');
        var tchecked = event.target.checked;

        var currentMoods = this.props.user.userMoods;

        if (tchecked && !inArray(currentMoods, selectedMood)) {
            currentMoods && currentMoods.push(event.target.value);
            this.props.updateUserFilters(currentMoods);
        }

        if( !tchecked && inArray(currentMoods, selectedMood) ){
            var rmIndex = currentMoods.indexOf(selectedMood);
            currentMoods.splice(rmIndex, 1);
            this.props.updateUserFilters(currentMoods);
        }
        
    }

    componentDidMount() {
        this.props.fetchFilterData('moods');
    }

    render() {
        var moodsData = this.props.filters.moodsData;
        var moodsArray = moodsData[0];

        return (
            <div style={{ marginTop: 25 }}>
                {moodsArray && moodsArray.map((mood, index) => {
                    return (

                        <ul className=""><li>
                            <FormControlLabel className="filtercheck"
                                control={
                                    <Checkbox
                                        onChange={this.handleMoodCheckboxChange}
                                        color="secondary"
                                        value={mood.moods_id}
                                        checked={this.props.user.userMoods.includes(mood.moods_id)}
                                    />}
                                label={mood.title}
                                labelPlacement="end"
                            />
                        </li></ul>
                    );
                })}
            </div>
        )
    }


}

Moods.propTypes = {
    fetchFilterData: PropTypes.func.isRequired,
    updateUserFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    filters: state.filters,
    user: state.user
})

export default connect(mapStateToProps,
    { fetchFilterData, updateUserFilters })
    (Moods);
