import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import { switchPlayerMode } from '../../FlikTraxStore/Actions/userActions';
import { switchMasterMode, switchApiPage, switchApiUrl, downloadFliktraxFile } from '../../FlikTraxStore/Actions/apiActions';
import { updateNowPlaying, updateIsPlaying, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { removeFromPlaylist, clearDefaultPlaylist, updatePlaylistFromLocal, loadLocalPlaylist, saveDefaultPlaylist } from '../../FlikTraxStore/Actions/userActions';
import { addToShoppingCart } from '../../FlikTraxStore/Actions/cartActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import HomeSearch from '../../Header/HomeSearch';
import AudioPlayer from '../../Dashboard/AudioPlayer/AudioPlayer';
//import FlikTraxBreadcrumbs from '../../Dashboard/Breadcrumbs/Breadcrumbs'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import './css/UserPlaylist.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import ShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Divider from '@material-ui/core/Divider';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteConfirm from './DeleteConfirm';
import SaveConfirm from './SaveConfirm';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';

class UserPlaylist extends Component {

    constructor(props) {
        super(props);

        this.isAuthenticated = props.user.isAuthenticated;
        this.user = props.user.user;
        this.handleWaveformChange = this.handleWaveformChange.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.audioPlayer = React.createRef();

        this.state = {
            userPlaylist: {},
        };

        this.isDirectAccess = this.props.user.userGroups.indexOf("direct-access") > -1;
        this.updateNowPlaying = this.updateNowPlaying.bind(this);
    }

    setOpen() {
        this.setState({ open: true })
    }

    handleDrawerOpen() {
        if (this.child) {
            this.child.handleDrawerOpen();
        }
    }

    async getUserPlaylist() {
        var axiosUrl = apiconfig.api.APIURL + 'uplaylist';
        console.log()
        var userPlaylist = JSON.stringify(this.props.user.userPlaylist);

        await axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            params: {
                playlist: userPlaylist
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ userPlaylist: response.data })
            })
    }

    componentDidMount() {
        var ftap = document.getElementById("ftaudioplayer");
        if (this.props.user.audioPlayerMode) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }
        var bgElement = document.getElementById('masterdashboard');
        bgElement.classList.remove("curatedBg");
        bgElement.classList.add('defaultBg');


        setTimeout(() => {
            this.props.loadLocalPlaylist();
            this.getUserPlaylist();
        }, 1000);

    }




    handleWaveformChange = name => event => {
        this.props.switchPlayerMode(event.target.checked);
        var ftap = document.getElementById("ftaudioplayer");
        if (event.target.checked) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }
    };


    removeFromPlaylist = (e) => {
        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');
        var notification = trackTitle + ' removed from your playlist';

        this.props.removeFromPlaylist(trackId);

        setTimeout(() => {
            this.props.loadLocalPlaylist();
        }, 1000);

        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });

        var trackBlock = document.getElementById('trackBlock-' + trackId);
        trackBlock.classList.add("trackhidden");
    }

    addToShoppingCart = (e) => {

        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

        var notification = trackTitle + ' added to your shopping cart';

        this.props.addToShoppingCart(trackId);

        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }

    updateNowPlaying(event) {
        event.preventDefault();
        this.setState(this.state);
        var targetId = event.currentTarget.id;
        var nowPlaying = event.currentTarget.dataset;

        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } else {
            this.props.updateIsPlaying(true);
            this.props.updatePlayerPosition(0);
        }
    }

    downloadCompressedTrack = (e) => {
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.filecompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }

    downloadUncompressedTrack = (e) => {
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.fileuncompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }

    render() {

        var playlistData = this.state.userPlaylist;
        var plEmbeddedData = playlistData._embedded;
        var truncate = require('truncate');

        return (
            <div>
                <section id="masterdashboard">
                    <div class="container">

                        <div className="content">
                            <header>
                                <Typography component="h2">
                                    <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                        <h2>MY PLAYLIST</h2>
                                    </Box>
                                </Typography>
                            </header>
                            <div>
                                <Container>

                                    <Grid container style={{ marginTop: 25 }} spacing={3}>
                                        <Grid className="searchbar" item xs={12}>
                                            <HomeSearch rdxProps={this.props} />
                                        </Grid>


                                        <Grid className="fttoolbar" item xs={12}>
                                            {/* <div className="float-left" style={{ padding: 15 }}>
                                                <FlikTraxBreadcrumbs />
                                            </div> */}
                                            <FormControlLabel className="float-right"
                                                control={
                                                    <Switch className="audioswitch"
                                                        checked={this.props.user.audioPlayerMode}
                                                        onChange={this.handleWaveformChange('playermode')}
                                                        value="playermode"
                                                        color="secondary"
                                                        inputProps={{ 'aria-label': 'Player Mode' }}
                                                    />
                                                }
                                                label="Auto-Hide Player"
                                                labelPlacement="end"
                                            />
                                        </Grid>


                                        <Grid item xs={12} style={{ minHeight: 900, backgroundColor: "white", padding: 50 }}>
                                            <div className={'panel-body'} ref="MasterContainer">
                                                <h2>My Playlist <Chip style={{ marginLeft: 10 }} color="primary" label={plEmbeddedData && playlistData._embedded.uplaylist.length} /></h2>
                                            </div>


                                            {(plEmbeddedData && playlistData._embedded.uplaylist.length === 0) &&
                                                <SnackbarContent className="mx-auto" style={{ marginTop: 75, width: '75%' }} message="Your playlist is empty." />
                                            }
                                            <FlikTraxLoader active={this.props.api.isLoading}>


                                                {(plEmbeddedData && playlistData._embedded.uplaylist.length > 0) &&
                                                    <div className="text-nowrap text-right">
                                                        <div className="btn-group" role="group" aria-label="Actions" style={{ marginTop: '-45px' }}>
                                                            <SaveConfirm savePlaylist={this.props.saveDefaultPlaylist} enqueueMessage={this.props.enqueueSnackbar} />
                                                            <DeleteConfirm clearPlaylist={this.props.clearDefaultPlaylist} enqueueMessage={this.props.enqueueSnackbar} />
                                                        </div>
                                                    </div>
                                                }


                                                {plEmbeddedData && playlistData._embedded.uplaylist.map((data, index) => {
                                                    let dataAttrs = {
                                                        'data-source': data.preview_url,
                                                        'data-tracktitle': data.track_title,
                                                        'data-artist': data.artist_name,
                                                        'data-description': data.description,
                                                        'data-duration': data.duration,
                                                        'data-vocaltype': data.vocal_type,
                                                        'data-trackid': data.id,
                                                        'data-filecompressed': data.file_path_compressed,
                                                        'data-fileuncompressed': data.file_path_uncompressed
                                                        //'data-file': data.id
                                                    };

                                                    var trackBlock = 'trackBlock-' + data.id;

                                                    return (
                                                        <div id={trackBlock} className="row ftTable">
                                                            <div className="col-sm-1 text-left">
                                                                <span>
                                                                    <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                                                                </span>
                                                            </div>
                                                            <div className="col-8 text-left categorytitle">

                                                                <span className="trackTitle">{data.track_title}</span>  <span className="trackIdTitle"></span>
                                                                <span className="artistNameSpan">  {data.artist_name}</span>
                                                                <br />
                                                                <span className="trackDescript">{truncate(data.description, 200)}</span>

                                                            </div>
                                                            <div id="trackactionblock" className="col-sm-3 text-right text-nowrap">
                                                                <span className="trackActions">{data.duration}</span>
                                                                <Tooltip title={'Remove ' + data.track_title + ' to your playlist'} placement="top">
                                                                    <Link  {...dataAttrs} onClick={this.removeFromPlaylist.bind(this)} className="trackActions"><RemoveCircleIcon /></Link>
                                                                </Tooltip>
                                                                {!this.isDirectAccess &&
                                                                    <Tooltip title={'Add to Shopping Cart'} placement="top">
                                                                        <Link id={data.id}  {...dataAttrs} onClick={this.addToShoppingCart.bind(this)} className="trackActions"><ShoppingCartIcon /></Link>
                                                                    </Tooltip>
                                                                }
                                                                {this.isDirectAccess &&
                                                                    <React.Fragment>
                                                                        <Tooltip title="Download MP3" aria-label="artist" placement="center-top">
                                                                            <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-filecompressed'], false)}><DownloadIcon /></Link>
                                                                        </Tooltip>
                                                                        <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-top">
                                                                            <Link style={{ color: "#ca75ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-fileuncompressed'], false)} > <DownloadIcon /></Link>
                                                                        </Tooltip>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                            <div style={{ width: "100%" }}>
                                                                <Divider />
                                                            </div>
                                                        </div>
                                                    )

                                                })}
                                            </FlikTraxLoader>

                                        </Grid>


                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>

                <AudioPlayer ref={this.audioPlayer} />
            </div>

        )
    }
}

UserPlaylist.propTypes = {
    switchPlayerMode: PropTypes.func.isRequired,
    updateNowPlaying: PropTypes.func.isRequired,
    updateIsPlaying: PropTypes.func.isRequired,
    updatePlayerPosition: PropTypes.func.isRequired,
    removeFromPlaylist: PropTypes.func.isRequired,
    clearDefaultPlaylist: PropTypes.func.isRequired,
    updatePlaylistFromLocal: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    addToShoppingCart: PropTypes.func.isRequired,
    loadLocalPlaylist: PropTypes.func.isRequired,
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    downloadFliktraxFile: PropTypes.func.isRequired,
    saveDefaultPlaylist: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
    audioplayer: state.audioplayer,
    notifications: state.snackbar,
    cart: state.cart
})

export default connect(mapStateToProps,
    {
        switchPlayerMode,
        updateNowPlaying,
        updateIsPlaying,
        updatePlayerPosition,
        removeFromPlaylist,
        clearDefaultPlaylist,
        updatePlaylistFromLocal,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar,
        addToShoppingCart,
        loadLocalPlaylist,
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
        downloadFliktraxFile,
        saveDefaultPlaylist
    })(UserPlaylist);