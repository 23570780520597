import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import apiconfig from '../../config/api-config.js';
import newsletterConfig from '../../config/newsletter-config.js'

class NewsletterSignup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentview: 'form',
            mcemail: '',
            emailValid: false,
            rcHidden: true,
            rcVerified: false,
            successMsg: ""
        };
    }

    validateEmail = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
        let emailValid = this.state.mcemail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (emailValid) {
            this.setState({
                emailValid: true,
                rcHidden: false
            });
        } else {
            this.setState({
                emailValid: false,
                rcHidden: true
            });
        }
    };

    setRcToken = (value) => {
        this.setState({ rcVerified: true });
    }

    rcSubscribe = (e) => {
        if (this.state.emailValid && this.state.rcVerified) {

            var axiosUrl = apiconfig.api.APIURL + 'services';

            axios({
                baseURL: axiosUrl,
                method: 'post',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                data: {
                    service: "newsletter-subscribe",
                    email: this.state.mcemail
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    console.log(response.status);
                    this.setState({ currentview: 'success' });
                    if( response.status !== 'pending' ){
                        this.setState({ successMsg: response.title });
                    }else{
                        this.setState({ successMsg: "Thanks for signing up! Check your email to confirm our newsletter subscription." });
                    }
                    
                });
        }
    }

    renderNewsletterForm() {
        return (
            <div>
                <Typography component={'span'} className="form-newsletter">
                    <input value={this.state.username}
                        onChange={this.validateEmail}
                        type="email"
                        name="mcemail"
                        id="mcemail"
                        className="form-control"
                        placeholder="Email" autoComplete="none" required /><br />

                    {!this.state.rcHidden &&
                        <ReCAPTCHA
                            style={{ marginBottom: 15 }}
                            sitekey={newsletterConfig.newsletter.RECAPTHA_SITEKEY}
                            onChange={this.setRcToken}
                        />
                    }
                    <button onClick={this.rcSubscribe} className="btn btn-lg btn-primary btn-block btn-signin" type="submit">Sign Up</button>
                </Typography>
            </div>
        )
    }

    renderSuccessMsg() {
        return (
            <Typography component="span">
                <Box textAlign="left" fontWeight={200} fontFamily="Gibson W01 Regular">
                    {this.state.successMsg} 
                </Box>
            </Typography>
        );
    }

    render() {
        switch (this.state.currentview) {
            case 'success':
                return this.renderSuccessMsg();
            //break;

            default:
                return this.renderNewsletterForm()
        }

    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, {})(NewsletterSignup);