import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../FlikTraxStore/Actions/userActions';
import { forceUpdate } from '../FlikTraxStore/Actions/cartActions';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import PersonAdd from '@material-ui/icons/PersonAdd';
import LibraryMusic from '@material-ui/icons/LibraryMusic';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import LoginForm from './Forms/LoginForm';
import RegisterForm from './Forms/RegisterForm';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MiniShoppingCart from './Forms/MiniShoppingCart';
import './css/UserNav.css';


class ProfileTabs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      isLoginHidden: true,
      isRegisterHidden: true,
      isNewsHidden: true,
      isShoppingHidden: true,
      isCartHidden: true
    }
  }

  handleClickAway() {
    this.resetHidden();
  }

  resetHidden() {
    this.setState({
      //isLoginHidden: true,
      //isRegisterHidden: true,
      isNewsHidden: true,
      isShoppingHidden: true,
      isCartHidden: true
    });
  }

  closeLoginTab() {
    this.setState({ isLoginHidden: true })
  }

  closeRegisterTab() {
    this.setState({ isRegisterHidden: true })
  }


  toggleLoginHidden() {
    this.setState({
      isLoginHidden: !this.state.isLoginHidden
    })
  }

  toggleRegisterHidden() {
    this.setState({
      isRegisterHidden: !this.state.isRegisterHidden
    })
  }

  toggleCartHidden() {
    this.setState({
      isCartHidden: !this.state.isCartHidden
    })
  }

  LoginContainer() {
    return (
      <TabContainer>
        <div className="tabContainer">
          <LoginForm closeLoginForm={this.closeLoginTab.bind(this)} />
        </div>
      </TabContainer>
    );
  }

  RegisterContainer() {
    return (
      <TabContainer>
        <div className="tabContainer">
          <RegisterForm closeRegisterForm={this.closeRegisterTab.bind(this)} />
        </div>
      </TabContainer>
    );
  }

  CartContainer() {
    return (
      <TabContainer>
        <div className="tabContainer">
          <MiniShoppingCart />
        </div>
      </TabContainer>
    );
  }

  getScIcon() {
    //Check if local storage
    var cartItems = this.props.cart.cartContents;
    if (cartItems.length > 0) {
      this.forceCompUpdate.bind(this);
      return (
        <Badge badgeContent={cartItems.length} color="primary">
          <ShoppingCart style={{ fontSize: 30, color: 'black' }} />
        </Badge>
      )
    } else {
      return (
        <ShoppingCart style={{ fontSize: 30, color: 'black' }} />
      )
    }
  }

  getPlaylistIcon() {
    if (this.props.user.userPlaylist.length > 0) {
      return (
        <Badge badgeContent={this.props.user.userPlaylist.length} color="primary">
          <LibraryMusic style={{ fontSize: 30, color: 'black' }} />
        </Badge>
      )
    } else {
      return (
        <LibraryMusic style={{ fontSize: 30 }} />
      )
    }
  }

  handleChange = (_, activeIndex) => this.setState({ activeIndex })

  forceCompUpdate() {
    this.props.forceUpdate(true);
  }


  render() {
    const { activeIndex } = this.state;

    return (
      <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            top: 15,
            right: 0
          }}
        >
          {activeIndex === 0 && this.resetHidden.bind(this) && !this.state.isLoginHidden &&
            this.LoginContainer()
          }

          {activeIndex === 1 && this.resetHidden.bind(this) && !this.state.isRegisterHidden &&
            this.RegisterContainer()
          }

          {activeIndex === 3 && this.resetHidden.bind(this) && !this.state.isCartHidden &&
            this.CartContainer()
          }

          <VerticalTabs id="userTabs"
            style={{ width: 65, textAlign: 'center', maxHeight: 400 }}
            value={activeIndex}
            onChange={this.handleChange}
          >
            <ProfileTab id="loginTab" ref="loginTab" onClick={this.toggleLoginHidden.bind(this)} style={{ marginTop: '-15px' }} label={
              <Tooltip title="Log In" aria-label="Log In" placement="left-start">
                <Typography className="userIcons" component="div">
                  <Person style={{ fontSize: 30, color: 'black' }} />
                </Typography>
              </Tooltip>
            } />

            <ProfileTab id="registerTab" ref="registerTab" onClick={this.toggleRegisterHidden.bind(this)} style={{ marginTop: 10 }} label={
              <Tooltip title="Register" aria-label="Register" placement="left-start">
                <Typography className="userIcons" component="div">
                  <PersonAdd style={{ fontSize: 30, color: 'black' }} />
                </Typography>
              </Tooltip>
            } />

            <Link to="/my-playlist">
              <ProfileTab style={{ marginTop: 10 }} label={
                <Tooltip title="My Playlist" aria-label="My Playlist" placement="left-start">
                  <Typography className="userIcons" component="div">
                    {this.getPlaylistIcon()}
                  </Typography>
                </Tooltip>
              } />
            </Link>

            <ProfileTab onClick={this.toggleCartHidden.bind(this)} style={{ marginTop: 10, marginBottom: 25 }} label={
              <Typography className="userIcons" component="div">
                {this.getScIcon()}
              </Typography>
            } />
          </VerticalTabs>

        </div>
      </ClickAwayListener>
    )
  }
}

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column',
    marginTop: 25,
  },
  indicator: {
    display: 'none'
  }
}))(Tabs)

const ProfileTab = withStyles(theme => ({
  selected: {

  }
}))(Tab);

function TabContainer(props) {
  return (

    <div id="userNavigation">
      <Typography id="userTabs" component="div">
        <div style={{ marginTop: 55 }}>
          {props.children}
        </div>
      </Typography>
    </div>
  );
}

ProfileTabs.propTypes = {
  updateAuthStatus: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  updateAuthenticating: PropTypes.func.isRequired,
  forceUpdate: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart
})

export default connect(mapStateToProps,
  {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    forceUpdate
  })(withRouter(ProfileTabs));