import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchApiData, switchMasterMode, switchApiUrl, switchApiPage, updateTrackPageTitle } from '../../FlikTraxStore/Actions/apiActions'
//import { makeStyles } from '@material-ui/core/styles'
import FlikTraxLoader from '../Loader/Loading';
import SimplePaginator from './Paginators/SimplePaginator';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './css/tables.css';

class Categories extends Component {

    constructor() {
        super();
        this.state = {
            loading: true
        };
    }

    getCatLink = (event) => {
        event.preventDefault();
        var dataset = event.currentTarget.dataset;
        this.props.switchMasterMode('trackdetail');
        this.props.switchApiPage(1);
        this.props.updateTrackPageTitle(dataset.title);
        this.props.switchApiUrl(dataset.link);
        this.props.fetchApiData();
    }

    render() {

        var trackData = this.props.api.embeddedData[0];


        return (

            <div style={{ textAlign: 'center' }}>
                <FlikTraxLoader active={this.props.api.isLoading}>
                    <SimplePaginator />

                    <Grid container style={{ marginLeft: 50 }}
                        justify="space-evenly"
                        alignItems="center"
                        spacing={2}>
                        {trackData &&
                            trackData.map((data, index) => {
                                return (
                                    <Grid item xs={12} sm={4}>
                                        <Typography style={{
                                            textAlign: "left",
                                            fontFamily: "Gibson W01 SemiBold",
                                            fontSize: "0.90em"
                                        }}>
                                            <Link 
                                                style={{ textTransform: 'uppercase', textDecoration: "none"}} 
                                                id={data.slug} data-title={data.title} 
                                                data-link={data._link && data._link.details} 
                                                onClick={this.getCatLink} >
                                            {data.title}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                    <SimplePaginator />
                    <div style={{ marginBottom: 100 }}></div>
                </FlikTraxLoader>
            </div>
        )
    }
}

Categories.propTypes = {
    fetchApiData: PropTypes.func.isRequired,
    switchMasterMode: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    updateTrackPageTitle: PropTypes.func.isRequired,
    api: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    api: state.api
})

export default connect(mapStateToProps, { fetchApiData, switchMasterMode, switchApiUrl, switchApiPage, updateTrackPageTitle })(Categories);