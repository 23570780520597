import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Filters from '../Filters/Filters'

const useStyles = makeStyles({
  paper:{
    backgroundColor:"#0e6bae"
  },  
  list: {
    width: 150,
    overflow: "auto"
  },
  fullList: {
    width: '33%',
    backgroundColor:"#0e6bae",
  },
});

export default function FilterDrawer(props) {
  
  const classes = useStyles();
  
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });



  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const closeDrawer = () => setState({'left': false });

  const openDrawer = () => setState({'left': true });

  // if(props.setDrawerOpen){
  //   openDrawer();
  // }

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >

    </div>
  );


  return (
    <div>
      <Button style={{fontFamily:"Gibson W01 SemiBold", fontSize:"1.0em"}} onClick={toggleDrawer('left', true)} >FILTERS</Button>
      <SwipeableDrawer
        classes={{ paper: classes.fullList }}
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
        <Filters closeDrawer={closeDrawer} />
      </SwipeableDrawer>
    </div>
  );
}
