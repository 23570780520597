import React, { Component } from 'react'
import '../css/TVProsShowcase.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "../css/image-gallery.css";
import TVProsCarousel from './TVProsCarousel';


export class TVProsShowCase extends Component {

    // constructor(props) {
    //     super(props)
    //   } 
    
    render() {
        return (
            <section id="tvprosshowcase">
                <div className="content">

                <header>

                    <Typography component="h2">
                        <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                            <h2>TVPros SHOWCASE</h2>
                        </Box>
                    </Typography>
                    </header>
                    <div id="carousel-wrapper">
                        <Box alignItems="center" textAlign="center">
                        <TVProsCarousel />     
                        </Box>
                    </div>
                </div>
                <a href="#clients" className="goto-next scrolly">Next</a>
            </section>

        )
    }
}

export default TVProsShowCase