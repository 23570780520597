import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlikTraxStore from '../../../FlikTraxStore/FlikTraxStore';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import apiconfig from '../../../../config/api-config.js';
import { updateNowPlaying, updateIsPlaying, updatePlayerPosition } from '../../../FlikTraxStore/Actions/audioPlayerActions';
import { addToShoppingCart } from '../../../FlikTraxStore/Actions/cartActions';
import { addToPlaylist, switchPlayerMode, removeFromPlaylist, updatePlaylistFromLocal } from '../../../FlikTraxStore/Actions/userActions';
import { fetchApiData, switchApiUrl, downloadFliktraxFile } from '../../../FlikTraxStore/Actions/apiActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../../FlikTraxStore/Actions/snackbarActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import './NewReleases.css';
import Grid from '@material-ui/core/Grid';
import Mixes from '../../ApiTables/Mixes';
import NewReleaseCards from './NewReleaseCards';
import NewReleasesPaginator from '../../ApiTables/Paginators/NewReleasesPaginator';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import ShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PlaylistAddIcon from '@material-ui/icons/QueueMusic';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloseIcon from '@material-ui/icons/Close';
import AudioPlayer from '../../AudioPlayer/AudioPlayer';
import Keywords from '../../TrackMetaData/TrackKeywords';

class NewReleases extends Component {

    constructor(props) {
        super(props);

        this.nrRef = React.createRef();

        this.isAuthenticated = props.user.isAuthenticated;
        this.user = props.user.user;

        this.state = {
            mixStates: [],
            mode: 'categories',
            newReleases: {},
            newReleaseMedia: {},
            nrpage: 1
        };

        this.isDirectAccess = this.props.user.userGroups.indexOf("direct-access") > -1;
        this.updateNowPlaying = this.updateNowPlaying.bind(this);
        this.switchApiPage = this.switchApiPage.bind(this);
        this.addToPlaylist = this.addToPlaylist.bind(this);
    }

    getMixes(trackId) {

        var mixElement = 'mixcontainer-' + trackId;
        var currentStates = this.state.mixStates;

        if (currentStates.indexOf(mixElement) === -1) {
            ReactDOM.render(<Provider store={FlikTraxStore}><Mixes trackid={trackId} updateNowPlaying={this.updateNowPlaying} baseProps={this.props} /></Provider>, document.getElementById(mixElement));
            currentStates.push(mixElement);
        } else {
            ReactDOM.render('', document.getElementById(mixElement));
            currentStates = currentStates.slice(currentStates.indexOf(mixElement), 1);
        }
        this.setState({ mixStates: currentStates });
        document.getElementById(trackId).scrollIntoView();
    }

    switchApiPage(page) {

        //console.log(page)

        this.setState({
            mode: 'tracks',
            nrpage: page
        });

        this.getNewReleaseTracks();
        this.getNewReleasesMedia();
    }

    getNewReleaseTracks = () => event => {
        var parse = require('url-parse');

        var mediaTitle = event.currentTarget.getAttribute('data-title');
        var mediaLink = event.currentTarget.getAttribute('data-link');
        var mediaImage = event.currentTarget.getAttribute('data-image');
        var query = parse(mediaLink);

        var queryLink = query.pathname + query.query;
        var apiQuery = queryLink.replace("/", "");
        this.props.switchApiUrl(apiQuery);
        this.props.fetchApiData();

        this.setState({ newReleaseTitle: mediaTitle });
        this.setState({ newReleaseImage: mediaImage });

        var axiosUrl = mediaLink;

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ newReleaseMedia: response.data })
            })

        this.setState({ mode: 'tracks' });
    }

    getNewReleasesMedia() {

        var newreleaseTracks = [];
        if (this.state.newReleaseMedia && this.state.newReleaseMedia._embedded) {
            newreleaseTracks = Object.values(this.state.newReleaseMedia._embedded);
            var newReleases = newreleaseTracks[0];

            return (
                <div ref={this.nrRef}>
                    <Grid spacing={10} container style={{ backgroundColor: "white" }}>
                        <Grid item sm={12}>
                            <h2 style={{ color: "black" }}>{this.state.newReleaseTitle} <img style={{ maxWidth: 150 }} className="img-thumbnail float-right" alt={this.state.newReleaseTitle} src={this.state.newReleaseImage} /></h2>
                            <a href="/new-releases">
                                <Button variant="contained" color="primary"><i style={{ marginRight: 10 }} class="far fa-hand-point-left"></i> Back to New Releases</Button>
                            </a>
                        </Grid>
                        <Grid item sm={12}>
                            <div className="text-center">
                                <NewReleasesPaginator switchNewReleasePage={this.switchApiPage} apidata={this.state.newReleaseMedia} />
                            </div>
                            {newReleases.map((track, index) => {
                                var data = track;
                                let dataAttrs = {
                                    'data-source': data.preview_url,
                                    'data-tracktitle': data.track_title,
                                    'data-artist': data.artist_name,
                                    'data-description': data.description,
                                    'data-duration': data.duration,
                                    'data-vocaltype': data.vocal_type,
                                    'data-trackid': data.id,
                                    'data-filecompressed': data.file_path_compressed,
                                    'data-fileuncompressed': data.file_path_uncompressed
                                };

                                var vcount = (data.variation_count - 1);
                                var truncate = require('truncate');
                                return (
                                    <div>
                                        <div className="row ftTable">
                                            <div className="col-sm-1 text-left">
                                                <span>
                                                    <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                                                </span>
                                            </div>
                                            <div className="col-8 text-left categorytitle">

                                                <span className="trackTitle">{data.track_title}</span>  <span className="trackIdTitle">
                                                    {data.variation_count &&
                                                        <Tooltip title={'Show ' + vcount + ' alternate mixes'} placement="top">
                                                            <ButtonGroup className="mixBut" size="small" color="primary">
                                                                <Button size="small" onClick={() => this.getMixes(data.id)} > + {vcount} </Button>
                                                            </ButtonGroup>
                                                        </Tooltip>
                                                    }
                                                </span>
                                                <span className="artistNameSpan">  {data.artist_name}</span>
                                                <br />
                                                <span className="trackDescript">{truncate(data.description, 200)}</span>
                                                <Keywords trackid={data.id}
                                                    keywords={data.keywords}
                                                    genres={data.genres_keys}
                                                    styles={data.styles}
                                                    moods={data.moods}
                                                    location={this.props.location}
                                                    history={this.props.history}
                                                />
                                            </div>
                                            <div id="trackactionblock" className="col-sm-3 text-right text-nowrap">
                                                <span className="trackActions">{data.duration}</span>
                                                <Tooltip title={'Add ' + data.track_title + ' to your playlist'} placement="top">
                                                    <Link data-trackid={data.id}  {...dataAttrs} onClick={this.addToPlaylist.bind(this)} className="trackActions"><PlaylistAddIcon /></Link>
                                                </Tooltip>
                                                {!this.isDirectAccess &&
                                                    <Tooltip title={'Add to Shopping Cart'} placement="top">
                                                        <Link id={data.id}  {...dataAttrs} onClick={this.addToShoppingCart.bind(this)} className="trackActions"><ShoppingCartIcon /></Link>
                                                    </Tooltip>
                                                }
                                                {this.isDirectAccess &&
                                                    <React.Fragment>
                                                        <Tooltip title="Download MP3" aria-label="artist" placement="center-top">
                                                            <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-filecompressed'], false)}><DownloadIcon /></Link>
                                                        </Tooltip>
                                                        <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-top">
                                                            <Link style={{ color: "#ca75ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-fileuncompressed'], false)} > <DownloadIcon /></Link>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <Divider />
                                        </div>


                                        <div id={'mixcontainer-' + data.id} style={{ width: "100%" }}></div>

                                    </div>

                                );
                            })}
                        </Grid>
                        <AudioPlayer />
                    </Grid>
                </div>
            );
        }
    }

    getNewReleases() {

        var axiosUrl = apiconfig.api.APIURL + 'newreleases';
        var userPlaylist = JSON.stringify(this.props.user.userPlaylist);

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            params: {
                playlist: userPlaylist
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ newReleases: response.data })
            })
    }

    getNewReleaseCategories() {
        var newReleasesData = this.state.newReleases;
        var nrEmbeddedData = newReleasesData._embedded;
        return (
            <div ref={this.nrRef}>
                <Grid className="mx-auto" spacing={4} container>


                    {nrEmbeddedData && nrEmbeddedData.newreleases.map((data, index) => {
                        return (

                            <NewReleaseCards data-link={data._link} data-title={data.title} title={data.title} link={data._link} slug={data.slug} image={data.image} getNewReleaseTracks={this.getNewReleaseTracks} />

                        )
                    })}

                </Grid>
            </div>
        )
    }

    componentDidMount() {
        this.getNewReleases();
        window.scrollTo(0, 0);
    }

    updateNowPlaying(event) {
        event.preventDefault();
        this.setState(this.state);
        var targetId = event.currentTarget.id;
        var nowPlaying = event.currentTarget.dataset;
        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } else {
            this.props.updateIsPlaying(true);
            this.props.updatePlayerPosition(0);
        }
    }

    addToPlaylist = (e) => {

        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

        var notification = trackTitle + ' added to your playlist';

        this.props.addToPlaylist(trackId);

        // setTimeout(() => {
        //   var localPlaylist = JSON.stringify(this.props.user.userPlaylist);
        //   localStorage.setItem('userPlaylist', localPlaylist);
        // }, 500);

        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }

    addToShoppingCart = (e) => {

        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

        var notification = trackTitle + ' added to your shopping cart';

        this.props.addToShoppingCart(trackId);

        // setTimeout(() => {
        //   var localPlaylist = JSON.stringify(this.props.user.userPlaylist);
        //   localStorage.setItem('userPlaylist', localPlaylist);
        // }, 500);

        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }

    render() {

        return (
            <div>
                <section id="newreleases">
                    <div className="container">

                        <div className="content">
                            <header>
                                <Typography component="h2">
                                    <Box textAlign="left" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                        <h2>New Releases</h2>
                                    </Box>
                                </Typography>
                            </header>
                            {this.state.mode === 'categories' && this.getNewReleaseCategories()}

                            {this.state.mode === 'tracks' && this.getNewReleasesMedia()}

                        </div>
                    </div>
                </section>
            </div>
        )


    }
}

NewReleases.propTypes = {
    switchPlayerMode: PropTypes.func.isRequired,
    updateNowPlaying: PropTypes.func.isRequired,
    updateIsPlaying: PropTypes.func.isRequired,
    updatePlayerPosition: PropTypes.func.isRequired,
    removeFromPlaylist: PropTypes.func.isRequired,
    updatePlaylistFromLocal: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    addToPlaylist: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    fetchApiData: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    addToShoppingCart: PropTypes.func.isRequired,
    downloadFliktraxFile: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
    audioplayer: state.audioplayer,
    notifications: state.snackbar,
    cart: state.cart
})

export default connect(mapStateToProps,
    {
        switchPlayerMode,
        updateNowPlaying,
        updateIsPlaying,
        updatePlayerPosition,
        removeFromPlaylist,
        addToPlaylist,
        updatePlaylistFromLocal,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar,
        fetchApiData,
        switchApiUrl,
        addToShoppingCart,
        downloadFliktraxFile
    })(withRouter(NewReleases));