import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../FlikTraxStore/Actions/userActions';
import { removeShoppingCartItem } from '../../FlikTraxStore/Actions/cartActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import FolderIcon from '@material-ui/icons/MusicNote';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from '@material-ui/core/Typography';

class MiniShoppingCart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errors: {
        cognito: null,
        blankfield: false
      }
    };

  }

  removeFromShoppingCart = (e) => {
    var trackId = e.currentTarget.getAttribute('data-trackid');
    this.props.removeShoppingCartItem(trackId);
  }

  getCartItem(data, index) {
    if (index < 6) {
      return (

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={data.track_title}
            secondary={data.artist_name}
          />
          <ListItemSecondaryAction>
            <IconButton data-trackid={data.id} edge="end" aria-label="delete" onClick={this.removeFromShoppingCart}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

      );
    }
  }

  getScHeader() {
    if (this.props.cart.cartContents.length > 0) {
      return (
        <h3 style={{ marginTop: 10, border: 0 }}>My Shopping Cart <span style={{ marginLeft: 5 }} class="badge badge-info">{this.props.cart.cartContents.length}</span></h3>
      );
    } else {
      return (
        <h3 style={{ marginTop: 10, border: 0 }}>My Shopping Cart</h3>
      )
    }
  }

  handleCheckout = (e) => {
    //this.props.history.push('/cart');
    window.location.replace("/cart");
  }

  render() {

    var cartItems = this.props.cart.cartContents;
    var scindex = 0;
    var remItems = 0
    if(cartItems.length > 5){
      remItems = cartItems.length - 5;
    }

    return (

      <div style={{ padding: 15 }} className="card card-container">
        {this.getScHeader()}

        <div>
          {cartItems.length === 0 &&
            <SnackbarContent
              className="primary"
              aria-describedby="empty"
              message={
                <span id="client-snackbar">
                  Shopping Cart is Empty
                   </span>
              }
            />
          }

          <List dense={true}>
            {cartItems.length > 0 &&

              cartItems.map((data, index) => {
                scindex++;
                return this.getCartItem(data, scindex);

              })
            }

          </List>
        </div>

        {this.props.cart.cartContents.length > 5 && 
        <Typography className="mx-auto" style={{color:"#5a6468", fontFamily:"Gibson W01 SemiBold"}}>+ {remItems} More</Typography>
        }

        {cartItems.length > 0 &&
          <Button onClick={this.handleCheckout} className="mx-auto" style={{ marginTop: 50 }} variant="contained" color="primary">
            Checkout
            <ShoppingCartIcon />
          </Button>
        }

      </div>
    );
  }
}

MiniShoppingCart.propTypes = {
  updateAuthStatus: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  updateAuthenticating: PropTypes.func.isRequired,
  removeShoppingCartItem: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  cart:state.cart
})

export default connect(mapStateToProps,
  {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    removeShoppingCartItem
  })(withRouter(MiniShoppingCart));
