import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import FormErrors from "../../../FormErrors.js";
import { Auth } from 'aws-amplify';

import Tooltip from '@material-ui/core/Tooltip';
import Pencil from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../../FlikTraxStore/Actions/userActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../../FlikTraxStore/Actions/snackbarActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class UserSettings extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user.user;
        this.userFirstName = this.user.attributes['custom:firstname'];
        this.userLastName = this.user.attributes['custom:lastname'];
        this.userEmail = this.user.attributes['email'];
        this.pushFirstName = this.pushFirstName.bind(this);
        this.pushLastName = this.pushLastName.bind(this);
        //this.renderChangePasswordForm = this.renderChangePasswordForm.bind(this);

        this.state = {
            viewform: 'settings',
            passwordFormHidden: true,
            firstname: this.userFirstName,
            lastname: this.userLastName,
            email: this.userEmail,
            oldpassword: '',
            newpassword: '',
            errors: {
                cognito: null,
                blankfield: false
            },
            fnEditing: false,
            lnEditing: false,
            fnEditbg: '',
            lnEditbg: ''
        };
    }



    handleChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.value });
    };

    useStyles() {
        return makeStyles(theme => ({
            container: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: 200,
            },
            dense: {
                marginTop: 19,
            },
            menu: {
                width: 200,
            },
            button: {
                margin: theme.spacing(1),
                textAlign: "right"
            },
        }));
    }

    editFirstName = event => {
        this.setState({ fnEditing: true });
        this.setState({ fnEditbg: 'white' });
        var fnInput = document.getElementById('firstname');
        fnInput.disabled = false;
        fnInput.focus();
    }

    pushFirstName = event => {
        var fnInput = document.getElementById('firstname');
        const result = Auth.updateUserAttributes(this.user, {
            'custom:firstname': fnInput.value
        })
            .then(response => {
                this.setState({ fnEditing: false });
                const user = Auth.currentAuthenticatedUser({
                    bypassCache: true
                })
                    .then(result => {
                        this.props.setAuthUser(result);
                    });
            });

        this.props.enqueueSnackbar({
            message: 'First Name updated successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 1500
            },
        });

        this.setState({ fnEditbg: '' });
    }

    editLastName = event => {
        //this.setState({ fnEditing: false });
        this.setState({ lnEditing: true });
        this.setState({ lnEditbg: 'white' });
        var lnInput = document.getElementById('lastname');
        lnInput.disabled = false;
        lnInput.focus();
    }

    pushLastName = event => {
        var lnInput = document.getElementById('lastname');
        const result = Auth.updateUserAttributes(this.user, {
            'custom:lastname': lnInput.value
        })
            .then(response => {
                this.setState({ lnEditing: false });
                const user = Auth.currentAuthenticatedUser({
                    bypassCache: true
                })
                    .then(result => {
                        this.props.setAuthUser(result);
                    });
            });

        this.props.enqueueSnackbar({
            message: 'Last Name updated successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 1500
            },
        });

        this.setState({ lnEditbg: '' });
    }

    switchToPasswordView = event => {
        event.preventDefault();
        this.setState({ viewform: 'changepassword' })
    }

    renderChangePasswordForm() {
        return (
            <div className="card card-container">

                <h3 style={{ marginTop: 15 }}>
                    Change Password
                </h3>
                <FormErrors formerrors={this.state.errors} />
                <form className="form-change-password" onSubmit={this.changePassword}>
                    <span id="reauth-email" className="reauth-email"></span>
                    <input
                        type="password"
                        id="oldpassword"
                        className="form-control"
                        placeholder="Old Password"
                        value={this.state.oldpassword}
                        onChange={this.handleChange('oldpassword')}
                    />
                    <input
                        style={{ marginTop: 15 }}
                        type="password"
                        id="newpassword"
                        className="form-control"
                        placeholder="New Password"
                        value={this.state.newpassword}
                        onChange={this.handleChange('newpassword')} />

                    <button style={{ marginTop: 15 }} className="btn btn-lg btn-primary btn-block btn-signin" type="submit">UPDATE PASSWORD</button>

                </form>

            </div>
        );
    }

    changePassword = event => {
        event.preventDefault();
        if ((this.state.oldpassword === '') && (this.state.newpassword === '')) {
            return;
        }

        if (this.state.oldpassword === '') {
            this.props.enqueueSnackbar({
                message: 'Old Password cannot be blank',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                    ),
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 1500
                },
            });
            return;
        }

        if (this.state.newpassword === '') {
            this.props.enqueueSnackbar({
                message: 'New Password cannot be blank',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                    ),
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 1500
                },
            });
            return;
        }



        try {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, this.state.oldpassword, this.state.newpassword);
                })
        } catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;
            this.setState({
                errors: {
                    ...this.state.errors,
                    cognito: err
                }
            })
        }
        this.props.enqueueSnackbar({
            message: 'Your password has been updated successfully!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 1500
            },
        });

        this.setState({ viewform: 'settings' })
    }

    render() {

        switch (this.state.viewform) {
            case 'changepassword':
                return this.renderChangePasswordForm();

            default:
                const classes = this.useStyles();

                return (
                    <div className="card card-container">

                        <h3 style={{ marginTop: 15 }}>
                            Settings
                            <Tooltip className="float-right" title="Cancel/Close" aria-label="Log In" placement="top">
                                <Button onClick={this.props.closeLoginForm} size="small">
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                        </h3>

                        <TextField
                            style={{ backgroundColor: this.state.fnEditbg }}
                            id="firstname"
                            label="First Name"
                            className={classes.textField}
                            value={this.state.firstname}
                            onChange={this.handleChange('firstname')}
                            margin="normal"
                            variant="outlined"
                            disabled
                        />

                        {!this.state.fnEditing &&
                            <Link onClick={this.editFirstName} className="text-right" style={{ fontSize: "0.75em", color: "grey" }}>
                                <Tooltip title="Edit First Name" aria-label="Edit First Name" placement="top">
                                    <Pencil />
                                </Tooltip>
                            </Link>
                        }

                        {this.state.fnEditing &&
                            <Link onClick={this.pushFirstName} className="text-right" style={{ fontSize: "0.75em", color: "grey" }}>
                                <Tooltip title="Click Save When Done" aria-label="Save" placement="top">
                                    <Save />
                                </Tooltip>
                            </Link>
                        }

                        <TextField
                            style={{ backgroundColor: this.state.lnEditbg }}
                            id="lastname"
                            label="Last Name"
                            className={classes.textField}
                            value={this.state.lastname}
                            onChange={this.handleChange('lastname')}
                            margin="normal"
                            variant="outlined"
                            disabled
                        />

                        {!this.state.lnEditing &&
                            <Link onClick={this.editLastName} className="text-right" style={{ fontSize: "0.75em", color: "grey" }}>
                                <Tooltip title="Edit Last Name" aria-label="Edit LastName" placement="top">
                                    <Pencil />
                                </Tooltip>
                            </Link>
                        }

                        {this.state.lnEditing &&
                            <Link onClick={this.pushLastName} className="text-right" style={{ fontSize: "0.75em", color: "grey" }}>
                                <Tooltip title="Click Save When Done" aria-label="Save" placement="top">
                                    <Save />
                                </Tooltip>
                            </Link>
                        }

                        <TextField
                            id="email"
                            label="Email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                            variant="outlined"
                            disabled
                        />

                        <button onClick={this.switchToPasswordView.bind(this)} className="btn btn-lg btn-primary btn-block btn-signin">  CHANGE PASSWORD </button>


                    </div>
                );
        }
    }
}

UserSettings.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps,
    {
        updateAuthStatus,
        setAuthUser,
        updateAuthenticating,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar,
    })(withRouter(UserSettings));
