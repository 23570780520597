import React, { Component } from 'react';
import { connect } from 'react-redux'
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Checkout from './Checkout';
import './css/UserCart.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class UserCart extends Component {

    constructor(props) {
        super(props)
        
        this.styles = {
            root: {
                flexGrow: 1,
            },
            paper: {
                padding: 25,
                textAlign: 'center',
                color: "white",
                width: "100%",
                minHeight: 300
            },
        };
    }

    render() {
        const steps = ['Choose License', 'Payment Details', 'Confirm'];
        return (
            <section id="usercart">
                <div className="content">
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item sm={12} style={{ textAlign: "left", minHeight: 900, backgroundColor: "white" }}>
                            <header>
                            <Typography style={{ marginTop: 25 }} >
                                <Box textAlign="left" fontWeight={300} fontFamily="Gibson W01 SemiBold">
                                    <h3 id="cartStepHead">{steps[this.props.cart.currentOrder.currentStep - 2]}</h3>
                                </Box>
                            </Typography>
                        </header>
                        <Grid item sm={12} style={{width:700}}>
                        <FlikTraxLoader active={this.props.api.isLoading}>
                            <Checkout /> 
                        </FlikTraxLoader>   
                        </Grid>  
                        </Grid>
      
                    </Grid>
                </Paper>
                </div>
                
            </section>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    api: state.api
  })

export default connect(mapStateToProps, {})(UserCart)