import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../css/Artists.css';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import artistsConfig from './artists.js';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PlayCircleOutline from '@material-ui/icons/PlayArrow';
import PauseCircleOutline from '@material-ui/icons/Pause';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

class VoiceArtists extends Component {

    constructor(props) {
        super(props);
        this.props = props
        this.updateNowPlaying = this.updateNowPlaying.bind(this);
    }

    renderSpinner() {
        switch (this.props.api.isLoading) {
            case true:
                return (
                    "fas fa-sync-alt fa-spin"
                );
            default:
                return (
                    "fas fa-sync-alt"
                )
        }
    }

    updateNowPlaying(event) {
        event.preventDefault();
        this.setState({ isLoading: true });
        var targetId = event.currentTarget.dataset.trackid;
        var nowPlaying = event.currentTarget.dataset;
        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } 
        this.setState({ isLoading: false });
    }

    render() {
        var VoiceMenuData = artistsConfig.voartists;
        var truncate = require('truncate');
        return (
            <section id="artists">
                <div className="content">
                    <header>

                        <Typography component="h2">
                           
                                <h2 className="text-center">Voiceover Artists</h2>
                            
                        </Typography>

                    </header>
                    <Grid container spacing={2}>
                        {VoiceMenuData && VoiceMenuData.map((data, index) => {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <Box style={{ backgroundColor: "rgba(255, 255, 255, 0.9)", color: "#4a5e79", marginTop: 75 }} p={5} fontWeight={900}>
                                        <Paper style={{ margin: 'auto', minWidth: 100, minHeight: 300 }}>
                                            <List className="moresamplelist" component="nav">
                                                <FlikTraxLoader active={this.props.api.isLoading}>
                                                    <ListItem>
                                                        <ListItemText style={{ textAlign: "left" }}><span className="align-text-bottom" style={{ fontSize: "1.2em", fontWeight: "900", fontFamily: "Gibson W01 Regular" }}>
                                                            {data[0].artist_name}</span><Avatar className="float-right" style={{height:75, width:75}} src={data[0].profile_image} />
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider />

                                                    {data.map((vdata, vindex) => {
                                                        let dataAttrs = {
                                                            'data-trackid': vdata._id,
                                                            'data-source': vdata.preview_url,
                                                            'data-tracktitle': vdata.track_title,
                                                            'data-artist': vdata.artist_name
                                                        };
                                                        //console.log(dataAttrs)
                                                        return (
                                                            <ListItem>
                                                                <ListItemText>
                                                                    <Tooltip title={vdata.track_title + " - " + vdata.artist_name} placement="top">
                                                                        <Link style={{ textDecoration: 'none', fontSize: "0.95em" }} id={data._id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === vdata._id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline style={{ marginRight: 10 }} className="svg_icons" fontSize='small' /> : <PlayCircleOutline style={{ marginRight: 10 }} className="svg_icons" fontSize='small' />} {truncate(vdata.track_title, 35)}</Link>
                                                                    </Tooltip>
                                                                </ListItemText>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </FlikTraxLoader>
                                            </List>
                                        </Paper>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                {/* <a href="#werealize" className="goto-next scrolly">Next</a> */}
            </section>
        )
    }
}

VoiceArtists.propTypes = {
    updateNowPlaying: PropTypes.func.isRequired,
    updateIsPlaying: PropTypes.func.isRequired,
    setPlayerStatus: PropTypes.func.isRequired,
    updatePlayerPosition: PropTypes.func.isRequired
}


const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
    audioplayer: state.audioplayer,
})

export default connect(mapStateToProps,
    {
        updateNowPlaying,
        updateIsPlaying,
        setPlayerStatus,
        updatePlayerPosition
    })(VoiceArtists);