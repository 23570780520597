import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        maxWidth: 500,
    },
    media: {
        height: 192
    },
});

export default function EmptyCartCard() {
    const classes = useStyles();
    var addclass = classes.card + " mx-auto";
    return (
        <Card className={addclass}>
            <CardActionArea style={{ cursor: "default" }}>
                <CardMedia
                    className={classes.media}
                    image="/images/playlist_x.png"
                    title="Login or Register"
                />
                {/* <CardContent>
                    <Typography style={{ fontFamily: "Gibson W01 SemiBold", textAlign: "center", fontSize: "1.0em", padding: 20 }} variant="subTitle1" color="textPrimary" component="p">
                        There's nothing here ...what a shame! Silence is <bold>not</bold> golden!<p style={{ marginTop: 25, textAlign: "center", fontSize: "1.1em" }}><Link to="/">Keep Shopping</Link></p>
                    </Typography>
                </CardContent> */}
            </CardActionArea>
        </Card>
    );
}
