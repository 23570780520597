import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchFilterData } from '../../../FlikTraxStore/Actions/filterActions';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Instruments extends Component {

    constructor(props) {
        super(props);
        this.currentInstruments = [];
    }

    handleInstrumentCheckboxChange = (event) => {
        var selectedInstrument = event.target.value.toString();
        var inArray = require('in-array');
        var tchecked = event.target.checked;

        var currentInstruments = this.props.user.userInstruments;

        if (tchecked && !inArray(currentInstruments, selectedInstrument)) {
            currentInstruments && currentInstruments.push(event.target.value);
            this.props.updateUserFilters(currentInstruments);
        }

        if( !tchecked && inArray(currentInstruments, selectedInstrument) ){
            var rmIndex = currentInstruments.indexOf(selectedInstrument);
            currentInstruments.splice(rmIndex, 1);
            this.props.updateUserFilters(currentInstruments);
        }

        console.log(this.props.user)
    }

    componentDidMount() {
        this.props.fetchFilterData('instruments');
    }

    render() {
        var instrumentsData = this.props.filters.instrumentsData;
        var instrumentsArray = instrumentsData[0];

        return (
            <div style={{ marginTop: 25 }}>
                {instrumentsArray && instrumentsArray.map((instrument, index) => {
                    return (

                        <ul className=""><li>
                            <FormControlLabel className="filtercheck"
                                control={
                                    <Checkbox
                                        onChange={this.handleInstrumentCheckboxChange}
                                        color="secondary"
                                        value={instrument.instruments_id}
                                        checked={this.props.user.userInstruments.includes(instrument.instruments_id)}
                                    />}
                                label={instrument.title}
                                labelPlacement="end"
                            />
                        </li></ul>
                    );
                })}
            </div>
        )
    }


}

Instruments.propTypes = {
    fetchFilterData: PropTypes.func.isRequired,
    updateUserFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    filters: state.filters,
    user: state.user
})

export default connect(mapStateToProps,
    { fetchFilterData, updateUserFilters })
    (Instruments);
