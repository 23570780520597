import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchApiData, switchMasterMode, switchApiPage, switchApiUrl, updateIsPlaylist, setCurrentSearch } from '../../FlikTraxStore/Actions/apiActions';
import { switchPlayerMode, updateFilterDrawerStatus, clearUserFilters } from '../../FlikTraxStore/Actions/userActions';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SearchBar from './SearchBar';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Avatar from '@material-ui/core/Avatar';
import './MasterDashboard.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter  } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Categories from '../ApiTables/Categories';
import Tracks from '../ApiTables/Tracks';
import FilterDrawer from './FilterDrawer';
import Chip from '@material-ui/core/Chip';

const queryString = require('query-string');

class MasterDashboard extends Component {

    constructor(props, context) {
        super(props, context);
        this.isAuthenticated = props.user.isAuthenticated;
        this.user = props.user.user;
        this.handleWaveformChange = this.handleWaveformChange.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.switchPlaylistBg = this.switchPlaylistBg.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.audioPlayer = React.createRef();
        this.filterDrawer = React.createRef();

        this.state = {
            isPlaylistPage: false,
            setDrawerOpen: false
        };
    }

    setOpen() {
        this.setState({ open: true })
    }

    handleDrawerOpen() {
        this.setState({ setDrawerOpen: true })
    }

    getFilterState() {
        var fstate = true;
        var moods = this.props.user.userMoods.length;
        var styles = this.props.user.userStyles.length;
        var instruments = this.props.user.userInstruments.length;
        var vtypes = this.props.user.userVocalType;
        var bpms = this.props.user.userBpm;
        var duration = this.props.user.userDuration;
        if ((moods === 0) && (styles === 0) && (instruments === 0) && (!vtypes) && (!bpms) && (!duration)) {
            fstate = false;
        }
        return fstate;
    }

    clearFilters = (e) => {
        this.props.clearUserFilters();
        this.props.fetchApiData();
    }

    renderFilterAlert() {
        var filterState = this.getFilterState();

        if (!filterState) {
            return
        }

        return (
            <div>
                <button onClick={this.clearFilters} type="button" class="btn btn-warning"><i class="fas fa-filter"></i> Clear Filters</button>
            </div>
        )
    }


    componentDidMount() {

        let loc = window.location;
        let parsedMode = queryString.parse(loc.search);
        if (Object.keys(parsedMode).length !== 0) {
            this.handleCategoryChange(parsedMode.mode);
        }

        var ftap = document.getElementById("ftaudioplayer");
        if (this.props.user.audioPlayerMode) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }
    }

    clearSearch = e => {
        this.props.setCurrentSearch(null);
        window.location.reload(false);
    };

    getOriginalTrack = (e) => {
        var dataset = e.currentTarget.dataset;
        var sterm = dataset.artistname + ' ' + dataset.tracktitle
        var apiUrl = 'search?searchmode=tvpros&searchtype=2&searchterm=' + encodeURIComponent(sterm);
        this.props.switchMasterMode('tracks');
        this.props.switchApiPage(1);
        this.props.switchApiUrl(apiUrl);
        this.props.setCurrentSearch(null);
        let currentPath = this.props.location.pathname;
        if (currentPath === '/dashboard') {
            this.props.fetchApiData();
        } else {
            this.props.history.push('/dashboard');
        }
    }

    currentModeRender() {
        //console.log(this.props.api)
        if (this.props.api.masterMode === 'categories') {

            return (
                <div>
                    <h2>{this.props.api.apiUrl.replace("tvpros", "").toUpperCase()} <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} /> <span className="float-right">{this.renderFilterAlert()} </span> </h2>
                    <Categories />
                </div>
            )
        } else {
            var searchProps = (this.props.api.currentSearchTerm !== null) ? this.props.api.currentSearchTerm : '';
            switch (searchProps.type) {
                case 'more':
                    return (
                        <div>
                            <h2>Search Results <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} />

                            </h2>
                            {this.props.api.currentSearch !== null && (
                                <React.Fragment>
                                    <h5 style={{ color: 'black' }}>
                                        Tracks similar to <Link data-tracktitle={this.props.api.currentSearch.track_title} data-artistname={this.props.api.currentSearch.artist_name} style={{ textDecoration: 'none', fontStyle: 'italic', color: "#3f51b5" }} onClick={this.getOriginalTrack}>{this.props.api.currentSearch.track_title} by {this.props.api.currentSearch.artist_name}</Link>
                                        <Chip
                                            style={{ marginLeft: 10 }}
                                            size="small"
                                            label="Clear"
                                            avatar={<Avatar><i style={{ paddingLeft: 2 }} className="fas fa-times-circle fa-3x"></i></Avatar>}
                                            color="secondary"
                                            clickable={true}
                                            onClick={this.clearSearch}
                                        />
                                    </h5>
                                </React.Fragment>
                            )}
                            <Tracks />

                        </div>

                    );
                case 'tag':
                    return (
                        <div>
                            <h2>Search Results <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} />
                            </h2>

                            <React.Fragment>
                                <h5 style={{ color: 'black' }}>
                                    Tracks tagged as <Link style={{ textDecoration: 'none', fontStyle: 'italic', color: "#3f51b5" }}>{this.props.api.currentSearchTerm.searchterm.toUpperCase()}</Link>
                                    <Chip
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                        label="Clear"
                                        avatar={<Avatar><i style={{ paddingLeft: 2 }} className="fas fa-times-circle fa-3x"></i></Avatar>}
                                        color="secondary"
                                        clickable={true}
                                        onClick={this.clearSearch}
                                    />
                                </h5>
                            </React.Fragment>

                            <Tracks />

                        </div>

                    );

                case 'artist':
                    return (
                        <div>
                            <h2>Search Results <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} />
                            </h2>

                            <React.Fragment>
                                <h5 style={{ color: 'black' }}>
                                    Tracks by <Link style={{ textDecoration: 'none', fontStyle: 'italic', color: "#3f51b5" }}>{this.props.api.currentSearchTerm.searchterm.toUpperCase()}</Link>
                                    <Chip
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                        label="Clear"
                                        avatar={<Avatar><i style={{ paddingLeft: 2 }} className="fas fa-times-circle fa-3x"></i></Avatar>}
                                        color="secondary"
                                        clickable={true}
                                        onClick={this.clearSearch}
                                    />
                                </h5>
                            </React.Fragment>

                            <Tracks />

                        </div>

                    );


                case 'default':
                    return (
                        <div>
                            <h2>Search Results <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} />
                            </h2>

                            <React.Fragment>
                                <h5>
                                    Results for  <Link style={{ textDecoration: 'none', fontStyle: 'italic', color: "#3f51b5" }}>{this.props.api.currentSearchTerm.searchterm.toUpperCase()}</Link>
                                    <Chip
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                        label="Clear"
                                        avatar={<Avatar><i style={{ paddingLeft: 10 }} class="fas fa-times-circle"></i></Avatar>}
                                        color="secondary"
                                        clickable={true}
                                        onClick={this.clearSearch}
                                    />
                                </h5>
                            </React.Fragment>

                            <Tracks />

                        </div>

                    );
                default:
                    return (
                        <div>
                            <h2>{this.props.api.trackPageTitle ? this.props.api.trackPageTitle : 'Latest Tracks'} <Chip style={{ marginLeft: 10 }} color="primary" label={this.props.api.apiData.total_items} /> <span className="float-right">{this.renderFilterAlert()} </span></h2>
                            <Tracks />
                        </div>
                    );
            }
        }
    }


    switchPlaylistBg() {
        var bgElement = document.getElementById('masterdashboard');

        switch (this.props.api.isPlaylist) {
            case true:
                bgElement.classList.remove("defaultBg");
                bgElement.classList.add('curatedBg')
                break;
            default:
                bgElement.classList.remove("curatedBg");
                bgElement.classList.add('defaultBg');
        }
    }

    handleCategoryChange(mode) {
        console.log(mode.toLowerCase())
        this.props.switchMasterMode('categories');
        this.props.switchApiPage(1);
        this.props.switchApiUrl(mode.toLowerCase());
        this.props.fetchApiData();
    }

    handleWaveformChange = name => event => {
        this.props.switchPlayerMode(event.target.checked);
        var ftap = document.getElementById("ftaudioplayer");
        if (event.target.checked) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }
    };

    render() {

        var bgMaster = this.props.api.apiUrl.indexOf("playlist") >= 0 ? "rgba(255, 255, 255, 0.75)" : "rgba(255, 255, 255, 1)";

        return (
            <div>
                <section id="masterdashboard" className="defaultBg">
                    <div className="content">
                        <header>
                            <Typography component="h2">
                                <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                    <h2>SEARCH TRACKS</h2>
                                </Box>
                            </Typography>
                        </header>

                        <div>
                            <Container>

                                <Grid container style={{ marginTop: 25 }} spacing={3}>
                                    <Grid className="searchbar" item xs={12}>
                                        <SearchBar rdxProps={this.props} />
                                    </Grid>
                                    <Grid className="filtertab" item xs={12} sm={4}>
                                        <FilterDrawer setDrawerOpen={this.state.setDrawerOpen} />
                                    </Grid>

                                    <Grid className="genretab" item xs={12} sm={4}>
                                        <div className="dropdown">
                                            <span className="dropdown-toggle genrestabtxt" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                CATEGORY
                                            </span>
                                            <div id="catdropdown" className="dropdown-menu" aria-labelledby="categories">
                                                <Grid item xs={12} sm={4}>
                                                    <Link onClick={this.handleCategoryChange.bind(this, 'STYLES')} className="dropdown-item">Styles</Link>
                                                    <Link onClick={this.handleCategoryChange.bind(this, 'MOODS')} className="dropdown-item">Moods</Link>
                                                    <Link onClick={this.handleCategoryChange.bind(this, 'INSTRUMENTS')} className="dropdown-item">Instruments</Link>
                                                    <Link onClick={this.handleCategoryChange.bind(this, 'CATEGORIES')} className="dropdown-item">Production Genres</Link>
                                                </Grid>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid className="searchtab" item xs={12} sm={4}>
                                        <Link onClick={this.handleCategoryChange.bind(this, 'PLAYLISTS')}>CURATED PLAYLISTS</Link>
                                    </Grid>
                                    <Grid className="fttoolbar" item xs={12}>
                                        {/* <div className="float-left" style={{padding: 15}}>
                                            <FlikTraxBreadcrumbs />
                                        </div> */}
                                        <FormControlLabel className="float-right"
                                            control={
                                                <Switch className="audioswitch"
                                                    checked={this.props.user.audioPlayerMode}
                                                    onChange={this.handleWaveformChange('playermode')}
                                                    value="playermode"
                                                    color="secondary"
                                                    inputProps={{ 'aria-label': 'Player Mode' }}
                                                />
                                            }
                                            label="Auto-Hide Player"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid id="masterBgBlock" item xs={12} style={{ minHeight: 900, backgroundColor: bgMaster }}>
                                        <div id="mastercontainer" className={'panel-body'} ref="MasterContainer">
                                            {this.currentModeRender()}
                                        </div>

                                    </Grid>


                                </Grid>
                            </Container>
                        </div>
                    </div>
                </section>
                <AudioPlayer ref={this.audioPlayer} />
            </div>

        )
    }
}

MasterDashboard.propTypes = {
    fetchApiData: PropTypes.func.isRequired,
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    setCurrentSearch: PropTypes.func.isRequired,
    switchPlayerMode: PropTypes.func.isRequired,
    api: PropTypes.object.isRequired,
    updateCurrentRoute: PropTypes.object.isRequired,
    updateIsPlaylist: PropTypes.object.isRequired,
    updateFilterDrawerStatus: PropTypes.object.isRequired,
    clearUserFilters: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user
})

export default connect(mapStateToProps,
    {
        fetchApiData,
        switchMasterMode,
        switchPlayerMode,
        switchApiUrl,
        switchApiPage,
        setCurrentSearch,
        updateIsPlaylist,
        updateFilterDrawerStatus,
        clearUserFilters
    })(withRouter(MasterDashboard));