import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './components/Home/css/Footer.css';
import App from './App';
import Amplify from 'aws-amplify';
import config from './config/cognito-config';
import * as serviceWorker from './serviceWorker';



Amplify.configure({
    manditorSignIn: true,
    region: config.cognito.REGION,
    //identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
})

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
