import React, { Component } from 'react'
import '../css/VoiceOver.css';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class VoiceOver extends Component {

    render() {

        return (
            <section id="voiceover">
                <div className="content">
                    <header>

                        <Typography component="h2">
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <h2>FlikTrax Voiceover Service</h2>
                            </Box>
                        </Typography>
                        {/* <Typography component="h4">
                            <Box textAlign="center" fontWeight={400} fontFamily="Gibson W01 Regular">
                                FLIKTRAX IS RUN BY MUSICIANS WHO KNOW AND LOVE MUSIC
                            </Box>
                        </Typography> */}



                        <Box className="directrel" style={{ backgroundColor: "rgba(0, 1, 61, 0.65)" }} textAlign="left" fontWeight={300} fontFamily="Gibson W01 Regular" >
                            FlikTrax makes it easy to add high quality 'Voiceover' to your media. Our experienced, versatile team of Voiceover Professionals are available for Voiceover, Narration and Audio ID's, 7 days a week. *Full Sound Design packages, which include Voiceover, Music and Audio Production, are also available. Contact us at <a style={{color:"white", textDecoration:"underline"}}href="mailto:info@Fliktrax.com">info@Fliktrax.com</a>
                        </Box>

                    </header>
                </div>
                <a href="#artists" className="goto-next scrolly">Next</a>
            </section >

        )
    }
}

export default VoiceOver