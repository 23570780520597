import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 200,
    // height: 500,
    // paddingBottom:25
    margin: 15,
    borderRadius: 0
  },
  media: {
    minHeight: 270,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (

    <div className={classes.root}>

      <Grid item xs>
        <Link data-link={props.link} data-title={props.title}  data-image={props.image} onClick={props.getNewReleaseTracks()}>
          <Card className={classes.card}>
            <Paper className={classes.paper}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={props.image}
                  title={props.title}
                />
                <CardContent style={{ backgroundColor: "#001621" }}>
                  <Typography style={{ color: "white" }} component="h3">
                    {props.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            {/* <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button>
          </CardActions> */}
            </Paper>
          </Card>
        </Link>
      </Grid>
    </div>
  );
}
