import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar'

class MoodChips extends Component {
  deleteMood(selectedMood) {
    var currentMoods = this.props.user.userMoods;
    var rmIndex = currentMoods.indexOf(selectedMood);
    currentMoods.splice(rmIndex, 1);
    this.props.updateUserFilters(currentMoods);
  }

  render() {
    //console.log(this.props)
    return (
      <div>


        <ul>
          {this.props.user.userMoods && this.props.user.userMoods.map(mood => (
            
              <Chip 
                size="small"
                color="secondary"
                label={mood}
                avatar={<Avatar>M</Avatar>}
                onDelete={() => { this.deleteMood(mood) }} />

          ))}
        </ul>

      </div>

    )
  }
}

MoodChips.propTypes = {
  updateUserFilters: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  { updateUserFilters })
  (MoodChips);