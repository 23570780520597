import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { switchMasterMode, switchApiPage, switchApiUrl } from '../../FlikTraxStore/Actions/apiActions';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

class SearchMusic extends Component {


    redirectDash = (e) => {
        var dataset = e.currentTarget.dataset;
        var mode = dataset.mode;
        var rdUrl = "/dashboard?mode=" + mode;
        window.location.replace(rdUrl);
      }

    render() {
        return (
            <section id="search">
                <div className="content">
                    <header>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>Search Tracks</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <h4 style={{ fontFamily: "Gibson W01 Regular" }}>Search Tracks by Categories or Keywords &amp; use filters to narrow your search</h4>
                            </Grid>
                        </Grid>
                    </header>

                    <Box display="flex" justifyContent="center" >
                                <ul className="linkblock">
                                    <li><Link data-mode="categories" onClick={this.redirectDash}>Production Genres</Link></li>
                                    <li><Link data-mode="moods" onClick={this.redirectDash}>Moods &amp; Emotion</Link></li>
                                    <li><Link data-mode="instruments" onClick={this.redirectDash}>Instrumentation</Link></li>
                                    <li><Link data-mode="styles" onClick={this.redirectDash}>Musical Styles</Link></li>
                                    <li><Link to="/new-releases">New Releases</Link></li>
                                </ul>
                    </Box>

                    <div className="row actions center-block">
                        <Grid container style={{ marginTop: 75 }} spacing={5}>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/dashboard" className="searchButtons btn-block">SEARCH TRACKS</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/custom" className="searchButtons btn-block" >CUSTOM MUSIC</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                        </Grid>
                        <div>
                        </div>
                    </div>
                </div>
                <a style={{ color: 'black' }} href="#custom" className="goto-next-black scrolly">Next</a>
            </section>
        )
    }
}

SearchMusic.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
})

export default connect(mapStateToProps,
    {
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
    })(SearchMusic);