import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import './css/UserOrders.css';
// import axios from 'axios';
// import apiconfig from '../../../config/api-config.js';
import { fetchUserOrders } from '../../FlikTraxStore/Actions/ordersActions';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import OrdersPanels from './OrdersPanels';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import EmptyOrders from './EmptyOrders';

class UserOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentview: 'license',
            licenses: {}
        }

        this.cartItems = null;
    }

    componentDidMount() {
        // this.props.fetchUserOrders();
        var currentTotal = this.props.cart.currentOrder.total;
        if (currentTotal > 0) {
            this.setState({ currentview: 'orders' });
        }
    }

    renderOrderTable() {
        //var orderItems = this.props.orders.ordersData;
        // /console.log(orderItems);
        return (
            <FlikTraxLoader active={this.props.api.isLoading}>
                <OrdersPanels />
            </FlikTraxLoader>
        );
    }


    render() {
        return (
            <section id="userorders">
                <div className="content">
                    <header>
                        <Grid container spacing={2} p={30}>
                            <Grid style={{ marginTop: 35 }} item xs={12}>
                                <img style={{ height: 100 }} src="/images/playlist_x.png" alt="Hit It Hard Logo" />
                            </Grid>
                            <Grid style={{ marginTop: 35 }} item xs={12}>
                                <h3>
                                    <Badge badgeContent={this.props.orders.ordersCount} color="primary">
                                        MY ORDERS
                                    </Badge>
                                </h3>
                            </Grid>
                        </Grid>
                    </header>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FlikTraxLoader active={this.props.api.isLoading}>
                                {(this.props.orders.ordersCount === 0) &&
                                    <EmptyOrders />
                                }
                                {(this.props.orders.ordersCount > 0) &&
                                    this.renderOrderTable()
                                }
                            </FlikTraxLoader>
                        </Grid>

                    </Grid>
                </div>
            </section>
        )

        // switch (this.state.currentview) {
        //   case 'orders':
        //     return (
        //       <div>
        //         <LicenseSelect />
        //         {/* {this.renderOrderTable} */}
        //       </div>
        //     );

        //   default:
        //     if (this.props.cart.cartContents.length === 0) {
        //       return (
        //           <div style={{textAlign:"center",padding:50}}>
        //             <EmptyCartCard />
        //           </div>
        //       );
        //     } else {
        //       return (
        //         <div>
        //           <LicenseSelect />

        //         </div>
        //       );
        //     }
        // }

    }
}

UserOrders.propTypes = {
    fetchUserOrders: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    cart: state.cart,
    api: state.api,
    orders: state.orders
})


export default connect(mapStateToProps, { fetchUserOrders })(UserOrders)