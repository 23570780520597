import {
    FETCH_USER_ORDERS,
    //APPLY_COUPON
} from '../Actions/types';

const initialState = {
    ordersData: {},
    ordersCount: 0,
    coupon: {
        affiliate:null, 
        code:null,
        discount: null
    },
    couponApplied: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        

        case FETCH_USER_ORDERS:
            var orderCount = Object.keys(action.payload).length -1;
            return {
                ...state,
                ordersData: action.payload,
                ordersCount: orderCount
            }

            // case APPLY_COUPON:
            //     return {
            //         ...state,
            //         coupon: action.payload,
            //         couponApplied: true
            //     }            

        default:
            return state;
    }
}


