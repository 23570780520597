import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import FlikTraxStore from '../../FlikTraxStore/FlikTraxStore';
import moment from 'moment';
import OrderMixes from './OrderMixes';
import { fetchUserOrders } from '../../FlikTraxStore/Actions/ordersActions';
import { downloadFliktraxFile } from '../../FlikTraxStore/Actions/apiActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ScrollIntoView from 'react-scroll-into-view'


class OrdersPanels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            mixStates: []
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getMixes(trackId) {

        var mixElement = 'mixcontainer-' + trackId;
        var currentStates = this.state.mixStates;

        if (currentStates.indexOf(mixElement) === -1) {
            ReactDOM.render(<Provider store={FlikTraxStore}><OrderMixes trackid={trackId} downloadFliktraxFile={this.props.downloadFliktraxFile} /></Provider>, document.getElementById(mixElement));
            currentStates.push(mixElement);
        } else {
            ReactDOM.render('', document.getElementById(mixElement));
            currentStates = currentStates.slice(currentStates.indexOf(mixElement), 1);
        }
        this.setState({ mixStates: currentStates });

        //document.getElementById(orderId).scrollIntoView();
        //window.scrollTo(0, document.getElementById(trackId).offsetTop) 
    }

    getOrderDT(timestamp) {
        var dateString = moment.unix(timestamp).format("ddd, MMM Do YYYY");
        return dateString;
    }

    getPaymentImage(details) {
        var imageUrl = "";
        switch (details.paymentInstrumentType) {
            case 'paypal_account':
                imageUrl = details.paypal.imageUrl;
                break;

            case 'credit_card':
                imageUrl = details.creditCard.imageUrl;
                break;

            default:
        }

        return imageUrl;
    }

    renderLicenseSummary(data) {
        return (
            <React.Fragment>
                <CardContent>
                    Type: <span style={{ fontWeight: "bold", paddingRight: 15 }}>{data.license.title}</span>
                    <p style={{ marginTop: 15 }}>
                        ID: {data.license.id}<br />
                        Coverage: {data.license.description}
                    </p>
                    <p style={{ padding: 7 }}>
                        <i style={{ marginRight: 7 }} className="fas fa-info-circle"></i> {data.license.coverage}
                    </p>
                </CardContent>
            </React.Fragment>
        )
    }

    renderOrderSummary(details) {

        var orderAmount = details.amount;
        var paymentMethod = '';
        var imageUrl = '';
        var methodDetails = '';

        switch (details.paymentInstrumentType) {
            case 'paypal_account':
                imageUrl = details.paypal.imageUrl;
                paymentMethod = 'PayPal';
                methodDetails = (
                    <div>
                        Account: {details.paypal.payerEmail}<br />
                        Trans ID: {details.paypal.authorizationId}
                    </div>
                );

                break;

            case 'credit_card':
                imageUrl = details.creditCard.imageUrl;
                paymentMethod = 'Credit Card';
                methodDetails = (
                    <div>
                        Type: {details.creditCard.cardType}<br />
                        Last 4:  {details.creditCard.last4}<br />
                        Exp:  {details.creditCard.expirationMonth} / {details.creditCard.expirationYear}<br />
                        Trans ID: {details.networkTransactionId}
                    </div>
                );
                break;

            default:
        }

        return (
            <React.Fragment>
                <CardContent>

                    <img className="float-right" style={{ margin: 0 }} alt={details.paymentInstrumentType} src={imageUrl} />
                    <span style={{ fontWeight: "bold", paddingRight: 15 }}>THANK YOU FOR YOUR ORDER!</span>
                    <Divider style={{ marginBottom: 25, color: "white", width: 1 }} />
                    Method:  {paymentMethod}
                    {methodDetails}
                    <Divider style={{ margin: 0 }} />
                </CardContent>
                <CardActions style={{ margin: 0, float: "right" }}>
                    <Button size="small">Total: {details.currencyIsoCode} {orderAmount}</Button>
                </CardActions>
            </React.Fragment>
        )
    }

    render() {
        var oOrders = this.props.orders.ordersData;
        var orders = Object.keys(oOrders).map(i => oOrders[i]);
        var ordersCount = this.props.orders.ordersCount;
        var pindex = 0;
        return (
            <Grid container>
                <Grid item xs={12}>
                    { orders && (ordersCount > 0) && orders.map((data, index) => {
                        pindex++;
                        //console.log(pindex)
                        if (data.id) {
                            var md5 = require('md5');
                            var orderItems = Object.keys(data.order_items).map(j => data.order_items[j]);
                            var orderStatus = data.order_status[""];
                            var plural = (orderItems.length > 1) ? 's' : '';
                            let userEmail = this.props.user.user.attributes.email;
                            let encodedEmail = md5(userEmail)
                            return (
                                <ExpansionPanel square>
                                    <ExpansionPanelSummary
                                        //onClick={document.getElementById(data.id).scrollIntoView()}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        
                                    >
                                        <img style={{ height: 35 }} className="img-fluid" alt="" src={this.getPaymentImage(orderStatus)} />

                                        <Hidden only="xs">
                                            <span style={{ marginLeft: 15 }}>
                                                {data.order_datetime && this.getOrderDT(data.order_datetime['sec'])}
                                            </span>
                                        </Hidden>
                                        <div style={{ flexBasis: '65%', textAlign: "right" }}>
                                            <Chip size="small"
                                                color="primary"
                                                avatar={<Avatar>{data.order_items.length}</Avatar>}
                                                label={"Track" + plural}
                                            />
                                        </div>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div style={{ width: "100%", overflow: "auto" }} >
                                            <Table className="table table-responsive">
                                                <TableHead style={{ backgroundColor: "#007acc" }}>
                                                    <TableRow >
                                                        <Hidden only="xs">
                                                            <TableCell style={{ fontSize: "1.0em", color: "white", fontFamily: "Gibson W01 SemiBold" }}>Track ID</TableCell>
                                                        </Hidden>
                                                        <TableCell></TableCell>
                                                        <TableCell style={{ fontSize: "1.0em", color: "white", fontFamily: "Gibson W01 SemiBold" }} align="left">Title</TableCell>
                                                        <Hidden only="xs">
                                                            <TableCell style={{ fontSize: "1.0em", color: "white", fontFamily: "Gibson W01 SemiBold" }} align="left">Artist</TableCell>
                                                        </Hidden>
                                                        <TableCell style={{ fontSize: "1.0em", color: "white", fontFamily: "Gibson W01 SemiBold" }} align="center">Downloads</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {orderItems && orderItems.map((item, i) => {
                                                        let $licenseUrl = 'https://dvtcbpcfza5s9.cloudfront.net/license/' + encodedEmail + '_' + item.id + '.pdf';
                                                        var vcount = (item.variation_count - 1);
                                                        var ref = React.createRef();
                                                        return (
                                                            <React.Fragment>
                                                                <TableRow style={{ fontSize: "1.1em" }}>
                                                                    <Hidden only="xs">
                                                                        <TableCell>{item.id}</TableCell>
                                                                    </Hidden>
                                                                    <TableCell align="left" ref={ref}>
                                                                        {item.variation_count &&

                                                                            <Tooltip title={'Download ' + vcount + ' alternate mixes'} placement="top">
                                                                                <ScrollIntoView selector={`#panel` + pindex } alignToTop={true}>
                                                                                <ButtonGroup className="mixBut" size="small" color="primary">
                                                                                    <Button size="small" onClick={() => this.getMixes(item.id)} > + {vcount} </Button>
                                                                                </ButtonGroup>
                                                                                </ScrollIntoView>
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.track_title}
                                                                    </TableCell>
                                                                    <Hidden only="xs">
                                                                        <TableCell>{item.artist_name}</TableCell>
                                                                    </Hidden>
                                                                    <TableCell className="text-nowrap" align="center">

                                                                        <Tooltip title="Download MP3" aria-label="artist" placement="center-bottom">
                                                                            <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.props.downloadFliktraxFile(item.file_path_compressed, false)}><DownloadIcon /></Link>
                                                                        </Tooltip>
                                                                        <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-bottom">
                                                                            <Link style={{ color: "#ca75ff" }} onClick={() => this.props.downloadFliktraxFile(item.file_path_uncompressed, false)} > <DownloadIcon /></Link>
                                                                        </Tooltip>
                                                                        <Tooltip title="Download License" aria-label="artist" placement="center-bottom">
                                                                            <a rel="noopener noreferrer" target="_blank" href={$licenseUrl} style={{ color: "#ff5c36", marginLeft: 15 }}> <i style={{ fontSize: "1.5em", verticalAlign: "bottom" }} class="fas fa-file-pdf"></i></a>
                                                                        </Tooltip>

                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow id = {`panel${pindex}`}>
                                                                    <TableCell colSpan={5} style={{ width: "100%" }}>
                                                                        <Table className="table table-responsive mixcontainer" id={'mixcontainer-' + item.id}></Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    <TableRow>
                                                        <Hidden only="xs">
                                                            <TableCell colSpan={2}>
                                                                <Card className="float-right" style={{ backgroundColor: "white", width: 400, minHeight: 250 }}>
                                                                    {this.renderLicenseSummary(data)}
                                                                </Card>
                                                            </TableCell>
                                                        </Hidden>
                                                        <TableCell colSpan={2}>
                                                            <Card style={{ backgroundColor: "white", minHeight: 250 }}>
                                                                {this.renderOrderSummary(orderStatus)}
                                                            </Card>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>  
                            );
                        }
                    })}
                </Grid>
            </Grid>
        );
    }
}

OrdersPanels.propTypes = {
    fetchUserOrders: PropTypes.func.isRequired,
    downloadFliktraxFile: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    orders: state.orders,
    api: state.api,
    user: state.user
})


export default connect(mapStateToProps, { fetchUserOrders, downloadFliktraxFile })(OrdersPanels)