import { UPDATE_NOW_PLAYING, UPDATE_IS_PLAYING, UPDATE_POSITION, UPDATE_PLAYER_STATUS } from '../Actions/types';

const initialState = {
    currentTrack: null,
    currentTrackTitle:'',
    currentArtist: '',
    currentDuration: '',
    isPlaying: false,
    playerStatus: 'stopped',
    position: 0
}

export default function(state = initialState, action){
    switch(action.type){
        case UPDATE_NOW_PLAYING:
            return {
                ...state,
                currentTrack: action.payload.source,
                currentTrackTitle: action.payload.tracktitle,
                currentArtist: action.payload.artist,
                currentDuration: action.payload.duration,
                currentTrackId: action.payload.trackid
            }

        case UPDATE_IS_PLAYING:
            return {
                ...state,
                isPlaying: action.payload
            }
            
        case UPDATE_PLAYER_STATUS:
            return {
                ...state,
                playerStatus: action.payload
            } 
            
            case UPDATE_POSITION:
                return {
                    ...state,
                    position: action.payload
                }              
            
        default:
            return state;
    }
}