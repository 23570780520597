import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    card: {
        maxWidth: 500,
    },
    media: {
        height: 192
    },
});

function openLoginTab() {
    var tab = document.getElementById("loginTab")
    tab.click();
}

function openRegisterTab() {
    var tab = document.getElementById("registerTab")
    tab.click();
}

export default function UnAuthPlaylistCard() {
    const classes = useStyles();
    var addclass = classes.card + " mx-auto";
    return (
        <Card className={addclass}>
            <CardActionArea style={{ cursor: "default" }}>
                <CardMedia
                    className={classes.media}
                    image="/images/playlist_x.png"
                    title="Login or Register"
                />
                <CardContent>
                    <Typography style={{ fontFamily: "Gibson W01 SemiBold", textAlign: "center", fontSize: "1.0em", padding: 20 }} variant="subTitle1" color="textPrimary" component="p">
                        <i style={{ color: "grey", marginRight: "10px" }} class="fas fa-info-circle"></i>Registered FlikTrax users can create custom playlists to share with friends or collegues. <p style={{ marginTop: 25, textAlign: "center", fontSize: "1.1em" }}>Log In or Register now!</p>
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions >
                <Button onClick={() => { openLoginTab() }} className="mx-auto" fullWidth="true" variant="contained" size="medium" color="primary">
                    Login
                </Button>
                &nbsp;&nbsp;
                <Button onClick={() => { openRegisterTab() }} className="mx-auto" fullWidth="true" variant="contained" size="medium" color="primary">
                    Register
                </Button>
                <p style={{ marginTop: 75 }}>&nbsp;</p>
            </CardActions>
        </Card>
    );
}
