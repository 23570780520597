import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class WelcomeDialog extends React.Component {

  constructor(props) {
    super(props);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }  

  state = {
    open: false,
  };

  componentDidMount(){
    let firstrun = localStorage.getItem('firstRun') || ''
    if(firstrun === 'true'){
      if( this.props.dialogopen == 'true' ){
        this.handleClickOpen();
      }
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    localStorage.setItem('firstRun', false);
  };

  render() {
    return (
      <div>
        <Dialog 
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            <span style={{color:"black"}}>Welcome To FlikTrax!!</span>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
            <p>Welcome and thanks for registering! Fliktrax is committed to providing the best possible search experience for production professionals and buyers to find the right tracks for their media.</p>
              <button onClick={this.handleClose} className="btn btn-lg btn-primary btn-block btn-resend" type="button">Start Searching</button>
            </Typography>
          </DialogContent>

        </Dialog>
      </div>
    );
  }
}

export default WelcomeDialog;
