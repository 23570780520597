import React, { Component } from 'react'
import '../css/TVProsBanner.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class TvProsBanner extends Component {

    render() {

        return (
            <section id="tvprosbanner">
                <div className="content">
                    <header>

                        <Typography>
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <img style={{marginTop:75}} src="/images/icons/FlikTrax_TV_Pros_logo_trans.png" alt="" />
                            </Box>
                        </Typography>
                        <Typography component="h4">
                            <Box textAlign="center" fontWeight={700} fontFamily="Gibson W01 Regular">
                                FLIKTRAX'S SPECIALIZED MUSIC LIBRARY FOR TELEVISION PROFESSIONALS
                            </Box>
                        </Typography>

                        
                        <Box className="tvprosplatform" style={{fontFamily:"Gibson W01 Regular", fontSize: '1.11em', backgroundColor: "rgba(255, 255, 255, 0.25)", color:"black" }}  textAlign="left">
                            The FlikTrax TV PROS platform provides your post production team with our complete TV Music Library. This comprehensive collection is updated daily and offers useful features to help locate, select and store the music most applicable to your needs.
                        </Box>
                       

                    </header>


                    <Grid container spacing={1} style={{ textAlign: 'center', marginTop: 55 }}>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a style={{width:"100%", borderStyle:"solid", borderColor:"black", borderWidth:1, padding:15, color:"black", textDecoration:"none"}} href="https://tvpros.fliktrax.com" target="_blank" className="but-block">GO TO TVPROS</a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                    </Grid>


                </div>
                <a href="#werealize" className="goto-next-black scrolly">Next</a>
            </section>

        )
    }
}

export default TvProsBanner