import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import CartForgotPasswordForm from './ForgotPassword/CartForgotPassword';
import CartForgotPasswordVerifyForm from './ForgotPassword/CartForgotPasswordVerification';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../FlikTraxStore/Actions/userActions';

import { Auth } from 'aws-amplify';
import FormErrors from '../../FormErrors';
import Validate from '../../utility/FormValidation';

//import CircularProgress from '@material-ui/core/CircularProgress';

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.switchForgotVerify = this.switchForgotVerify.bind(this);
  }

  state = {
    viewform: 'login',
    fpusername: "",
    fppassword: "",
    username: "",
    password: "",
    errors: {
      cognito: null,
      blankfield: false
    },
    authenticating: false
  };

  switchForgotVerify = event => {
    this.setState({ viewform: 'forgotverify' })
  }


  redirectPage() {
    window.location.reload();
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  clearAuthState = () => {
    this.setState({ authenticating: false});
  }

  

  onResetPassword = event => {
    this.setState({
      viewform: 'forgot'
    });
  };

  resetPassword = event => {
    event.preventDefault();
    alert("reset")
  }

  handleLogin = async event => {
    event.preventDefault();

    this.setState({ authenticating: true });

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    const { username, password } = this.state;
    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.props.updateAuthStatus(true);
      this.props.setAuthUser(user);
      setTimeout(() => {
        this.redirectPage();
      }, 1000);
      
    } catch (error) {
      this.clearAuthState();
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        }
      })
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  renderLoginForm() {
    return (

      <div>
        <FormErrors formerrors={this.state.errors} />
        <form style={{backgroundColor:"#c4dbe3", padding:50}} className="form-signin" onSubmit={this.handleLogin}>
          <span id="reauth-email" className="reauth-email"></span>
          <input
            type="email"
            id="username"
            className="form-control"
            placeholder="Email"
            value={this.state.username}
            onChange={this.onInputChange}
          />
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            value={this.state.password}
            onChange={this.onInputChange} />

          <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">{this.state.authenticating && <span><i class="fas fa-sync-alt fa-spin" style={{ marginRight: "5px" }} /></span>}LOGIN</button>
        </form>
        <button onClick={this.onResetPassword.bind(this)} style={{ marginTop: "-25px" }} className="btn btn-lg btn-primary btn-block btn-signin"> I FORGOT MY PASSWORD</button>
        {/* <a href="#" className="forgot-password">
                Forgot the password?
            </a> */}
      </div>


    );
  }

  render() {
    switch (this.state.viewform) {
      case 'forgot':
        return (
          <CartForgotPasswordForm closeLoginForm={this.props.closeLoginForm} switchForgotVerify={this.switchForgotVerify} />
        );

      case 'forgotverify':
        return (
          <CartForgotPasswordVerifyForm closeLoginForm={this.props.closeLoginForm} />
        );

      default:
        return this.renderLoginForm();
    }

  }
}

LoginForm.propTypes = {
  updateAuthStatus: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  updateAuthenticating: PropTypes.func.isRequired
}


const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating
  })(withRouter(LoginForm));
