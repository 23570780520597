import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
// import axios from 'axios';
// import apiconfig from '../../../config/api-config.js';
import { removeShoppingCartItem } from '../../../FlikTraxStore/Actions/cartActions';
import FlikTraxLoader from '../../../Dashboard/Loader/Loading';
import LicenseSelect from './LicenseSelect';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RemoveCircle from '@material-ui/icons/Close';
import EmptyCartCard from '../EmptyCartCard';

class OrderList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentview: 'license',
      licenses: {}
    }

    this.cartItems = null;
  }

  componentDidMount() {
    var currentTotal = this.props.cart.currentOrder.total;
    if (currentTotal > 0) {
      this.setState({ currentview: 'orders' });
    }
  }

  componentDidUpdate() {
    this.renderOrderTable();
  }

  removeItem(itemid) {
    this.props.removeShoppingCartItem(itemid);
    var selectedLicense = this.props.cart.currentOrder.selectedLicense;
    if(selectedLicense){
      var selectedRadio = document.getElementById(this.props.cart.currentOrder.selectedLicense.id);
      selectedRadio.click();
    }
  }

  getTotalDialog() {
    //console.log(this.props.cart.currentOrder.total)
    var ccapplied = localStorage.getItem('couponapplied');
    if (ccapplied) {
      var appliedcoupon = JSON.parse(localStorage.getItem('appliedcoupon'));
      var wodiscount = this.props.cart.currentOrder.total + (this.props.cart.currentOrder.total * appliedcoupon.discount);
      return (
        <React.Fragment>
          {this.props.cart.currentOrder.total && <span>{appliedcoupon.code} applied ({appliedcoupon.discount * 100}% off): </span>}
          <span style={{ textDecoration: "line-through", color: "red" }}>
            {this.props.cart.currentOrder.total && '$' +  this.props.cart.currentOrder.total && wodiscount.toFixed(2)}
          </span>
          <span style={{ marginLeft: 15 }}>{this.props.cart.currentOrder.total && '$' + this.props.cart.currentOrder.total.toFixed(2)}</span>
        </React.Fragment>
      );
    } else {
      return (this.props.cart.currentOrder.total && <React.Fragment>${this.props.cart.currentOrder.total.toFixed(2)}</React.Fragment>);
    }
  }

  renderRemoveButton(id) {
    return (
      <RemoveCircle style={{ cursor: "pointer" }} onClick={() => this.removeItem(id)} />
    )
  }

  renderOrderTable() {
    var cartItems = this.props.cart.cartContents;
    return (
      <FlikTraxLoader active={this.props.api.isLoading}>

        <React.Fragment>
          <Table style={{ fontSize: "0.90em", backgroundColor: "#edf7fc" }}>
            <TableHead>
              <TableRow>
                <TableCell>Track ID</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="center">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems && cartItems.map((data, index) => {
                return (
                  <TableRow id={data.id}>
                    <TableCell>{data.id}</TableCell>
                    <TableCell align="left">
                      <Tooltip title={"Artist:" + data.artist_name} aria-label="artist" placement="center-top">
                        <Typography>{data.track_title}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">{this.props.cart.currentOrder.itemPrice && this.props.cart.currentOrder.itemPrice > 0 ? this.props.cart.currentOrder.itemPrice : ""}</TableCell>
                    <TableCell align="center">{this.renderRemoveButton(data.id)}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell rowSpan={3} />

                {/* <TableCell align="right" style={{ fontWeight: "bold" }} colSpan={2}>Subtotal</TableCell> */}
                {/* <TableCell align="left" style={{ fontWeight: "bold" }}>{this.props.cart.currentOrder.total && this.props.cart.currentOrder.total.toFixed(2)}</TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell align="right" style={{ fontWeight: "bold" }} colSpan={2}>Total</TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>{this.getTotalDialog()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </React.Fragment>
      </FlikTraxLoader>
    );
  }


  render() {


    switch (this.state.currentview) {
      case 'orders':
        return (
          <div>
            <LicenseSelect />
            {/* {this.renderOrderTable} */}
          </div>
        );

      default:
        if (this.props.cart.cartContents.length === 0) {
          return (
            <div style={{ textAlign: "center", padding: 50 }}>
              <EmptyCartCard />
            </div>
          );
        } else {
          return (
            <div>
              <LicenseSelect />
              {this.renderOrderTable()}
            </div>
          );
        }
    }

  }
}

OrderList.propTypes = {
  removeShoppingCartItem: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  cart: state.cart,
  api: state.api,
})


export default connect(mapStateToProps, { removeShoppingCartItem })(OrderList)