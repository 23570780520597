import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import '../css/HitItHardBanner.css';
import Typography from '@material-ui/core/Typography';

export class HitItHardBanner extends Component {
    render() {
        return (
            <section id="hitithard">
                <div className="content">
                    <header>
                        <Grid container spacing={2}>
                            <Grid style={{marginTop:35}}  item xs={12}>
                            <img src="/images/icons/HitIt_hard_logo_trans.png" alt="Hit It Hard Logo" />
                            </Grid>
                            <Grid style={{marginTop:35}} item xs={12}>
                                <h3>HIT IT HARD - FLIKTRAX CUSTOM MUSIC DIVISION</h3>
                            </Grid>
                        </Grid>
                    </header>

                    <Grid  style={{marginTop:55}} container spacing={2}>
                        <Grid  item xs={12} sm={6}>
                            <Typography className="linkblock"  component="div">
                                When your media demands a more detailed, specialized musical approach, our elite collective of award-winning, experienced professionals are available to create the perfect soundtrack for your production.
                            </Typography>
                        </Grid>                                               
                    </Grid>

                    <div className="row actions center-block">
                        <Grid container style={{ marginTop: 75 }} spacing={5}>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                                <Link className="searchButtons" to="#">SHOWCASE</Link>
                            </Grid> */}
                        </Grid>
                        <div>
                        </div>
                    </div>

                </div>
                <a style={{color: 'black'}} href="#hitithardshowcase" className="goto-next scrolly">Next</a>
            </section>
        )
    }
}

export default HitItHardBanner
