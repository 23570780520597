import React, { Component } from 'react'
import { connect } from 'react-redux';
import Navigation from '../Header/Navigation';
import UserOrders from './Sections/UserOrders';
import { Footer } from '../Footer/Footer';

class Orders extends Component {

  // componentDidMount() {
  //   window.scrollTo(0, 0);
  //   const mainscript = document.createElement("script");
  //   mainscript.src = "/assets/js/main-external.js";
  //   mainscript.async = true;
  //   document.body.appendChild(mainscript);
  //   const bsScript = document.createElement("script");
  //   bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
  // }

  render() {
    return (
      <div className="About">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          <UserOrders />
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cart: state.cart,
})

export default connect(mapStateToProps, {})(Orders)
