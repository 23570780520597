import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../FlikTraxStore/Actions/snackbarActions';
import CloseIcon from '@material-ui/icons/Close';
import './HomeSearch.css'

class HomeSearch extends Component {

    constructor(props) {
        super(props);

        this.rdxProps = props.rdxProps;

        this.onKeyPressed = this.onKeyPressed.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);

        this.state = {
            smode: 1,
            searchterms: '',
            inputerror: false
        };
    }

    encIOSURL(str) {
        var encStr = str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
        return encStr;
      }

    onSearchClick = (e) => {
        //e.preventDefault();
        var searchType = this.state.smode;
        var searchterm = document.getElementById("home_search");
        var searchstr = searchterm.value.trim();
        if (searchstr === "") {
            e.preventDefault();
            this.props.enqueueSnackbar({
                message: "Please enter a search term!",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    action: key => (
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                    ),
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    preventDuplicate: true,
                    autoHideDuration: 1500
                },
            });
        }
        var iOSstr =  this.encIOSURL(searchstr);
        var encsearch = encodeURIComponent(iOSstr);
        var apiUrl = 'search?searchtype=' + searchType + '&searchterm=' + encsearch;
        this.rdxProps.switchMasterMode('tracks');
        this.rdxProps.switchApiPage(1);
        this.rdxProps.switchApiUrl(apiUrl);
    };

    onKeyPressed(e) {
        if (e.key === "Enter") {
            var searchbtn = document.getElementById("searchBtn");
            var searchType = this.state.smode;
            var searchterm = e.currentTarget.value;
            var searchstr = searchterm.trim();
            if (searchstr === "") {
                e.preventDefault();
                this.props.enqueueSnackbar({
                    message: "Please enter a search term!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'warning',
                        action: key => (
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                        ),
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        preventDuplicate: true,
                        autoHideDuration: 1500
                    },
                });
            }
            var iOSstr =  this.encIOSURL(searchstr);
            var encsearch = encodeURIComponent(iOSstr);
            var apiUrl = 'search?searchtype=' + searchType + '&searchterm=' + encsearch;
            this.rdxProps.switchMasterMode('tracks');
            this.rdxProps.switchApiPage(1);
            this.rdxProps.switchApiUrl(apiUrl);
            searchbtn.click();
        }
    }


    render() {
        return (
            <div id="searchGroup" className="input-group mx-auto form-inline d-flex flex-nowrap">
                <input className="input-group-append" style={{ borderRadius: "0px" }} onKeyDown={this.onKeyPressed} type="text" name="home_search" id="home_search" placeholder="Exotic Dreamy Compassionate"></input>
                <div class="input-group-append ">
                    <Link to="/dashboard" onClick={this.onSearchClick} class="input-group-text" id="searchBtn" style={{ color: "white", backgroundColor: "#0e6bae" }}><i class="fas fa-search fa-1x"></i></Link>
                </div>
            </div>
        )
    }
}

HomeSearch.propTypes = {
    removeSnackbar: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    notifications: state.snackbar
})

export default connect(mapStateToProps,
    {
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar
    })(HomeSearch);
