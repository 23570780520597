import React, { Component, useContext } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserVocalType } from '../../../FlikTraxStore/Actions/userActions';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
class VocalTypeChip extends Component {

    deleteVocalType = (event) => { 
      this.props.updateUserVocalType(null);
    }

    render() {
        return(
            <div>
              <ul>
              {this.props.user.userVocalType && 
                <span style={{margin:25}}>
                  <Chip size="small" 
                        color="default" 
                        label={this.props.user.userVocalType}
                        avatar={<Avatar>VT</Avatar>} 
                        onDelete={() => { this.deleteVocalType() }} />
                </span>  
              }
              </ul>
            </div>

         )
      }
}

VocalTypeChip.propTypes = {
  updateUserVocalType: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  { updateUserVocalType })
  (VocalTypeChip);