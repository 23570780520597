import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar'

class StyleChips extends Component {

  deleteStyle(selected) {
    var currents = this.props.user.userStyles;
    var rmIndex = currents.indexOf(selected);
    currents.splice(rmIndex, 1);
    this.props.updateUserFilters(currents);
  }

  render() {
    return (
      <div>


        <ul>
          {this.props.user.userStyles && this.props.user.userStyles.map(style => (
                              <span style={{margin:25}}>
                                <Chip size="small" 
                                      color="secondary" 
                                      label={style}
                                      avatar={<Avatar>S</Avatar>} 
                                      onDelete={() => { this.deleteStyle(style) }} />
                              </span>   
                            ))}
        </ul>

      </div>

    )
  }
}

StyleChips.propTypes = {
  updateUserFilters: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  { updateUserFilters })
  (StyleChips);