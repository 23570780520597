import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Link } from 'react-router-dom';


class OrderMixes extends Component {

    constructor(props) {
        super(props);
        this.baseProps = props;
        this.state = {
            trackId: props.trackid,
            mixData: {},
            // isLoading: false,
            // isPlaying: false,
            // currentTrack: null,
            mixStates: [],
            isLoading: false
        }
    }

    getMixData() {
        var thisState = this;
        thisState.setState({ isLoading: true });
        var axiosUrl = apiconfig.api.APIURL + 'mixes?track_id=' + this.state.trackId;
        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })
            .then(function (response) {
                thisState.setState({ mixData: response.data._embedded.mixes })
                thisState.setState({ isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentWillMount() {
        this.getMixData();
    }

    render() {

        let mixes = this.state.mixData

        return (
                <BrowserRouter>
                    {mixes.length > 0 && mixes.map((data, index) => {
                        let dataAttrs = {
                            'data-source': data.preview_url,
                            'data-tracktitle': data.track_title,
                            'data-artist': data.artist_name,
                            'data-description': data.description,
                            'data-duration': data.duration,
                            'data-vocaltype': data.vocal_type,
                            'data-trackid': data.id,
                            'data-filecompressed': data.file_path_compressed,
                            'data-fileuncompressed': data.file_path_uncompressed
                        };
                        if (data.track_title) {
                            return (

                                <TableRow style={{ fontSize: "1.1em", backgroundColor: 'rgba(31,137,177,0.08)', padding: 25 }}>
                                    <Hidden only="xs">
                                        <TableCell>{data.id}</TableCell>
                                    </Hidden>
                                    <TableCell>
                                        {data.track_title}
                                    </TableCell>
                                    <Hidden only="xs">
                                        <TableCell>{data.artist_name}</TableCell>
                                    </Hidden>
                                    <TableCell className="text-nowrap" align="right">
                                        <React.Fragment>
                                            <Tooltip title="Download MP3" aria-label="artist" placement="center-bottom">
                                                <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.baseProps.downloadFliktraxFile(data.file_path_compressed, false)}><DownloadIcon /></Link>
                                            </Tooltip>
                                            <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-bottom">
                                                <Link style={{ color: "#ca75ff" }} onClick={() => this.baseProps.downloadFliktraxFile(data.file_path_uncompressed, false)} > <DownloadIcon /></Link>
                                            </Tooltip>
                                        </React.Fragment>
                                    </TableCell>
                                </TableRow>

                            );
                        }
                    })}

                </BrowserRouter>
        );
    }
}





OrderMixes.propTypes = {
}

const mapStateToProps = state => ({
    user: state.user,
    api: state.api
})


export default connect(mapStateToProps, {})(OrderMixes)