import { FETCH_FILTER_DATA } from './types';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';

export const fetchFilterData = (filter) => dispatch => {
    var page = FlikTraxStore.getState().filters.apiPage;
    var axiosUrl = apiconfig.api.APIURL + filter + '?page=' + page + '&group=2';
    let slot = filter + 'Data';
    console.log(axiosUrl);

    axios({
        baseURL: axiosUrl,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        timeout: 2000,
    }).then(response => {
        dispatch({
            type: FETCH_FILTER_DATA,
            payload: response.data,
            filterSlot: slot,
            filterName: filter 
        })
    })
        .catch(error => {
            throw (error);
        });
}