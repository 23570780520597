import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Navigation from '../Header/Navigation';
import UserPlaylist from './Sections/UserPlaylist';
import UserPlaylistUnAuth from './Sections/UserPlaylistUnAuth';
import { Footer } from '../Footer/Footer';

class MyPlaylist extends Component {

  constructor(props) {
    super(props);
    this.isAuthenticated = props.user.isAuthenticated;
  }


  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation />
          {!this.isAuthenticated &&
            <UserPlaylistUnAuth />
          }
          {this.isAuthenticated &&
            <UserPlaylist />
          }
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  {})(withRouter(MyPlaylist));
