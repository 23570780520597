import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import DeletePlaylistIcon from '@material-ui/icons/DeleteSweep';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteConfirm(props) {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const clearDefaultPlaylist = () => {
        props.clearPlaylist();
        setOpen(false);
        props.enqueueMessage({
            message: 'Playlist Cleared! Please wait...',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });

        setTimeout(() => {
            window.location.reload();
        }, 2000);

    }

    return (
        <div>
            <Button style={{ fontFamily: "Gibson W01 SemiBold", textDecoration: 'none', marginLeft: 15 }} variant="contained" color="primary" onClick={handleClickOpen}>
                <Tooltip title="Clear Playlist" placement="top">
                    <DeletePlaylistIcon />
                </Tooltip>
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"><h3 style={{ color: 'black' }}>Clear Playlist?</h3></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to clear your playlist?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearDefaultPlaylist} color="primary">
                        Clear Playlist
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
