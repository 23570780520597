import React, { Component } from 'react'

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { switchMasterMode, switchApiPage, switchApiUrl } from '../../FlikTraxStore/Actions/apiActions';
import HomeSearch from '../../Header/HomeSearch';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

class Banner extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        //console.log(this.props)
        return (
            <section id="banner">
                <div className="content">
                    <header>
                        <Grid container style={{ marginTop: 25 }} spacing={5}>
                            <Grid item xs={12}>
                                <Typography component="h2">
                                    <Box textAlign="center" fontWeight={900}>
                                        <h2>FLIKTRAX MUSIC SOLUTIONS</h2>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: -15 }}>
                                <Typography component="h4">
                                    <Box textAlign="center" fontWeight={300} fontFamily="Gibson W01 SemiBold">
                                        PREMIER MUSIC FOR MEDIA
                                </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h3">
                                    <Box textAlign="center" fontWeight={300} fontFamily="Gibson W01 Regular" marginTop={17} marginBottom={2}>
                                        START SEARCHING FOR MUSIC
                                        </Box>
                                </Typography>
                                <HomeSearch rdxProps={this.props} />
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 125 }} spacing={5}>
                            <Grid item xs={12} sm={3}>
                            </Grid>                            
                            <Grid item xs={12} sm={3}>
                                <Link to="/dashboard" className="bannerButtons btn-block">SEARCH TRACKS</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/custom" className="bannerButtons btn-block" >CUSTOM MUSIC</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            </Grid>                             
                        </Grid>
                    </header>
                </div>
                <a href="#search" className="goto-next scrolly">Next</a>
            </section>
        )
    }
}

Banner.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
})

export default connect(mapStateToProps,
    {
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
    })(Banner);