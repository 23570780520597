import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserDuration } from '../../../FlikTraxStore/Actions/userActions';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const durationObj = {
                '00:01~00:59':'59 sec and Below',
                '01:00~02:00':'1:00 Min - 2:00 MIn',
                '02:00~30:00':'2.01 Min and Above'
            };

const DurationsRadios = ({ data }) =>
  Object.entries(durationObj).map(([k, v]) => (
    <FormControlLabel className="filter-radios" value={k} control={<Radio />} label={v} />
  ));

function Durations(props) {
  const classes = useStyles();

  function handleDurationChange(event) {
    props.updateUserDuration(event.target.value) 
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        {/* <FormLabel component="legend">Vocal Type</FormLabel> */}
        <RadioGroup
          aria-label="VocalType"
          name="vocaltype"
          className={classes.group}
          onChange={handleDurationChange}
        >
          <DurationsRadios />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

Durations.propTypes = {
  updateUserDuration : PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return { user: state.user };
} 

export default connect(mapStateToProps, { updateUserDuration })(Durations);
