import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Navigation from '../Header/Navigation';
import SharedPlaylist from './Sections/SharedPlaylist';
import { Footer } from '../Footer/Footer';

class UserSharedPlaylist extends Component {


//   componentWillMount() {
//     if(!this.props.user.isAuthenticated){
//       this.props.history.push('/');
//     }
//   }

  componentDidMount() {

    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation />
          <SharedPlaylist />
        </div>
        <Footer />
      </div>
    )
  }
}

UserSharedPlaylist.propTypes = {

}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, {})(withRouter(UserSharedPlaylist));
