import React, { Component } from 'react'
import '../css/AboutFliktrax.css';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class AboutFliktrax extends Component {

    render() {

        return (
            <section id="aboutfliktrax">
                <div className="content">
                    <header>

                        <Typography component="h2">
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <h2>ABOUT FLIKTRAX</h2>
                            </Box>
                        </Typography>
                        <Typography component="h4">
                            <Box textAlign="center" fontWeight={400} fontFamily="Gibson W01 Regular">
                                FLIKTRAX IS RUN BY MUSICIANS WHO KNOW AND LOVE MUSIC
                            </Box>
                        </Typography>


                        
                        <Box className="directrel"  style={{ backgroundColor: "rgba(13, 135, 177, 0.50)" }} textAlign="left" fontWeight={300} fontFamily="Gibson W01 Regular" >
                                We have a direct relationship with the music we license and the artists we work with. This hands-on approach enables us to guide you to exactly what you need. Fliktrax’s team of music pros are always available to personally assemble a playlist from your request.
                        </Box>
                        
                    </header>
                </div>
            <a href="#weknow" className="goto-next scrolly">Next</a>
            </section >

        )
    }
}

export default AboutFliktrax