import React, { Component } from 'react'
import '../css/CustomMusic.css';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class Custom extends Component {

    render() {

        return (
            <section id="custommusic">
                <div className="content">
                    <header>

                        <Typography component="h2" style={{ marginTop: 50 }} >
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <h2>CUSTOM MUSIC</h2>
                            </Box>
                        </Typography>
                        <Typography component="h4">
                            <Box textAlign="center" fontWeight={300} fontFamily="Gibson W01 SemiBold">
                                OUR DIVERSE TEAM OF COMPOSERS CREATE SONIC EXCELLENCE OUT OF THIN AIR
                            </Box>
                        </Typography>

                        <Typography style={{ width: "90%", lineHeight: '1.8em' }} id="customcontent" component="div">
                            <Box className="linkblock" marginTop={15} style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }} p={7} m={5} textAlign="left" fontFamily="Gibson W01 Regular" fontSize="1.1em">
                                FlikTrax's custom music division is available to clients who's musical needs are more detailed and demanding. By first understanding exactly what is needed, we dive deep to craft dynamic audio to compliment and elevate your media.
                            </Box>
                        </Typography>

                    </header>


                    <Grid container spacing={1} style={{ textAlign: 'center', marginTop: 55 }}>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Link to="/hit-it-hard" className="button customBut btn-block">HIT IT HARD</Link>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Link to="/tvpros/" className="button customBut btn-block">TVPROS</Link>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                    </Grid>
                </div>
                <a href="#clients" className="goto-next scrolly">Next</a>
            </section>

        )
    }
}

export default Custom