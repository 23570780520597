import React, { Component } from 'react'
import NewsletterSignup from './NewsletterSignup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './Footer.css';

export class Footer extends Component {

    openLoginTab() {
        var tab = document.getElementById("loginTab")
        tab.click();
    }

    openRegisterTab() {
        var tab = document.getElementById("registerTab")
        tab.click();
    }

    openOlarkBox() {
        var tab = document.getElementsByClassName("olark-launch-button");
        tab[0].click();
    }

    redirectDash = (e) => {
        var dataset = e.currentTarget.dataset;
        var mode = dataset.mode;
        var rdUrl = "/dashboard?mode=" + mode;
        window.location.replace(rdUrl);
    }


    render() {
        return (
            <div id="footer">
                <Grid container
                    direction="row"
                    // justify="space-evenly"
                    // alignItems="center" 
                    spacing={6}
                    style={{ padding: 24 }} 
                    >
                    <Grid className="cardBox" item xs={12} sm={4} >

                        <Typography className="subHead">
                            Company Goals
                        </Typography>
                        <Typography style={{width:"85%"}} className="subBody ftagline">
                            Fliktrax is committed to providing the best possible search experience for music supervisors and buyers to find the right tracks for their media.
                        </Typography>

                    </Grid>
                    <Grid className="cardBox" item xs={12} sm={4}>


                        <Typography className="subHead">
                            Contact Information
                        </Typography>
                        <Typography component="address" className="subBody">
                            74 Hilltop Road<br />
                            Rhinebeck, NY, 12572<br />
                            Phone: 201-390-3182<br />
                            Email: <a href="mailto:info@fliktrax.com">info@fliktrax.com</a>
                        </Typography>
                    </Grid>
                    <Grid className="cardBox" item xs={12} sm={4} >
                        <Typography style={{ marginBottom: "15px" }} className="subHead">
                            Sign up for our newsletter
                        </Typography>
                        <NewsletterSignup />
                    </Grid>
                </Grid>
                <Grid container width="33%" >
                    <Grid item xs={12} sm={12}  >
                        <hr />
                    </Grid>
                </Grid>
                <Grid id="subfooter" container spacing={2} style={{ padding: 24 }} >
                    <Grid className="cardBox" item xs={12} sm={3}  >


                        <Typography className="subHead">
                            Home
                        </Typography>
                        <Typography className="subBody">
                            <Link to="/">Fliktrax.com</Link>
                        </Typography>
                        <Typography className="subHead">
                            &nbsp;
                                    </Typography>
                        <Typography className="subHead">
                            <a href="/">About</a>
                        </Typography>
                        <Typography className="subBody">
                            <Link to="/about">About FlikTrax</Link><br />
                            <a href="/about#pricing">Pricing</a><br />
                            <Link onClick={() => { this.openOlarkBox() }}>Contact Us</Link>
                        </Typography>


                    </Grid>
                    <Grid className="cardBox" item xs={12} sm={3} >

                        <Typography className="subHead">
                            Search Tracks
                        </Typography>
                        <Typography className="subBody">
                            <p style={{ marginTop: 7 }}>
                                <Link data-mode="categories" onClick={this.redirectDash}>Production Genres</Link><br />
                                <Link data-mode="moods" onClick={this.redirectDash}>Moods &amp; Emotion</Link><br />
                                <Link data-mode="instruments" onClick={this.redirectDash}>Instrumentation</Link><br />
                                <Link data-mode="styles" onClick={this.redirectDash}>Musical Styles</Link><br />
                                <Link to="/new-releases">New Releases</Link>
                            </p>
                        </Typography>
                    </Grid>
                    <Grid className="cardBox" item xs={12} sm={3}  >


                        <Typography className="subHead">
                            Custom Music
                                    </Typography>
                        <Typography className="subBody">
                            <Link to="/tvpros">TVPros</Link><br />
                            <Link to="/hit-it-hard">Hit it Hard</Link><br />
                        </Typography>

                    </Grid>
                    <Grid className="cardBox" item xs={12} sm={3}>


                        <Typography className="subHead">
                            Submit Music<br />
                        </Typography>
                        <Typography className="subBody">
                            <a target="_blank" rel="noreferrer" href="https://contributors.fliktrax.com">Contributors</a><br />
                        </Typography>

                        <br />
                        <Typography className="subHead">
                            FlikTrax
                        </Typography>
                        <Typography className="subBody">
                            <Link onClick={() => { this.openLoginTab() }} >Log In</Link><br />
                            <Link onClick={() => { this.openRegisterTab() }} >Register</Link>
                        </Typography>


                    </Grid>
                </Grid>
                <Grid id="copy" container
                    spacing={2}
                    style={{ padding: 24 }} >
                    <Grid item xs={12} sm={12}>
                        &copy; FlikTrax Music Licensing
                            <span style={{ float: "right" }}>
                            CONNECT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Fliktrax/"><i className="fab fa-facebook-square fa-2x"></i></a> &nbsp;
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/Fliktrax"><i className="fab fa-twitter fa-2x"></i></a> &nbsp;
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/fliktrax/"><i className="fab fa-instagram fa-2x"></i></a> &nbsp;
                            </span>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Footer

