import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ForgotPasswordForm from './ForgotPassword/ForgotPassword';
import ForgotPasswordVerifyForm from './ForgotPassword/ForgotPasswordVerification';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../FlikTraxStore/Actions/userActions';
import Recaptcha from 'react-google-invisible-recaptcha';

import { Auth } from 'aws-amplify';
import FormErrors from '../../FormErrors';
import Validate from '../../utility/FormValidation';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.switchForgotVerify = this.switchForgotVerify.bind(this);
    this.onRcResolved = this.onRcResolved.bind( this );
  }

  state = {
    viewform: 'login',
    fpusername: "",
    fppassword: "",
    username: "",
    password: "",
    rcvalue: "",
    errors: {
      cognito: null,
      blankfield: false,
      captcha:false
    },
    authenticating: false
  };

  switchForgotVerify = event => {
    this.setState({ viewform: 'forgotverify' })
  }


  redirectPage() {
    switch (window.location.pathname) {
      case '/':
        window.location.reload();
        break;

      default:
        window.location.replace('/');
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        captcha:false
      }
    });
  };

  clearAuthState = () => {
    this.setState({ authenticating: false });
  }



  onResetPassword = event => {
    this.setState({
      viewform: 'forgot'
    });
  };

  resetPassword = event => {
    event.preventDefault();
    alert("reset")
  }

  onRcResolved() {
    alert( 'Recaptcha resolved with response: ' + this.recaptcha.getResponse() );
  }

  handleLogin = async event => {
    event.preventDefault();

    this.setState({ authenticating: true });

    // Form validation
    this.clearErrorState();

    // this.recaptcha.execute();
    // var rcresp = null;
    // let rcerr = false;
    // setTimeout(() => {
    //   rcresp = this.recaptcha.getResponse();
    // }, 1000);

    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // if('' !== rcresp){
    //   this.setState({
    //     errors: { ...this.state.errors, ...error }
    //   });
    // }

    const { username, password } = this.state;
    try {
      
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.props.updateAuthStatus(true);
      this.props.setAuthUser(user);
      setTimeout(() => {
        this.redirectPage();
      }, 1000);

    } catch (error) {
      this.clearAuthState();
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        }
      })
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  renderLoginForm() {
    return (

      <div className="card card-container">

        <h2>
          Login
            <Tooltip className="float-right" title="Cancel/Close" aria-label="Log In" placement="top">
            <Button onClick={this.props.closeLoginForm} size="small">
              <CloseIcon />
            </Button>
          </Tooltip>
        </h2>
        <h3>Login to your FlikTrax account</h3>
        <FormErrors formerrors={this.state.errors} />
        <form className="form-signin" onSubmit={this.handleLogin}>
          <span id="reauth-email" className="reauth-email"></span>
          <input
            type="email"
            id="username"
            className="form-control"
            placeholder="Email"
            value={this.state.username}
            onChange={this.onInputChange}
          />
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            value={this.state.password}
            onChange={this.onInputChange} />

          <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">{this.state.authenticating && <span><i class="fas fa-sync-alt fa-spin" style={{ marginRight: "5px" }} /></span>}LOGIN</button>          
        </form>
        <button onClick={this.onResetPassword.bind(this)} style={{ marginTop: "-25px", marginBottom:25 }} className="btn btn-lg btn-primary btn-block btn-signin"> I FORGOT MY PASSWORD</button>
        <div className="mx-auto">
        <Recaptcha
          ref={ ref => this.recaptcha = ref }
          badge="inline"
          sitekey="6LcXCcsZAAAAAFTy38N_DLHNuoj4vXzsaYv9MICB"
          //onResolved={ this.onRcResolved } 
          //onError={ () => console.log( 'Botdetected.' ) } 
          />
          </div>
      </div>


    );
  }

  render() {
    switch (this.state.viewform) {
      case 'forgot':
        return (
          <ForgotPasswordForm closeLoginForm={this.props.closeLoginForm} switchForgotVerify={this.switchForgotVerify} />
        );

      case 'forgotverify':
        return (
          <ForgotPasswordVerifyForm closeLoginForm={this.props.closeLoginForm} />
        );

      default:
        return this.renderLoginForm();
    }

  }
}

LoginForm.propTypes = {
  updateAuthStatus: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  updateAuthenticating: PropTypes.func.isRequired
}


const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating
  })(withRouter(LoginForm));
