import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar'

class InstrumentChips extends Component {

  deleteInstrument(selectedInstrument) {
    var currentInstruments = this.props.user.userInstruments;
    var rmIndex = currentInstruments.indexOf(selectedInstrument);
    currentInstruments.splice(rmIndex, 1);
    this.props.updateUserFilters(currentInstruments);
  }

  render() {
    //console.log(this.props)
    return (
      <div>
        <ul>
          {this.props.user.userInstruments && this.props.user.userInstruments.map(instrument => (
            <Chip size="small"
              color="primary"
              label={instrument}
              avatar={<Avatar>I</Avatar>}
              onDelete={() => { this.deleteInstrument(instrument) }} />
          ))}
        </ul>
      </div>

    )
  }
}

InstrumentChips.propTypes = {
  updateUserFilters: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  { updateUserFilters })
  (InstrumentChips);