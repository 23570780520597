import React, { useContext, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { fetchApiData, switchApiPage } from '../../../FlikTraxStore/Actions/apiActions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'dark'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 1.55),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

function SimplePaginator(props) {

  const classes = useStyles();

  function handleChangePage(event, newPage) {
    var nextPage = newPage + 1;
    props.switchApiPage(nextPage);
    props.fetchApiData();
    window.scrollTo(0,200);
  }

  var currpage = props.apidata.page - 1;
  var rowsPerPage = props.apidata.page_size;
  var totalItems  = props.apidata.total_items;

  return (
    <div  style={{display:'inline-block'}} className={classes.root}>
      {totalItems &&
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={currpage}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
            />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { api: state.api };
} 

SimplePaginator.propTypes = {
  switchApiPage: PropTypes.func.isRequired,
  fetchApiData: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired
}


export default connect(mapStateToProps, {switchApiPage, fetchApiData})(SimplePaginator);