import React, { Component } from 'react'
import Navigation from '../Header/Navigation';
import { UserNav } from '../UserNav/UserNav';
import { AboutFliktrax } from './Sections/AboutFliktrax';
import { WeKnow } from './Sections/WeKnow';
import { WeRealize} from './Sections/WeRealize';
import { Pricing } from './Sections/Pricing';
import { Footer } from '../Footer/Footer';

export class About extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="About">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          <AboutFliktrax />
          <WeKnow />
          <WeRealize />
          <Pricing />
        </div>

        <Footer />
      </div>

    )


  }
}

export default About
