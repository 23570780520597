import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export class CustomMusic extends Component {
    render() {
        return (
            <section id="custom">

                <div className="content">

                    <header>
                        <Grid spacing={2}>
                            <Grid item xs={12}>
                                <h2>Custom Music</h2>
                            </Grid>
                        </Grid>
                    </header>

                    <Grid container spacing={2} style={{textAlign:'center'}}>
                        <Grid item xs={12} sm={6}>
                            <div  className="col-sm">
                                <img src="/images/icons/FlikTrax_TV_Pros_logo_with tint.png" alt="TVPros Logo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="col-sm">
                                <img src="/images/icons/HIH_Logo_with tint.png" alt="Hit It Hard Logo" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{fontFamily:"Gibson W01 Regular", color:'white', fontWeight:700, fontSize:"1.0em", paddingLeft:75, paddingRight:75}} component="h2">
                                    FlikTrax TVPros is our specialized music library for television professionals.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography  style={{fontFamily:"Gibson W01 Regular", color:'white', fontWeight:700, fontSize:"1.0em", paddingLeft:75, paddingRight:75}} component="h2">
                            Hit It Hard is our award-winning custom music division, specializing in television, advertising and streaming media.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Link to="/tvpros" className="button custBut">MORE ABOUT TVPROS</Link>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Link to="/hit-it-hard"className="button custBut">MORE ABOUT HIT IT HARD</Link>
                        </Grid>                        

                    </Grid>
                </div>
                <a href="#footer" className="goto-next scrolly">Next</a>
            </section>
        )
    }
}

export default CustomMusic


