import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SavePlaylistIcon from '@material-ui/icons/SaveAlt';
import Tooltip from '@material-ui/core/Tooltip';

export default function SaveConfirm(props) {

    const [open, setOpen] = React.useState(false);

    const [title, setTitle] = React.useState('');

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const saveDefaultPlaylist = (e) => {
        if(title.trim() === ''){
            props.enqueueMessage({
                message: 'Please Enter a Title!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    preventDuplicate: true,
                    autoHideDuration: 1500
                },
            });
            return;
        }

        props.savePlaylist(title);
        
        props.enqueueMessage({
            message: 'Playlist Saved Successfully',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });

        setTimeout(() => {
            setOpen(false);
        }, 2000);

    }

    return (
        <div>
            <Button style={{ fontFamily: "Gibson W01 SemiBold", textDecoration: 'none' }} variant="contained" color="primary" onClick={handleClickOpen}>
                <Tooltip title="Save Playlist" placement="top">
                    <SavePlaylistIcon />
                </Tooltip>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><h3 style={{ color: 'black' }}>Save Playlist</h3></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a title for this playlist
            </DialogContentText>
                    <TextField
                        value={title} 
                        onChange={e => setTitle(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="listtitle"
                        label="Title"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveDefaultPlaylist} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                     </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
