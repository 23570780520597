import React, { Component } from 'react'
import { connect } from 'react-redux';
import Navigation from '../Header/Navigation';
import UserCart from './Sections/UserCart';
import { Footer } from '../Footer/Footer';

class Cart extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);
    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="About">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          <UserCart />
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cart: state.cart,
})

export default connect(mapStateToProps, {})(Cart)
