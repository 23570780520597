import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateOrder } from '../../FlikTraxStore/Actions/cartActions';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import { downloadFliktraxFile } from '../../FlikTraxStore/Actions/apiActions';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/CloudDownload';

function Confirmation(state) {

    useEffect(() => {
        mapStateToProps({ state });
        state.updateOrder('currentStep', 4);
    }, []);

    return (

        < FlikTraxLoader >
            <div style={{ padding: 50 }}>
                <Typography style={{ color: "black" }} variant="h5" gutterBottom>
                    Thank you for your order.
                </Typography>


                <Typography style={{ color: "black" }} variant="subtitle1">
                    Thank you for your order! We have emailed your order confirmation. You can download the tracks below.
                </Typography>
                <Typography style={{ color: "black" }} variant="subtitle1">
                    Please read the <a target="_blank" rel="noopener noreferrer" href="https://dvtcbpcfza5s9.cloudfront.net/fliktrax_end_user_agreement.pdf"><i class="fas fa-file-pdf"></i> FlikTrax End User Agreement</a>  for this license. For further information please send an email to <a href="mailto:info@fliktrax.com">info@fliktrax.com</a>
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Track Title</TableCell>
                            <TableCell align="left">Artist</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.cart.currentOrder.orderTracks.map((data, index) => {
                            let dataAttrs = {
                                'data-source': data.preview_url,
                                'data-tracktitle': data.track_title,
                                'data-artist': data.artist_name,
                                'data-description': data.description,
                                'data-duration': data.duration,
                                'data-vocaltype': data.vocal_type,
                                'data-trackid': data.id,
                                'data-filecompressed': data.file_path_compressed,
                                'data-fileuncompressed': data.file_path_uncompressed
                            };
                            var md5 = require('md5');
                            let userEmail = state.user.user.attributes.email;
                            let encodedEmail = md5(userEmail);
                            let $licenseUrl = 'https://dvtcbpcfza5s9.cloudfront.net/license/' + encodedEmail + '_' + data.id + '.pdf';
                            
                            return (
                                <TableRow id={data.id}>
                                    <TableCell>{data.track_title}</TableCell>
                                    <TableCell style={{ cursor: "pointer" }} align="left">
                                        {data.artist_name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Download MP3" aria-label="artist" placement="center-top">
                                            <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => state.downloadFliktraxFile(dataAttrs['data-filecompressed'], true)}><DownloadIcon /></Link>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-top">
                                            <Link style={{ color: "#ca75ff" }} onClick={() => state.downloadFliktraxFile(dataAttrs['data-fileuncompressed'], true)}><DownloadIcon /></Link>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Download License" aria-label="artist" placement="center-bottom">
                                            <a rel="noopener noreferrer" target="_blank"  href={$licenseUrl} style={{ color: "#ff5c36"}}> <i style={{ fontSize: "1.5em", verticalAlign: "bottom" }} class="fas fa-file-pdf"></i></a>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <div style={{textAlign:"center", marginTop:50, fontFamily: "Gibson W01 Regular"}}>
                    Download alternate versions of your purchase on your <a style={{textDecoration:"underline"}} href="/orders">orders page</a>
                </div>
            </div>
        </FlikTraxLoader >
    );
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        api: state.api,
        user:state.user
    };
}

export default connect(mapStateToProps, { updateOrder, downloadFliktraxFile })(Confirmation)