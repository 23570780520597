import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FormErrors from '../../FormErrors';
import Validate from '../../utility/FormValidation';
//import CodeValid from '../../utility/FormCodeValidation';
import { Auth } from 'aws-amplify';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../FlikTraxStore/Actions/userActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';

// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// import CloseIcon from '@material-ui/icons/Close';


class RegisterForm extends Component {

    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            viewform: 'register',
            useremail: null,
            resendHidden: true
        }

        this.boxClass = ["d-none d-md-block masterBox"];

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.renderRegisterForm = this.renderRegisterForm.bind(this);
        this.resendCode = this.resendCode.bind(this);
    }

    state = {
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        confirmpassword: "",
        verificationcode: "",
        errors: {
            cognito: null,
            blankfield: false,
            passwordmatch: false
        }
    }

    redirectPage() {
        window.location.reload();
    }

    renderRegisterForm() {

        return (

            <div>
                <FormErrors formerrors={this.state.errors} />
                <form style={{backgroundColor:"#c4dbe3", padding:50}} className="form-signin" onSubmit={this.handleSubmit} >
                    <span id="reauth-email" className="reauth-email"></span>

                    <div className="input-group">
                        <input id="firstname"
                            placeholder="First Name"
                            style={{ width: "50%" }}
                            value={this.state.firstname}
                            type="text"
                            onChange={this.onInputChange}
                            className="form-control"
                            required />

                        <input id="lastname"
                            placeholder="Last Name"
                            style={{ width: "50%" }}
                            value={this.state.lastname}
                            type="text"
                            onChange={this.onInputChange}
                            className="form-control"
                            required />

                    </div>

                    <input type="email"
                        id="username"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.onInputChange}
                        required />

                    <input type="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onInputChange}
                        required />

                    <input
                        className="form-control"
                        type="password"
                        id="confirmpassword"
                        placeholder="Confirm Password"
                        value={this.state.confirmpassword}
                        onChange={this.onInputChange}
                    />

                    <input type="hidden"
                        id="email"
                        value={this.state.username}
                        onChange={this.onInputChange} />
                    <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">REGISTER</button>
                </form>
                <ul className="passwordhint">
                    <li>Password must be a minimum of 8 charactors.</li>
                    <li>Must contain at least 1 number[0-9] </li>
                </ul>
            </div>

        );
    }

    renderCodeVerifyForm() {
        return (
            <div style={{ border: "none" }} className="card card-container">
                <h2>Verify Registration</h2>
                <p>Please enter the verification code sent to {this.state.username}</p>

                <form className="form-signin" onSubmit={this.handleVerify} >
                    <span id="reauth-email" className="reauth-email"></span>

                    <input type="text"
                        id="verificationcode"
                        className="form-control"
                        placeholder="Verify Code"
                        onChange={this.onInputChange}
                        required />

                    <button className="btn btn-lg btn-primary btn-block btn-verify" type="submit">Verify Registration</button>
                    {!this.state.resendHidden && 
                        <button className="btn btn-lg btn-info btn-block btn-resend" type="button" onClick={() => this.resendCode()}>Resend Code</button>
                    }
                </form>
                <FormErrors formerrors={this.state.errors} />
            </div>
        );
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false,
                passwordmatch: false
            }
        });
    }

    handleVerify = async event => {
        event.preventDefault();
        console.log(this.state);
        this.clearErrorState();
        try {
            //return (console.log(this.state.verificationcode));
            const verifyResponse = await Auth.confirmSignUp(this.state.username,
                this.state.verificationcode,
                { forceAliasCreation: true });

            if (verifyResponse === "SUCCESS") {
                try { 
                    const user = await Auth.signIn(this.state.username, this.state.password);
                    this.props.updateAuthStatus(true);
                    this.props.setAuthUser(user);
                    localStorage.setItem('firstRun', "true");
                    setTimeout(() => {
                        this.redirectPage();
                    }, 1000);
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;

            this.setState({
                resendHidden: false}
            );

            this.setState({
                errors: {
                    ...this.state.errors,
                    cognito: err
                }
            })
        }
    }

    resendCode() {
        Auth.resendSignUp(this.state.username).then(() => {
            console.log('code resent successfully');
        }).catch(e => {
            console.log(e);
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        // const attributeList = [];
        // attributeList.push(new CognitoUserAttribute({
        //     Name: 'name',
        //     Value: this.state.name
        // }));

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
            return;
        }

        // AWS Cognito integration here
        const { firstname, lastname, username, password } = this.state;
        try {
            const signUpResponse = await Auth.signUp({
                username,
                password,
                attributes: {
                    'custom:firstname': firstname,
                    'custom:lastname': lastname,
                }
            });
            //console.log(signUpResponse);
            this.setState({
                viewform: 'verify',
                name: this.state.name,
                useremail: this.state.username
            });

        } catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;
            this.setState({
                viewform: 'register',
                errors: {
                    ...this.state.errors,
                    cognito: err
                }
            })
        }
    };

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });

        document.getElementById(event.target.id).classList.remove("is-danger");
    }

    render() {
        switch (this.state.viewform) {
            case 'verify':
                return this.renderCodeVerifyForm();
            default:
                return this.renderRegisterForm();
        }

    }

}

RegisterForm.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired, 
    closeSnackbar: PropTypes.func.isRequired, 
    removeSnackbar: PropTypes.func.isRequired 
}

const mapStateToProps = state => ({
    user: state.user,
    notifications: state.snackbar
})

export default connect(mapStateToProps,
    {
        updateAuthStatus,
        setAuthUser,
        updateAuthenticating,
        enqueueSnackbar, 
        closeSnackbar, 
        removeSnackbar 
    })(withRouter(RegisterForm));

