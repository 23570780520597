import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../FlikTraxStore/Actions/userActions';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Notifier from '../utility/Notifier';
//import { Auth } from 'aws-amplify';
import ProfileTabs from '../UserNav/ProfileTabs';
import ProfileTabsUser from '../UserNav/ProfileTabsUser';

class Navigation extends Component {

  redirectDash = (e) => {
    this.setState(this.state);
    window.location.replace("/dashboard");
  }

  handleCustomRedirect = (e) => {
    var linkData = e.currentTarget.dataset.link;
    console.log(linkData);
    window.location.replace(linkData);
  }


  render() {
    return (

      <Navbar id="masternav" bg="white" expand="lg" fixed="top" >
        <Navbar.Toggle aria-controls="nav" />
        <Navbar.Brand>
          <Link to="/">
            <img src="/images/ftlogo.jpg" alt="" />
          </Link>
        </Navbar.Brand>

        <Navbar.Collapse id="headlinks" className="ftheadlinks">
          <Nav className="ml-auto">
            <Nav.Link><Link to="/about" authProps={this.props} >ABOUT</Link></Nav.Link>
            <Nav.Link><Link to="" onClick={this.redirectDash.bind(this)}> SEARCH TRACKS</Link></Nav.Link>
              <NavDropdown title="CUSTOM SERVICES" >
              <NavDropdown.Item data-link="/custom" onClick={this.handleCustomRedirect}>ABOUT CUSTOM MUSIC</NavDropdown.Item>
              <NavDropdown.Item data-link="/hit-it-hard" onClick={this.handleCustomRedirect}>HIT IT HARD</NavDropdown.Item>
              <NavDropdown.Item data-link="/tvpros" onClick={this.handleCustomRedirect}>TV PROS</NavDropdown.Item>
              <NavDropdown.Item data-link="/voiceover" onClick={this.handleCustomRedirect}>VOICEOVER SERVICES</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link><Link to="/new-releases" authProps={this.props} >NEW RELEASES</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {!this.props.user.isAuthenticated && (
          <ProfileTabs />
        )}
        {this.props.user.isAuthenticated && (
          <ProfileTabsUser />
        )}
        <Notifier />
      </Navbar>

    )
  }
}

Navigation.propTypes = {
  updateAuthStatus: PropTypes.func.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  updateAuthenticating: PropTypes.func.isRequired
}


const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating
  })(withRouter(Navigation));



