import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';


import { switchPlayerMode } from '../../FlikTraxStore/Actions/userActions';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { removeFromPlaylist, deleteSavedPlaylist, updatePlaylistFromLocal, downloadPlaylist } from '../../FlikTraxStore/Actions/userActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import SearchBar from '../../Dashboard/Sections/SearchBar';
import AudioPlayer from '../../Dashboard/AudioPlayer/AudioPlayer';
//import FlikTraxBreadcrumbs from '../../Dashboard/Breadcrumbs/Breadcrumbs'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import './css/UserPlaylist.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteSpConfirm from './DeleteSpConfirm';
import DeletePlaylistIcon from '@material-ui/icons/DeleteSweep';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Tooltip from '@material-ui/core/Tooltip';

import SharePlaylist from './SharePlaylist';
import DownloadPlaylistIcon from '@material-ui/icons/CloudDownload';

class SavedPlaylists extends Component {

    constructor(props) {
        super(props);

        this.isAuthenticated = props.user.isAuthenticated;
        this.user = props.user.user;
        this.useremail = this.user.attributes.email;
        this.handleWaveformChange = this.handleWaveformChange.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.audioPlayer = React.createRef();

        this.state = {
            userPlaylist: {},
        };

        this.updateNowPlaying = this.updateNowPlaying.bind(this);
    }

    setOpen() {
        this.setState({ open: true })
    }

    handleDrawerOpen() {
        if (this.child) {
            this.child.handleDrawerOpen();
        }
    }

    async getUserPlaylist() {
        var axiosUrl = apiconfig.api.APIURL + 'userplaylist';

        await axios({
            baseURL: axiosUrl,
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'fetch',
                userid: this.user.username
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ userPlaylist: response.data })
            })
    }



    componentDidMount() {

        var ftap = document.getElementById("ftaudioplayer");
        if (this.props.user.audioPlayerMode) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }

        var bgElement = document.getElementById('masterdashboard');
        bgElement.classList.remove("curatedBg");
        bgElement.classList.add('defaultBg');

        setTimeout(() => {
            this.getUserPlaylist();
        }, 1000);

    }

    handleWaveformChange = name => event => {
        this.props.switchPlayerMode(event.target.checked);
        var ftap = document.getElementById("ftaudioplayer");
        if (event.target.checked) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }

        //this.getPlaylist();
    };

    deleteSavedPlaylist = (e) => {
        var playlistId = e.currentTarget.getAttribute('data-playlistid');
        var playlistTitle = e.currentTarget.getAttribute('data-playlisttitle');

        var notification = playlistTitle + ' deleted succesfully';

        this.props.deleteSavedPlaylist(playlistId);

        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });

        setTimeout(() => {
            window.location.reload();
        }, 2000);

    }

    downloadCompressedTrack = (e) => {
        console.log(e.currentTarget.dataset)
        this.setState(this.state);
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.filecompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }

    downloadUncompressedTrack = (e) => {
        this.setState(this.state);
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.fileuncompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }

    updateNowPlaying(event) {
        event.preventDefault();
        this.setState(this.state);
        var targetId = event.currentTarget.id;
        var nowPlaying = event.currentTarget.dataset;

        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } else {
            this.props.updateIsPlaying(true);
            this.props.updatePlayerPosition(0);
        }
    }

    removePlayer() {
        var ftap = document.getElementById("ftaudioplayer");
        ftap.className += " player-disabled";
    }

    getPdfFile(fileUrl, trackTitle) {
        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = trackTitle;
                    a.click();
                });
            });
    }

    // downloadPdfInfo = (e) => {
    //     this.setState(this.state);

    //     var slugify = require('slugify')
    //     var dataset = e.currentTarget.dataset;
    //     this.props.setActiveMixTrack(dataset.trackid);
    //     var dlfileName = slugify(dataset.tracktitle);
    //     this.props.getTrackInfoLink(dataset.trackid);
    //     var linkData = this.props.api.trackInfoLink;
    //     var fileUrl = linkData['pdfUrl'];
    //     if (fileUrl !== undefined) {
    //         var statusCode = parseInt(linkData['code']);
    //         switch (statusCode) {
    //             case 200:
    //                 this.getPdfFile(fileUrl, dlfileName);
    //                 break;
    //             default:
    //                 setTimeout(() => {
    //                     this.getPdfFile(fileUrl, dlfileName);
    //                 }, 3000);
    //         }
    //     }
    // }

    downloadPlaylist = (e) => {
        var dataset = e.currentTarget.dataset;
        var playlistid = dataset.playlistid;
        //console.log(playlistid);
        this.props.downloadPlaylist(playlistid);
        var notification = "Download complilation in progress!\r\n\r\nCheck your email " + this.useremail + " for download links associated with this playlist.";
        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                preventDuplicate: true,
                autoHideDuration: 5000
            },
        });
    }



    render() {
        var playlistData = this.state.userPlaylist;
        var plEmbeddedData = Object.values(playlistData);

        var truncate = require('truncate');

        return (
            <Paper>
                <div>

                    <section id="masterdashboard">
                        <div className="container">
                            <Container>
                                <div className="content">
                                    <header>
                                        <Typography component="h2">
                                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                                <h2>SAVED PLAYLISTS</h2>
                                            </Box>
                                        </Typography>
                                    </header>

                                    <div id="mastercontainer" >
                                        <Grid container style={{ marginTop: 25 }} spacing={3}>
                                            <Grid item xs={12} style={{ minHeight: 900, backgroundColor: "white" }}>
                                                <Grid className="fttoolbar" item xs={12}>
                                                    <div className="float-left" style={{ padding: 15 }}>
                                                        {/* <FlikTraxBreadcrumbs /> */}
                                                    </div>
                                                    <FormControlLabel className="float-right"
                                                        control={
                                                            <Switch className="audioswitch"
                                                                checked={this.props.user.audioPlayerMode}
                                                                onChange={this.handleWaveformChange('playermode')}
                                                                value="playermode"
                                                                color="secondary"
                                                                inputProps={{ 'aria-label': 'Player Mode' }}
                                                            />
                                                        }
                                                        label="Auto-Hide Player"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {/* <FlikTraxBreadcrumbs /> */}
                                                    <Typography component="h3" style={{ marginTop: 50, marginBottom: 50, textAlign: "left" }}>
                                                        <Badge badgeContent={this.props.user.savedPlaylistCount} color="primary">
                                                            <div >
                                                                <Badge color="primary" badgeContent={this.props.user.savedPlaylistCount} >
                                                                    <Typography style={{ color: "black", fontFamily: "Gibson W01 SemiBold" }} ><h2 style={{ color: "black", textDecoration: "underline" }}>Saved Playlists</h2></Typography>
                                                                </Badge>

                                                            </div>
                                                        </Badge>

                                                        {/* {(playlistData && plEmbeddedData.length - 1 > 0) &&
                                                            <div className="text-nowrap text-right">
                                                                <div className="btn-group" role="group" aria-label="Actions" style={{ marginTop: '-45px' }}>
                                                                    <DeleteSpConfirm deletePlaylist={this.props.clearSavedPlaylists} enqueueMessage={this.props.enqueueSnackbar} />
                                                                </div>
                                                            </div>
                                                        } */}

                                                    </Typography>
                                                </Grid>



                                                <FlikTraxLoader active={this.props.api.isLoading}>
                                                    {(playlistData && plEmbeddedData.length - 1 === 0) &&

                                                        <SnackbarContent className="mx-auto" style={{ marginTop: 75, width: '75%' }} message="Your have no saved playlists." />

                                                    }

                                                    {
                                                        playlistData && plEmbeddedData.map((data, index) => {

                                                            let plDataAttrs = {
                                                                'data-playlistid': data.id,
                                                                'data-playlisttitle': data.title
                                                            };

                                                            if (data.id !== undefined) {
                                                                var spPlaylist = data.playlist
                                                                return (
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            style={{ backgroundColor: "#0e6bae", fontWeight: "bold" }}
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >

                                                                            <Typography style={{ color: "white", fontWeight: "bold" }} fontFamily="Gibson W01 SemiBold">{data.title}</Typography>
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container>
                                                                                <Grid item xs={12}>
                                                                                    <div style={{ textAlign: "right" }}>
                                                                                        
                                                                                        <Tooltip title={'Download Playlist'} placement="bottom">
                                                                                            <Button color="primary" onClick={this.downloadPlaylist} style={{ cursor: 'pointer' }} {...plDataAttrs}>
                                                                                                <DownloadPlaylistIcon  />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title={'Share Playlist'} placement="bottom">
                                                                                            <Button color="primary" onClick={this.removePlayer} style={{ cursor: 'pointer' }} {...plDataAttrs}>
                                                                                                <SharePlaylist enquequeMessage={this.props.enqueueSnackbar} playlistId={data.id} playlistTitle={data.title} />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title={'Delete Playlist'} placement="bottom">
                                                                                            <Button color="primary" style={{ cursor: 'pointer' }} {...plDataAttrs} onClick={this.deleteSavedPlaylist} >
                                                                                                <DeletePlaylistIcon />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    {spPlaylist && spPlaylist.map((spdata, spindex) => {
                                                                                        //console.log(spdata);
                                                                                        let dataAttrs = {
                                                                                            'data-playlistid': spdata.id,
                                                                                            'data-playlisttitle': spdata.title,
                                                                                            'data-source': spdata.preview_url,
                                                                                            'data-tracktitle': spdata.track_title,
                                                                                            'data-artist': spdata.artist_name,
                                                                                            'data-description': spdata.description,
                                                                                            'data-duration': spdata.duration,
                                                                                            'data-vocaltype': spdata.vocal_type,
                                                                                            'data-trackid': spdata.id,
                                                                                            'data-filecompressed': data.file_path_compressed,
                                                                                            'data-fileuncompressed': data.file_path_uncompressed
                                                                                        };
                                                                                        //console.log(dataAttrs);
                                                                                        return (
                                                                                            <div>
                                                                                                <div className="row ftTable">
                                                                                                    <div className="col-sm-1 text-left">
                                                                                                        <span>
                                                                                                            <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className="col-8 text-left categorytitle">

                                                                                                        <span className="trackTitle">
                                                                                                            {spdata.track_title}
                                                                                                        </span>
                                                                                                        <span style={{ marginLeft: 10 }} className="artistNameSpan">
                                                                                                            {spdata.duration}
                                                                                                        </span>

                                                                                                        <span className="artistNameSpan"> {spdata.artist_name}</span>
                                                                                                        <br />
                                                                                                        <span className="trackDescript">{truncate(spdata.description, 200)}</span>
                                                                                                    </div>
                                                                                                    <div id="trackactionblock" className="col-sm-3">

                                                                                                        {/* <SpeedDial dataAttributes={dataAttrs} className="align-middle trackActions" downloadPdfInfo={this.downloadPdfInfo} addToPlaylist={this.addToPlaylist} downloadCompressedTrack={this.downloadCompressedTrack} downloadUncompressedTrack={this.downloadUncompressedTrack} /> */}

                                                                                                    </div>


                                                                                                </div>
                                                                                                <div style={{ width: "100%" }}>
                                                                                                    <Divider />
                                                                                                </div>

                                                                                            </div>


                                                                                        )

                                                                                    })
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>


                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </FlikTraxLoader>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </div>
                            </Container>
                        </div>
                    </section>

                    <AudioPlayer ref={this.audioPlayer} />
                </div>
            </Paper>

        )
    }
}

SavedPlaylists.propTypes = {
    switchPlayerMode: PropTypes.func.isRequired,
    updateNowPlaying: PropTypes.func.isRequired,
    updateIsPlaying: PropTypes.func.isRequired,
    updatePlayerPosition: PropTypes.func.isRequired,
    removeFromPlaylist: PropTypes.func.isRequired,
    updatePlaylistFromLocal: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    deleteSavedPlaylist: PropTypes.func.isRequired,
    downloadPlaylist: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
    audioplayer: state.audioplayer,
    notifications: state.snackbar
})

export default connect(mapStateToProps,
    {
        switchPlayerMode,
        updateNowPlaying,
        updateIsPlaying,
        updatePlayerPosition,
        removeFromPlaylist,
        updatePlaylistFromLocal,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar,
        deleteSavedPlaylist,
        downloadPlaylist

    })(withRouter(SavedPlaylists));