import { ADD_TO_SHOPPING_CART, 
         REMOVE_FROM_SHOPPING_CART, 
         LOAD_SHOPPING_CART, 
         FORCE_DUMMY_STATE,
         LOAD_LICENSES, 
         UPDATE_ORDER,
         CLEAR_SHOPPING_CART
         } from '../Actions/types';

const initialState = {
    initialDt: new Date(),
    userId: null,
    cartContents: [],
    cartState: false,
    dummystate: false,
    licenses:{},
    currentOrder:{
        orderTracks:[],
        orderTrackIds:[],
        clientNoonce: null,
        saleToken: null,
        currentStep: 2,
        selectedLicense: null,
        itemPrice:null,
        total:null,
        transaction:{}
    }
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FORCE_DUMMY_STATE:
            return {
                ...state,
                dummystate: action.payload
            }

        case LOAD_SHOPPING_CART:
            return {
                ...state,
                cartContents: action.payload
            }

        case LOAD_LICENSES:    
            return {
                ...state,
                licenses: action.payload
            }

        case UPDATE_ORDER:
            return {
                ...state,
                currentOrder: action.payload,
            }    

        case ADD_TO_SHOPPING_CART:
            // console.log(state.cartContents);
            var newCart = [...state.cartContents, action.payload];
            return {
                ...state,
                cartContents: newCart
            }

        case CLEAR_SHOPPING_CART:
                return {
                    ...state,
                    cartContents: []
                }    

        case REMOVE_FROM_SHOPPING_CART:
            var currentSc = state.cartContents;
            for (var i = 0; i < currentSc.length; i++) {
                if (currentSc[i].id === action.payload) {
                    currentSc.splice(i, 1);
                }
            }
            return {
                ...state,
                cartContents: currentSc
            }

        default:
            return state;

    }
}
