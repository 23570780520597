import { UPDATE_NOW_PLAYING, UPDATE_IS_PLAYING, UPDATE_POSITION, UPDATE_PLAYER_STATUS } from './types';

export const updateNowPlaying = (playing) => dispatch => {
    dispatch({
        type: UPDATE_NOW_PLAYING,
        payload: playing
    })
}

export const setPlayerStatus = (status) => dispatch => {
    dispatch({
        type: UPDATE_PLAYER_STATUS,
        payload: status
    })    
}

export const updateIsPlaying = (status) => dispatch => {
    dispatch({
        type: UPDATE_IS_PLAYING,
        payload: status
    })
}

export const updatePlayerPosition = (pos) => dispatch => {
    dispatch({
        type: UPDATE_POSITION,
        payload: pos
    })
}