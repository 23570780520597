import React, { Component } from 'react';
import './css/Banner.css';
import './css/Search.css';
import './css/Custom.css';
import Navigation from '../Header/Navigation';
//import { UserNav } from '../UserNav/UserNav';
import  Banner  from './Sections/Banner';
import SearchMusic from './Sections/SearchMusic';
import { CustomMusic } from './Sections/CustomMusic';
import { Footer } from '../Footer/Footer';

class Home extends Component {

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          {/* {!this.isAuthenticated && (
           <UserNav authProps={this.props} />
          )} */}
          <Banner authProps={this.props}  />
          <SearchMusic/>
          <CustomMusic/>
        </div>
          <Footer />
        </div>
    )
  }
}

export default Home
