import React, { Component } from 'react'
import '../css/WeRealize.css';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class WeRealize extends Component {

    render() {

        return (
            <section id="werealize">
                <div className="content">
                    <header>

                        <Typography component="h2">
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <h2>WE REALIZE THAT MUSIC</h2>
                            </Box>
                        </Typography>
                        <Typography component="h4">
                            <Box textAlign="center" fontWeight={400} fontFamily="Gibson W01 Regular">
                                IS A PIECE OF A MUCH LARGER PICTURE
                            </Box>
                        </Typography>


                        <Box className="personal" style={{  backgroundColor: "rgba(255, 255, 255, 0.25)" }} textAlign="left" fontWeight={300} fontFamily="Gibson W01 Regular" >
                            FlikTrax.com is a music library that's main objective is to help you find the perfect musical accompaniment for your media quickly. Our exact search, filters, curated playlists and personal search assistant were designed to streamline your music selection process.
                        </Box>


                    </header>
                </div>
                <a href="#pricing" className="goto-next scrolly">Next</a>
            </section>

        )
    }
}

export default WeRealize