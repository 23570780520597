import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';

import { switchPlayerMode } from '../../FlikTraxStore/Actions/userActions';
//import { getTrackInfoLink } from '../../FlikTraxStore/Actions/apiActions';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { removeFromPlaylist, saveDefaultPlaylist, updatePlaylistFromLocal, loadLocalPlaylist } from '../../FlikTraxStore/Actions/userActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import SearchBar from '../../Dashboard/Sections/SearchBar';
import AudioPlayer from '../../Dashboard/AudioPlayer/AudioPlayer';
//import FlikTraxBreadcrumbs from '../../Dashboard/Breadcrumbs/Breadcrumbs'

import './css/UserPlaylist.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';

class UserPlaylist extends Component {

    constructor(props) {
        super(props);

        this.isAuthenticated = props.user.isAuthenticated;
        this.user = props.user.user;
        this.handleWaveformChange = this.handleWaveformChange.bind(this);
        this.audioPlayer = React.createRef();

        this.state = {
            userPlaylist: {},
        };

        this.updateNowPlaying = this.updateNowPlaying.bind(this);
    }

    setOpen() {
        this.setState({ open: true })
    }

    async getUserSharedPlaylist(playlistId) {
        var axiosUrl = apiconfig.api.APIURL + 'userplaylist';

        await axios({
            baseURL: axiosUrl,
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: 'getshared',
                playlistid: playlistId
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({ userPlaylist: response.data })
            })
    }



    componentDidMount() {

        var ftap = document.getElementById("ftaudioplayer");
        if (this.props.user.audioPlayerMode) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }

        var bgElement = document.getElementById('masterdashboard');
        bgElement.classList.remove("curatedBg");
        bgElement.classList.add('defaultBg');

        var queryString = require('query-string');
        var playlistId = Object.values(queryString.parse(window.location.href))[0];
        this.getUserSharedPlaylist(playlistId);

    }


    handleWaveformChange = name => event => {
        this.props.switchPlayerMode(event.target.checked);
        var ftap = document.getElementById("ftaudioplayer");
        if (event.target.checked) {
            ftap.className += " player-disabled";
        } else {
            ftap.classList.remove("player-disabled");
        }
    };


    updateNowPlaying(event) {
        event.preventDefault();
        this.setState(this.state);
        var targetId = event.currentTarget.id;
        var nowPlaying = event.currentTarget.dataset;

        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } else {
            this.props.updateIsPlaying(true);
            this.props.updatePlayerPosition(0);
        }
    }

    render() {
        var playlistData = this.state.userPlaylist;
        var uPlaylist = playlistData.playlist; 
        //var plEmbeddedData = playlistData._embedded;
        var truncate = require('truncate');

        return (
            <div>
                <section id="masterdashboard">
                    <div className="container">

                        <div className="content">
                            <header>
                                <Typography style={{marginTop:75}} component="h2">
                                    <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                        <h2>{playlistData.title}</h2>
                                    </Box>
                                </Typography>
                            </header>

                            <div id="mastercontainer" >
                                <Container>

                                    <Grid container style={{ marginTop: 25 }} spacing={3}>
                                        {/* <Grid className="searchbar" item xs={12}>
                                            <SearchBar rdxProps={this.props} />
                                        </Grid> */}


                                        <Grid className="fttoolbar" item xs={12}>
                                            <div className="float-left" style={{ padding: 15 }}>
                                                {/* <FlikTraxBreadcrumbs /> */}
                                            </div>
                                            <FormControlLabel className="float-right"
                                                control={
                                                    <Switch className="audioswitch"
                                                        checked={this.props.user.audioPlayerMode}
                                                        onChange={this.handleWaveformChange('playermode')}
                                                        value="playermode"
                                                        color="secondary"
                                                        inputProps={{ 'aria-label': 'Player Mode' }}
                                                    />
                                                }
                                                label="Auto-Hide Player"
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ minHeight: 900, backgroundColor: "white" }}>

                                            <div className={'panel-body'} ref="MasterContainer">
                                                <h2>{playlistData.title} <Chip style={{ marginLeft: 10 }} color="primary" label={uPlaylist && uPlaylist.length} /></h2>
                                            </div>
                                            <FlikTraxLoader active={this.props.api.isLoading}>

                                                {uPlaylist && uPlaylist.map((data, index) => {
                                                    let dataAttrs = {
                                                        'data-source': data.preview_url,
                                                        'data-tracktitle': data.track_title,
                                                        'data-artist': data.artist_name,
                                                        'data-description': data.description,
                                                        'data-duration': data.duration,
                                                        'data-vocaltype': data.vocal_type,
                                                        'data-trackid': data.id,
                                                        //'data-file': data.id
                                                    };

                                                    var trackBlock = 'trackBlock-' + data.id;

                                                    return (
                                                        <div id={trackBlock} className="row ftTable">
                                                            <div className="col-sm-1 text-left">
                                                                <span>
                                                                    <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                                                                </span>
                                                            </div>
                                                            <div className="col-8 text-left categorytitle">

                                                                <span className="trackTitle">{data.track_title}</span>  <span className="trackIdTitle"></span>
                                                                <span className="artistNameSpan">  {data.artist_name}</span>
                                                                <br />
                                                                <span className="trackDescript">{truncate(data.description, 200)}</span>

                                                            </div>
                                                            <div id="trackactionblock" style={{ marginBottom: 10 }} className="col-sm-3">

                                                            </div>
                                                            <div style={{ width: "100%" }}>
                                                                <Divider />
                                                            </div>
                                                        </div>
                                                    )

                                                })}
                                            </FlikTraxLoader>
                                        </Grid>


                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>

                <AudioPlayer ref={this.audioPlayer} />
            </div>

        )
    }
}

UserPlaylist.propTypes = {
    switchPlayerMode: PropTypes.func.isRequired,
    updateNowPlaying: PropTypes.func.isRequired,
    updateIsPlaying: PropTypes.func.isRequired,
    updatePlayerPosition: PropTypes.func.isRequired,
    removeFromPlaylist: PropTypes.func.isRequired,
    updatePlaylistFromLocal: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    //addToShoppingCart: PropTypes.func.isRequired,
    loadLocalPlaylist: PropTypes.func.isRequired,
    //clearDefaultPlaylist: PropTypes.func.isRequired,
    saveDefaultPlaylist: PropTypes.func.isRequired,
    //getTrackInfoLink: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
    audioplayer: state.audioplayer,
    notifications: state.snackbar
})

export default connect(mapStateToProps,
    {
        switchPlayerMode,
        updateNowPlaying,
        updateIsPlaying,
        updatePlayerPosition,
        removeFromPlaylist,
        updatePlaylistFromLocal,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar,
        //addToShoppingCart,
        loadLocalPlaylist,
        //clearDefaultPlaylist,
        saveDefaultPlaylist,
        //getTrackInfoLink

    })(UserPlaylist);