import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import CouponDialog from './components/Home/CouponDialog';
import UserHome from './components/UserHome/UserHome';
import About from './components/About/About';
import CustomMusic from './components/CustomMusic/CustomMusic';
import TVPros from './components/TVPros/TVPros';
import Dashboard from './components/Dashboard/Dashboard';
import NewReleases from './components/Dashboard/NewReleases/NewReleases';
import HitItHard from './components/HitItHard/HitItHard';
import MyPlaylist from './components/Playlist/MyPlaylist';
import SavedPlaylists from './components/Playlist/SavedPlaylists';
import UserSharedPlaylist from './components/Playlist/UserSharedPlaylist';
import Cart from './components/Cart/Cart';
import Orders from './components/Orders/Orders';
import VoiceOver from './components/VoiceOver/VoiceOver';
//import OrderConfirmation from './components/Cart/OrderConfirmation';

import { Auth } from 'aws-amplify';
import { Provider } from 'react-redux';
import FlikTraxStore from './components/FlikTraxStore/FlikTraxStore';
import { updateAuthStatus, setAuthUser, setAuthGroups, updateAuthenticating, loadLocalPlaylist, loadSavedPlaylistCount } from './components/FlikTraxStore/Actions/userActions'
import { loadShoppingCart, mergeRemoteCart } from './components/FlikTraxStore/Actions/cartActions';
import { applyCoupon } from './components/FlikTraxStore/Actions/ordersActions';
import { fetchUserOrders } from './components/FlikTraxStore/Actions/ordersActions'
import { SnackbarProvider } from 'notistack';


class App extends Component {

  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    userfilters: {},
    hasAffiliate:false
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setUser = user => {
    this.setState({ user: user })
  }

  async componentDidMount() {

    var qparsed = new URLSearchParams(window.location.search);
    var hasAffiliate = qparsed.has('affilliatecc');
    if (hasAffiliate) {
      this.setState({ hasAffiliate: true })
      let affiliate = qparsed.get('affilliatecc');
      var ccapplied = localStorage.getItem('couponapplied');
      if (!ccapplied) {
        FlikTraxStore.dispatch(applyCoupon(affiliate));
      }
    }

    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      FlikTraxStore.dispatch(updateAuthStatus(true));
      const user = await Auth.currentAuthenticatedUser();
      var userGroups = [];
      var cognitoGroups = user.signInUserSession.idToken.payload['cognito:groups'];
      if (cognitoGroups !== undefined) {
        userGroups = cognitoGroups;
      }
      this.setUser(user);
      FlikTraxStore.dispatch(setAuthUser(user));
      FlikTraxStore.dispatch(setAuthGroups(userGroups));
      FlikTraxStore.dispatch(loadLocalPlaylist());
      FlikTraxStore.dispatch(loadSavedPlaylistCount());
      FlikTraxStore.dispatch(fetchUserOrders());
    } catch (error) {
    }
    this.setState({ isAuthenticating: false });
    FlikTraxStore.dispatch(updateAuthenticating(false));
    this.mergeShoppingCart();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  mergeShoppingCart() {
    switch (FlikTraxStore.getState().user.isAuthenticated) {
      case true:
        FlikTraxStore.dispatch(mergeRemoteCart);
        break;

      default:
        var currentCart = localStorage.getItem('cartItems');
        if (currentCart) {
          var cartObj = JSON.parse(currentCart);
          if (cartObj.length > 0) {
            FlikTraxStore.dispatch(loadShoppingCart(cartObj));
          }
        }
    }
  }

  render() {

    const authprops = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    }

    return (
      !this.state.isAuthenticating &&
      <Provider store={FlikTraxStore}>
        {this.state.hasAffiliate &&
        <CouponDialog dialogopen='true' />
        }
        <SnackbarProvider maxSnack={10}>
          <BrowserRouter>
            <div className="App">
              {!authprops.isAuthenticated &&
                <Route exact path="/" render={(props) => <Home />} />
              }
              {authprops.isAuthenticated && authprops.user &&
                <Route exact path="/" render={(props) => <UserHome />} />
              }
              <Route path="/about" render={(props) => <About />} />
              <Route path="/custom" render={(props) => <CustomMusic />} />
              <Route path="/tvpros" render={(props) => <TVPros />} />
              <Route path="/hit-it-hard" render={(props) => <HitItHard />} />
              <Route path="/voiceover" render={(props) => <VoiceOver />} />
              <Route path="/dashboard" render={(props) => <Dashboard />} />
              <Route path="/new-releases" render={(props) => <NewReleases />} />
              <Route path="/my-playlist" render={(props) => <MyPlaylist />} />
              <Route path="/cart" render={(props) => <Cart />} />
              <Route path="/orders" render={(props) => <Orders />} />
              <Route path="/saved-playlists" render={(props) => <SavedPlaylists />} />
              <Route path="/shared-playlist" render={(props) => <UserSharedPlaylist />} />
              {/* <Route path="/order-confirm" render={(props) => <OrderConfirmation />} /> */}
            </div>
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    );
  }
}

export default App;