import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';

export class WhatsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newReleases: {},
        };
    }


    getNewReleases() {

        var axiosUrl = apiconfig.api.APIURL + 'newreleases';

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ newReleases: response.data })
            })
    }


    componentWillMount() {
        this.getNewReleases();
    }


    render() {
        var newReleasesData = this.state.newReleases;
        var nrEmbeddedData = newReleasesData._embedded;
        var nrIndex = 0;
        return (
            <section id="whatsnew">
                <div className="content">
                    <header>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{ textTransform: "uppercase", fontWeight: "900", fontFamily: "Gibson W01 Regular" }} component="div" noWrap>
                                    <h2>What's New</h2>
                                </Typography>
                            </Grid>
                        </Grid>
                    </header>

                    <Grid container style={{ marginTop: 25 }} spacing={5}>
                        <Grid item sm={6}>
                            <Typography style={{ fontFamily: "Gibson W01 Regular", fontSize: "1.85em", textTransform: "uppercase", fontWeight: "900", marginBottom: "2.0em" }} >
                                FlikTrax News
                            </Typography>
                            <Typography p={35} style={{ fontFamily: "Gibson W01 Regular", fontSize: "1.0em", fontWeight: "900", textAlign: "left" }} >
                                Welcome to the new FlikTrax.com. We've redesigned FlikTrax.com to make it faster and easier to locate the perfect music for your media.
                            </Typography>
                            <Typography p={35} style={{ fontFamily: "Gibson W01 Regular", fontSize: "1.0em", fontWeight: "900", textAlign: "left", marginTop:50 }} >
                                For Immediate Release! Janson and Fliktrax Partner on New Music Resource <span  style={{display:'inline-block', textAlign:'right'}}>(<a rel="noopener noreferrer" target="_blank" style={{color:'white', textDecoration:'underline'}} href="https://dvtcbpcfza5s9.cloudfront.net/Janson_Fliktrax_Press_Release_1_15_20-1.pdf">view full press release</a>)</span> 
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography style={{ fontFamily: "Gibson W01 Regular", fontSize: "1.85em", textTransform: "uppercase", fontWeight: "900", marginBottom: "2.0em" }} >
                                New Releases
                            </Typography>
                            <div style={{ width: 300 }} id="carouselExampleControls" className="carousel slide mx-auto" data-ride="carousel">
                                <div className="carousel-inner">

                                    {nrEmbeddedData && nrEmbeddedData.newreleases.map((data, index) => {
                                        nrIndex++;
                                        var active = nrIndex === 1 ? 'carousel-item active' : 'carousel-item';
                                        //console.log(data)
                                        return (

                                            <div className={active}>
                                                <img className="d-block w-100" src={data.image} alt="First slide" />
                                            </div>

                                        );
                                    })}


                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>

                            <Grid  container style={{ marginTop: 25 }} spacing={5}>
                                <Grid item className="mx-auto" sm={6}>
                                    <Link to="/new-releases" className="wnButtons btn-block">GO TO NEW RELEASES</Link>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <div className="row actions center-block">

                        <Grid container style={{ marginTop: 100 }} spacing={5}>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/dashboard" className="wnButtons btn-block">SEARCH TRACKS</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/custom" className="wnButtons btn-block" >CUSTOM MUSIC</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                        </Grid>


                        <Grid container style={{ marginTop: 100 }} spacing={5}>
                            <Grid item sm={12}>
                                <a href="#custom" className="goto-next scrolly">Next</a>
                            </Grid>
                        </Grid>


                    </div>

                </div>


            </section>
        )
    }
}

export default WhatsNew
