import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class SharePlaylist extends Component {


    constructor(props) {
        super(props);
        this.user = props.user;

        if (this.props.user.user) {
            this.userAttributes = this.props.user.user.attributes;
            this.userFirstName = this.userAttributes['custom:firstname'];
            this.userLastName = this.userAttributes['custom:lastname'];
            this.userEmail = this.userAttributes.email;
            this.userName = this.userFirstName + ' ' + this.userLastName;
        }

        this.handleChange = this.onInputChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    state = {
        viewform: 'request',
        open: false,
        firstname: this.userFirstName,
        lastname: this.userLastName,
        username: this.userName,
        fromname: this.userName,
        fromemail: this.userEmail,
        toemail: "",
        comment: ""
    }

    componentDidMount() {
        this.setState({
            firstname: this.userFirstName,
            lastname: this.userLastName,
            fromemail: this.userEmail,
            username: this.userName,
        });
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };



    onInputChange = event => {
        console.log(this.state)
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleClose() {
        this.setState({
            open: false
        });
    }

    displayWarning(type) {
        var message = '';
        var displaytype = 'error';
        switch (type) {
            case 'email':
                message = "Error: To Email is Required!"
                document.getElementById("toemail").focus();
                break;

            case 'comment':
                displaytype = "warning";
                message = "Please leave a short message to your friend or colleague."
                document.getElementById("comment").focus();
                break;

            default:
        }

        this.props.enquequeMessage({
            message: message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: displaytype,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 2000
            },
        });
    }

    handleSubmit() {

        var toEmail = this.state.toemail;

        if (toEmail.trim() === '') {
            this.displayWarning('email');
            return;
        }

        var toComment = this.state.comment;
        if (toComment.trim() === '') {
            this.displayWarning('comment');
            return;
        }

        var axiosUrl = apiconfig.api.APIURL + 'services';

        axios({
            baseURL: axiosUrl,
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                service: "ses-template-service",
                playlistid: this.props.playlistId,
                toemail: this.state.toemail,
                fromname: this.state.username,
                message: this.state.comment,
                template: 'production'
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                this.setState({ userPlaylist: response.data })
            });


        this.props.enquequeMessage({
            message: "Playlist Shared Successfully",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 3000
            },
        });
        this.setState({
            open: false
        });
    }

    renderShareForm() {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    <ShareIcon />
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="share=playlist"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title">
                        <h3>
                            <ShareIcon /> Share Playlist
                            <Tooltip className="float-right" title="Canel/Close" aria-label="Log In" placement="top">
                                <Button onClick={this.handleClose} size="small">
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                        </h3>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "black", fontFamily: "Gibson W01 Regular", fontSize: "1.0em" }}>
                            Share playlist <span style={{ fontFamily: "Gibson W01 SemiBold" }}>{this.props.playlistTitle}</span> with a friend or collegue.
                        </DialogContentText>
                        <form className="form-signin" onSubmit={this.handleSubmit} >
                            <TextField
                                style={{ marginBottom: 30 }}
                                className="form-control"
                                label="Your Name"
                                autoFocus
                                margin="dense"
                                id="username"
                                value={this.state.username}
                                placeholder="Your Name"
                                type="text"
                                onChange={this.onInputChange}
                                fullWidth
                                disabled
                            />
                            <TextField

                                style={{ marginBottom: 30 }}
                                className="form-control"
                                label="Your Email Address"
                                margin="dense"
                                id="fromemail"
                                value={this.state.fromemail}
                                placeholder="Your Email Address"
                                type="text"
                                onChange={this.onInputChange}
                                fullWidth
                                disabled
                            />
                            <TextField
                                style={{ marginBottom: 30 }}
                                className="form-control"
                                label="To  Email Address"
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="toemail"
                                type="email"
                                onChange={this.onInputChange}
                                fullWidth
                            />

                            <textarea style={{ marginTop: 20 }} id="comment" onChange={this.onInputChange} className="form-control" placeholder="Comment" rows="6">
                            </textarea>
                        </form>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSubmit} color="primary">
                            Share
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                    </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    render() {
        switch (this.state.viewform) {
            case 'success':
                return this.renderSuccessForm();
            default:
                return this.renderShareForm();
        }
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, {})(withRouter(SharePlaylist));


