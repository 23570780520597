import { FETCH_API_DATA,
         FETCH_MINI_NEWEST, 
         SWITCH_MASTER_MODE, 
         SWITCH_API_PAGE, 
         SWITCH_API_URL, 
         UPDATE_TRACK_PAGE_TITLE,
         AXIOS_IS_LOADING,
         IS_PLAYLIST_PAGE,
         SET_CURRENT_SEARCH,
         SET_CURRENT_SEARCH_TERM
    } from '../Actions/types';

const initialState = {
    apiData: {},
    embeddedData: [],
    miniNewestData: [],
    apiUrl: 'newest?page=1',
    masterMode: 'tracks',
    apiPage: 1,
    trackPageTitle: '',
    audioPlayerMode: false,
    isLoading: false,
    isPlaylist:false,
    currentSearch: null,
    currentSearchTerm: null,
}

export default function(state = initialState, action){
    switch(action.type){

        case SET_CURRENT_SEARCH:
            return {
                ...state,
                currentSearch: action.payload,
            }

        case SET_CURRENT_SEARCH_TERM:
            return {
                ...state,
                currentSearchTerm: action.payload,
            }      

        case FETCH_API_DATA:
            return {
                ...state,
                apiData: action.payload ,
                embeddedData: Object.values(action.payload._embedded) ,
            }

        case FETCH_MINI_NEWEST:
                return {
                    ...state,
                    //apiData: action.payload,
                    miniNewestData: Object.values(action.payload._embedded.newest),
                }            

        case SWITCH_MASTER_MODE:
            return {
                ...state,
                masterMode: action.payload
            }

        case SWITCH_API_URL:
                
                var isPlaylistPage = (action.payload.indexOf("playlist") === 0) ? true : false;
                return {
                    ...state,
                    apiUrl: action.payload,
                    isPlaylist: isPlaylistPage
                }            

        case SWITCH_API_PAGE:
            //
            return {
                ...state,
                apiPage: action.payload 
            }
                
        case UPDATE_TRACK_PAGE_TITLE:
            return {
                ...state,
                trackPageTitle: action.payload 
            }
            
        case AXIOS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            } 
            
        case IS_PLAYLIST_PAGE:
            return {
                ...state,
                isPlaylist: action.payload
            }     

        default:
            return state;
    }
}


