import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxLoader from '../Loader/Loading';
import { BrowserRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PlaylistAddIcon from '@material-ui/icons/QueueMusic';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class Mixes extends Component {

    constructor(props) {
        super(props);

        //console.log(props)

        this.baseProps = props.baseProps;
        //console.log(props.parentstate);

        this.isDirectAccess = this.baseProps.user.userGroups.indexOf("direct-access") > -1;
        this.updateNowPlaying = this.updateNowPlaying.bind(this);

        this.state = {
            trackId: props.trackid,
            mixData: {},
            isLoading: false,
            isPlaying: false,
            currentTrack: null,
            mixStates: []
        }

        this.getMixData = this.getMixData.bind(this);

    }

    addToPlaylist = (e) => {
        e.preventDefault();
        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');
        var notification = trackTitle + ' added to your playlist';
        this.baseProps.addToPlaylist(trackId);
        this.baseProps.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.baseProps.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }

    addToShoppingCart = (e) => {

        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

        var notification = trackTitle + ' added to your shopping cart';

        this.baseProps.addToShoppingCart(trackId);

        this.baseProps.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.baseProps.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }


    getMixData() {

        var axiosUrl = apiconfig.api.APIURL + 'mixes?track_id=' + this.state.trackId;
        var thisState = this;
        thisState.setState({ isLoading: true });
        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })
            .then(function (response) {
                thisState.setState({ mixData: response.data })
                thisState.setState({ isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentWillMount() {
        this.getMixData();
    }

    updateNowPlaying(event) {
        event.preventDefault();
        //this.setState(this.state);
        var targetId = event.currentTarget.id;
        var nowPlaying = event.currentTarget.dataset;
        this.props.updateNowPlaying(nowPlaying);

        if (this.props.audioplayer.currentTrackId === targetId) {
            this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
            this.props.updatePlayerPosition(this.props.audioplayer.position);
        } else {
            this.props.updateIsPlaying(true);
            this.props.updatePlayerPosition(0);
        }
    }

    downloadCompressedTrack = (e) => {
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.filecompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }

    downloadUncompressedTrack = (e) => {
        var path = require('path');
        var dataset = e.currentTarget.dataset;
        var fileUrl = 'https://ftmedia.s3.amazonaws.com/' + dataset.fileuncompressed;
        var filename = path.basename(fileUrl);

        fetch(fileUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }

    render() {

        var embedData = this.state.mixData._embedded;

        return (
            <div style={{ backgroundColor: 'rgba(31,137,177,0.08)', padding: 25 }}>
                <FlikTraxLoader active={this.state.isLoading}>
                    {embedData && embedData.mixes.map((data, index) => {

                        let dataAttrs = {
                            'data-source': data.preview_url,
                            'data-tracktitle': data.track_title,
                            'data-artist': data.artist_name,
                            'data-description': data.description,
                            'data-duration': data.duration,
                            'data-vocaltype': data.vocal_type,
                            'data-trackid': data.id,
                            'data-filecompressed': data.file_path_compressed,
                            'data-fileuncompressed': data.file_path_uncompressed
                        };

                        //console.log(data)

                        if (data.id !== this.state.trackId) {
                            return (
                                <div className="row ftTable">
                                    <div className="col-sm-1 text-left">
                                        <span>
                                            <BrowserRouter>
                                                <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                                            </BrowserRouter>
                                        </span>
                                    </div>
                                    <div className="col-8 text-left categorytitle">

                                        <span className="trackTitle">{data.track_title}</span>  <span className="trackIdTitle"></span>
                                        <span className="artistNameSpan">  {data.artist_name}</span>
                                    </div>
                                    <div id="trackactionblock" className="col-sm-3 text-right text-nowrap">
                                        <BrowserRouter>
                                            <span className="trackActions">{data.duration}</span>
                                            <Tooltip title={'Add ' + data.track_title + ' to your playlist'} placement="top">
                                                <Link data-trackid={data.id}  {...dataAttrs} onClick={this.addToPlaylist.bind(this)} className="trackActions"><PlaylistAddIcon /></Link>
                                            </Tooltip>
                                            {!this.isDirectAccess &&
                                                <Tooltip title={'Add to Shopping Cart'} placement="top">
                                                    <Link id={data.id}  {...dataAttrs} onClick={this.addToShoppingCart.bind(this)} className="trackActions"><ShoppingCartIcon /></Link>
                                                </Tooltip>
                                            }
                                            {this.isDirectAccess &&
                                                <React.Fragment>
                                                    <Tooltip title="Download MP3" aria-label="artist" placement="center-top">
                                                        <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.baseProps.downloadFliktraxFile(dataAttrs['data-filecompressed'], false)}><DownloadIcon /></Link>
                                                    </Tooltip>
                                                    <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-top">
                                                        <Link style={{ color: "#ca75ff" }} onClick={() => this.baseProps.downloadFliktraxFile(dataAttrs['data-fileuncompressed'], false)} > <DownloadIcon /></Link>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        </BrowserRouter>
                                    </div>
                                    <div style={{ width: "100%", marginTop: 15 }}>
                                        <Divider />
                                    </div>
                                </div>



                            )
                        }
                    })}
                </FlikTraxLoader>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    api: state.api,
    audioplayer: state.audioplayer,
    user: state.user,
    notifications: state.snackbar
  })
  
export default connect(mapStateToProps,
    {
      updateNowPlaying,
      updateIsPlaying,
      setPlayerStatus,
      updatePlayerPosition,
    })(Mixes);