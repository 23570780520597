import React, { Component, useContext } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserDuration } from '../../../FlikTraxStore/Actions/userActions';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
class BpmChip extends Component {

    deleteBpm = (event) => { 
      this.props.updateUserDuration(null);
    }

    render() {
        return(
            <div>
              <ul>
              {this.props.user.userBpm && 
                  <Chip size="small" 
                        color="default" 
                        label={this.props.user.userBpm}
                        avatar={<Avatar>BPM</Avatar>} 
                        onDelete={() => { this.deleteBpm() }} /> 
              }
              </ul>
            </div>  
         )
      }
}

BpmChip.propTypes = {
  updateUserDuration: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps,
  { updateUserDuration })
  (BpmChip);