import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../../FlikTraxStore/Actions/userActions';

import FormErrors from "../../../FormErrors.js";
import Validate from "../../../utility/FormValidation";
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class CartForgotPasswordVerification extends Component {
    state = {
        verificationcode: "",
        email: "",
        newpassword: "",
        errors: {
            cognito: null,
            blankfield: false
        },
        authenticating:false
    };

    redirectPage() {
        switch (window.location.pathname) {
            case '/':
                window.location.reload();
                break;

            default:
                this.props.history.push('/');
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    };

    passwordVerificationHandler = async event => {
        event.preventDefault();

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }

        // AWS Cognito integration here
        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.verificationcode,
                this.state.newpassword
            );
            try {
                this.setState({ authenticating: true });
                const user = await Auth.signIn(this.state.email, this.state.newpassword);
                this.props.updateAuthStatus(true);
                this.props.setAuthUser(user);
                setTimeout(() => {
                    this.redirectPage();
                }, 100);
            } catch (error) {
                this.setState({ authenticating: false });
                let err = null;
                !error.message ? err = { "message": error } : err = error;
                this.setState({
                    errors: {
                        ...this.state.errors,
                        cognito: err
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value.trim()
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    };

    render() {
        return (
            <div>

                <h3>
                    Reset Password
                </h3>
                <p>
                    Please enter the verification code sent to your email address and a new password.
                </p>

                <FormErrors formerrors={this.state.errors} />

                <form style={{ backgroundColor: "#c4dbe3", padding: 50 }} onSubmit={this.passwordVerificationHandler}>
                    <div className="field">
                        <p className="control">
                            <input
                                type="text"
                                className="form-control"
                                id="verificationcode"
                                aria-describedby="verificationCodeHelp"
                                placeholder="Enter verification code"
                                value={this.state.verificationcode}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="email"
                                id="email"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                type="password"
                                className="input"
                                id="newpassword"
                                placeholder="New Password"
                                value={this.state.newpassword}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <button className="btn btn-lg btn-primary btn-block btn-signin">
                            {this.state.authenticating && <span><i class="fas fa-sync-alt fa-spin" style={{ marginRight: "5px" }} /></span>} Submit
                </button>
                        </p>
                    </div>
                </form>
            </div>

        );
    }
}

CartForgotPasswordVerification.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps,
    {
        updateAuthStatus,
        setAuthUser,
        updateAuthenticating
    })(withRouter(CartForgotPasswordVerification));