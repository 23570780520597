import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserDuration } from '../../../FlikTraxStore/Actions/userActions';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

class DurationChip extends Component {

    deleteBPM = (event) => { 
      this.props.updateUserDuration(null)
    }

    render() {
        return(
            <div>
              <ul>
                  {this.props.user.userDuration && 
                    <span style={{margin:25}}>
                      <Chip
                            size="small" 
                            color="default" 
                            label={this.props.user.userDuration}
                            avatar={<Avatar>DN</Avatar>} 
                            onDelete={() => { this.deleteBPM() }} />
                    </span>  
                  }
              </ul>
            </div>

         )
      }
}

DurationChip.propTypes = {
  updateUserDuration : PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return { user: state.user };
} 

export default connect(mapStateToProps, { updateUserDuration })(DurationChip);