import React, { Component } from 'react'
import Navigation from '../Header/Navigation';
import { UserNav } from '../UserNav/UserNav';
import { Custom } from './Sections/Custom';
// import { HitItHard } from './Sections/HitItHard';
// import { ShowCase } from './Sections/ShowCase';
import { Clients } from './Sections/Clients';
import { Footer } from '../Footer/Footer';

export class About extends Component { 

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);
    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="About">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          {/* {!this.isAuthenticated && (
          <UserNav authProps={this.props} />
          )} */}
          <Custom />
          {/* <HitItHard />
          <ShowCase /> */}
          <Clients />
        </div>

        <Footer />
      </div>

    )


  }
}

export default About
