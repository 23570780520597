import React, { Component } from 'react';
import FormErrors from "../../../FormErrors.js";
import Validate from "../../../utility/FormValidation";
import { Auth } from 'aws-amplify';

class CartForgotPassword extends Component {


    constructor(props) {
        super(props);
        console.log(props);
    }


    state = {
        email: "",
        errors: {
            cognito: null,
            blankfield: false
        },
        authenticating: false
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
        this.setState({ authenticating: false });
    }

    forgotPasswordHandler = async event => {
        event.preventDefault();

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }

        // AWS Cognito integration here
        try {
            this.setState({ authenticating: true });
            await Auth.forgotPassword(this.state.email);
            //this.props.history.push('/forgotpasswordverification');
            this.props.switchForgotVerify();
        } catch (error) {
            this.setState({ authenticating: false });
            console.log(error);
        }
    }

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value.trim()
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    }

    render() {
        return (
            <div>

                <h3>
                    Reset Password
                </h3>
                <p>
                    Please enter the email address associated with your account and we'll
                    email you verification code to reset your password.
                </p>

                <FormErrors formerrors={this.state.errors} />
                <form style={{ backgroundColor: "#c4dbe3", padding: 50 }} onSubmit={this.forgotPasswordHandler}>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.username}
                        onChange={this.onInputChange}
                    />

                    <div style={{ marginTop: "25px" }} className="field">
                        <p className="control">
                            <button className="btn btn-lg btn-primary btn-block btn-signin">
                                {this.state.authenticating && <span><i class="fas fa-sync-alt fa-spin" style={{ marginRight: "5px" }} /></span>} Submit
                            </button>
                        </p>
                    </div>

                </form>

            </div>
        );
    }
}

export default CartForgotPassword;