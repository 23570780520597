import React, { useContext } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserFilters, updateUserVocalType } from '../../../FlikTraxStore/Actions/userActions';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

 function VocalTypes(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState();

  function handleVtChange(event) {
    props.updateUserVocalType(event.target.value);
  }

  return (
    <div className={classes.root}>
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <FormLabel component="legend">Vocal Type</FormLabel> */}
            <RadioGroup
              aria-label="VocalType"
              name="vocaltype"
              className={classes.group}
              value={value}
              onChange={handleVtChange}
            >
              <FormControlLabel className="filter-radios" value="female" control={<Radio />} label="Female" />
              <FormControlLabel className="filter-radios" value="male" control={<Radio />} label="Male" />
              <FormControlLabel className="filter-radios" value="instrumental" control={<Radio />} label="Instrumental" />
            </RadioGroup>
          </FormControl>
    </div>
  );
}

VocalTypes.propTypes = {
  updateUserFilters: PropTypes.func.isRequired,
  updateUserVocalType: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return { user: state.user };
} 

export default connect(mapStateToProps, { updateUserFilters, updateUserVocalType })(VocalTypes);
