import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import FilterList from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import '../css/Filters.css'
import Moods from './FilterServices/Moods';
import MoodChips from './Chips/MoodChips';
import VocalTypeChip from './Chips/VocalTypeChip';
import StyleChips from './Chips/StyleChips';
import InstrumentChips from './Chips/InstrumentChips';
import BpmChip from './Chips/BpmChip';
import DurationChip from './Chips/DurationChip';
import Styles from './FilterServices/Styles';
import Instruments from './FilterServices/Instruments';
import VocalType from './FilterServices/VocalType';
import BeatsPerMinute from './FilterServices/BeatsPerMinute';
import Durations from './FilterServices/Durations';
//import DurationsSlider from './FilterServices/DurationsSlider';
import { clearUserFilters, updateFilterDrawerStatus } from '../../FlikTraxStore/Actions/userActions';
import { fetchApiData } from '../../FlikTraxStore/Actions/apiActions';

class Filters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      open: false
    };
  }

  getFilterState() {
    var fstate = true;
    var moods = this.props.user.userMoods.length;
    var styles = this.props.user.userStyles.length;
    var instruments = this.props.user.userInstruments.length;
    var vtypes = this.props.user.userVocalType;
    var bpms = this.props.user.userBpm;
    var duration = this.props.user.userDuration;
    if ((moods === 0) && (styles === 0) && (instruments === 0) && (!vtypes) && (!bpms) && (!duration)) {
      fstate = false;
    }
    return fstate;
  }

  filterMessage = () => {
    var filterState = this.getFilterState();
    if (!filterState) {
      return (
        <h5><span style={{ marginTop: 25 }} className="badge badge-primary">No Filters Selected</span></h5>
      )
    }
  };

  clearUserFilters() {
    this.props.clearUserFilters();
    this.props.closeDrawer();
    this.props.fetchApiData();
  }

  applyUserFilters() {
    var mixlist = document.getElementsByClassName('mixcontainer');
    for (var i = 0; i < mixlist.length; i++) {
      mixlist[i].innerHTML = "";
    }
    this.props.closeDrawer();
    this.props.fetchApiData();
  }

  // componentDidUpdate(){
  //   console.log(this.props.user)
  // }

  filterApplyButtons = () => {
    var filterState = this.getFilterState();
    if (filterState) {
      return (
        <div>
          <Button onClick={this.applyUserFilters.bind(this)} variant="contained" size="small" color="primary">
            Apply Filters
          </Button>

          <Button onClick={this.clearUserFilters.bind(this)} variant="contained" size="small" color="default">
            Clear Filters
          </Button>
        </div>
      )
    }
  };

  handleClick = () => {
    this.setState({ prev: true });
  };

  handleClickAway = () => {
    //alert("test")
    this.setState({ open: false })
  };


  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {

    const { expanded } = this.state;
    return (

      <div style={{minHeight:2000}} >
        {/* onChange={this.handlePanelChange('selectedfilters')}  */}
        <ExpansionPanel className="filterpanel" expanded={expanded === 'selectedfilters'} expanded>
          <ExpansionPanelSummary
            expandIcon={<FilterList />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className="">
              <Typography className="filterdds">SELECTED FILTERS</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <div style={{ maxWidth: "300px", paddingTop: 25, display: 'flex', justifyContent: 'center', flexwrap: 'wrap-reverse' }}>

              <MoodChips />
              <InstrumentChips />
              <StyleChips />
              <VocalTypeChip />
              <BpmChip />
              <DurationChip />

            </div>

            {this.filterMessage()}


          </ExpansionPanelDetails>
          <Divider />

          <ExpansionPanelActions>

            {this.filterApplyButtons()}

          </ExpansionPanelActions>

        </ExpansionPanel>

        <ExpansionPanel className="filterpanel" expanded={expanded === 'moods'} onChange={this.handlePanelChange('moods')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography id="moodsHeader" className="filterdds" ref="moodsHeadeer">Moods</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <Moods />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className="filterpanel" expanded={expanded === 'instruments'} onChange={this.handlePanelChange('instruments')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="filterdds">Instruments</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <Typography >
              <Instruments />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className="filterpanel" expanded={expanded === 'styles'} onChange={this.handlePanelChange('styles')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="filterdds">Styles</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <Typography>
              <Styles />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className="filterpanel" expanded={expanded === 'vocals'} onChange={this.handlePanelChange('vocals')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="filterdds">Vocal Type</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <VocalType />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className="filterpanel" expanded={expanded === 'bpm'} onChange={this.handlePanelChange('bpm')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="filterdds">BPM</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <Typography>
              <BeatsPerMinute />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className="filterpanel" expanded={expanded === 'durations'} onChange={this.handlePanelChange('durations')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="filterdds">Duration</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filtercontents">
            <Durations />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>

    );

  }

}

Filters.propTypes = {
  fetchApiData: PropTypes.func.isRequired,
  clearUserFilters: PropTypes.func.isRequired,
  updateFilterDrawerStatus: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  api: state.api
})

export default connect(mapStateToProps, { clearUserFilters, fetchApiData, updateFilterDrawerStatus })(Filters);

