import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { updateOrder } from '../../FlikTraxStore/Actions/cartActions';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
//import Typography from '@material-ui/core/Typography';
import CartRegister from './CartRegister';
import CartLogin from './CartLogin';
import OrderTable from './OrderTable';
import PaymentForm from './PaymentForm';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    // marginTop: "50px;",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Choose License', 'Payment Details', 'Confirm'];
//const steptitles = ['Choose License', 'Payment Details', 'Confirm'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <CartLogin />;
    case 1:
      return <CartRegister />;
    case 2:
      return <OrderTable />;
    case 3:
      return <PaymentForm />;
    // case 4:
    //   return <Review />;
    default:
      throw new Error('Unknown step');
  }
}


function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

function Checkout(state) {

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    mapStateToProps({ state });
  }, []);

  const handleNext = () => {
    if(activeStep === 3){
      return;
    }
    var as = activeStep + 1;
    state.updateOrder('currentStep', as);
    setActiveStep(as);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setActiveStep(2);
      }).catch(err => {
        setActiveStep(1);
      });
  }, []);

  return (
    <React.Fragment>
      {activeStep === 1 &&
        <h3 style={{ fontSize: "1.1em", color: "black", fontFamily: "Gibson W01 Regular" }}>Register to continue - Already have an account? <span style={{ textDecoration: "underline" }}><Link onClick={() => setActiveStep(0)} >Click here to login</Link></span></h3>
      }
      {activeStep === 0 &&
        <h3 style={{ fontSize: "1.1em", color: "black", fontFamily: "Gibson W01 Regular" }}>Login to continue - Don't have an account? <span style={{ textDecoration: "underline" }}><Link onClick={() => setActiveStep(1)} >Click here to register</Link></span></h3>
      }
      <main className={classes.layout}>
        {activeStep > 1 &&
          <Stepper activeStep={state.cart.currentOrder.currentStep - 2} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        }
        <React.Fragment>
          {getStepContent(activeStep)}
          
          {(state.cart.cartContents.length !== 0) && (activeStep >= 2) &&
            <div className={classes.buttons}>
              {(activeStep !== 3) && (
                <Link to="/cart">
                <Button className={classes.button}>
                  Back
                </Button>
                </Link>
              )}
              {(state.cart.cartContents.length !== 0) && activeStep !== 3 &&
              <Button
                variant="contained"
                color="primary"
                disabled={state.cart.currentOrder && state.cart.currentOrder.total > 0 ? false: true}
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
              }
            </div>
          }
        </React.Fragment>
        

      </main>
    </React.Fragment>
  );
}



Checkout.propTypes = {
  updateOrder: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { updateOrder })(Checkout)

