import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../../../FlikTraxStore/Actions/userActions';

import FormErrors from "../../../FormErrors.js";
import Validate from "../../../utility/FormValidation";
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class ForgotPasswordVerification extends Component {
    state = {
        verificationcode: "",
        email: "",
        newpassword: "",
        errors: {
            cognito: null,
            blankfield: false
        }
    };

    redirectPage() {
        switch (window.location.pathname) {
            case '/':
                window.location.reload();
                break;

            default:
                this.props.history.push('/');
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    };

    passwordVerificationHandler = async event => {
        event.preventDefault();

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }

        // AWS Cognito integration here
        try {
            await Auth.forgotPasswordSubmit(
                this.state.email.trim(),
                this.state.verificationcode.trim(),
                this.state.newpassword
            );
            try {
                //return(console.log(this.state.username + ':' + this.state.password));
                const user = await Auth.signIn(this.state.email, this.state.newpassword);
                this.props.updateAuthStatus(true);
                this.props.setAuthUser(user);
                setTimeout(() => {
                    this.redirectPage();
                }, 100);
            } catch (error) {
                console.log(error);
                let err = null;
                !error.message ? err = { "message": error } : err = error;
                this.setState({
                    errors: {
                        ...this.state.errors,
                        cognito: err
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value.trim()
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    };

    render() {
        return (
            <div className="card card-container">

                <h2>
                    Reset Password
            <Tooltip className="float-right" title="Canel/Close" aria-label="Log In" placement="top">
                        <Button onClick={this.props.closeLoginForm} size="small">
                            <CloseIcon />
                        </Button>
                    </Tooltip>
                </h2>
                <p>
                    Please enter the verification code sent to your email address and a new password.
                </p>

                <FormErrors formerrors={this.state.errors} />

                <form onSubmit={this.passwordVerificationHandler}>
                    <div className="field">
                        <p className="control">
                            <input
                                type="text"
                                className="form-control"
                                id="verificationcode"
                                aria-describedby="verificationCodeHelp"
                                placeholder="Enter verification code"
                                value={this.state.verificationcode}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="email"
                                id="email"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                autocomplete="new-password" 
                                type="password"
                                className="input"
                                id="newpassword"
                                placeholder="New Password"
                                value={this.state.newpassword}
                                onChange={this.onInputChange}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <button className="btn btn-lg btn-primary btn-block btn-signin">
                                Submit
                </button>
                        </p>
                    </div>
                </form>
            </div>

        );
    }
}

ForgotPasswordVerification.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps,
    {
        updateAuthStatus,
        setAuthUser,
        updateAuthenticating
    })(withRouter(ForgotPasswordVerification));