import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateOrder, loadLicenses } from '../../../FlikTraxStore/Actions/cartActions';
import axios from 'axios';
import apiconfig from '../../../../config/api-config.js';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        fontSize: "0.85em"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "1.5em",
        width: 200,
        backgroundColor: "#66a0d2",
        // paddingTop: 50
    }
}));



function mapStateToProps(state) {
    return {
        ...state,
        cart: state.cart,
        user: state.user
    };
}

function LicenseSelect(state) {

    const classes = useStyles();

    function openOlarkBox() {
        var tab = document.getElementsByClassName("olark-launch-button");
        tab[0].click();
    }

    function getDisplayPrice(price) {
        var priceText = "";
        switch (price === 0) {
            case true:
                return (<Link style={{ color: "white", textDecoration: "underline" }} onClick={openOlarkBox.bind(this)}>Contact Us</Link>);

            default:
                priceText = "$" + price.toFixed(2);
        }
        return priceText;
    }

    function displayCoverage() {

        var currentLicense = state.cart.currentOrder.selectedLicense;

        if (currentLicense) {
            return (
                <Card className={classes.card}>
                    <CardMedia className={classes.cover} style={{ width: "33%" }} >
                        {getDisplayPrice(currentLicense.price)}
                    </CardMedia>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="div" variant="div" textAlign="left">
                                {currentLicense.description}
                            </Typography>
                            <Typography component="div" variant="div" textAlign="left">
                                {currentLicense.coverage}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            );
        }
    }

    function updateLicense(data) {
        // if (data.price === 0) {
        //     return;
        // }
        //console.log(data.id)
        state.updateOrder('selectedLicense', data);
        localStorage.setItem('selectedLicenseId', data.id);
        if (state.cart.currentOrder.selectedLicense) {
            var itemNum = state.cart.cartContents.length;
            var itemPrice = state.cart.currentOrder.selectedLicense.price;
            var totalCart = itemPrice * itemNum;
            state.updateOrder('itemPrice', itemPrice);
            var ccapplied = localStorage.getItem('couponapplied');
            if(ccapplied){
                var appliedcoupon = JSON.parse(localStorage.getItem('appliedcoupon'));
                totalCart = totalCart - (totalCart * appliedcoupon.discount);
            }
            state.updateOrder('total', totalCart);
        }
    }

    function getLicenses() {
        axios({
            baseURL: apiconfig.api.APIURL + 'licenses',
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
        })
            .then(response => {
                state.loadLicenses(response.data._embedded.licenses);
            });
    }

    useEffect(() => {
        mapStateToProps({ state });
        getLicenses();
        //console.log(state)
    }, []);



    return (
        <div style={{ padding: 25, textAlign: "center", backgroundColor: "#c4dbe3", fontFamily: "Gibson W01 Regular" }}>
            <FormControl component="fieldset">
                {/* <FormLabel style={{ fontSize: "1.5em", color: "white", backgroundColor: "#272833", padding: 15 }}>CHOOSE A LICENSING OPTION</FormLabel> */}
                <RadioGroup aria-label="position" name="position" row>
                    {state.cart.licenses.length > 0 && state.cart.licenses.map((data, index) => {
                        return (
                            <FormControlLabel
                                style={{ fontFamily: "Gibson W01 SemiBold" }}
                                value={data.id}
                                control={<Radio id={data.id} onClick={() => updateLicense(data)} color="primary" />}
                                label={data.title}
                                labelPlacement="bottom"
                            />
                        )
                    })
                    }
                </RadioGroup>
            </FormControl>
            {displayCoverage()}
        </div>
    );
}

LicenseSelect.propTypes = {
    updateOrder: PropTypes.func.isRequired,
    loadLicenses: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { updateOrder, loadLicenses })(LicenseSelect)