import React, { Component } from 'react';
import './css/UserBanner.css';
import './css/WhatsNew.css';
import './css/SearchTracks.css';
import './css/CustomMusic.css';
import Navigation from '../Header/Navigation';
// import { UserNav } from '../UserNav/UserNav';
import WelcomeDialog  from './WelcomeDialog'; 
import  UserBanner  from './Sections/UserBanner';
import  SearchTracks  from './Sections/SearchTracks';
import  WhatsNew  from './Sections/WhatsNew';
import  CustomMusic  from './Sections/CustomMusic';
import { Footer } from '../Footer/Footer';

class UserHome extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
  }

  render() {
    return (
      <div className="App">
        <WelcomeDialog dialogopen='true' />
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
           {/* <UserNav authProps={this.props} /> */}
          <UserBanner authProps={this.props} />
          <SearchTracks authProps={this.props} />
          <WhatsNew authProps={this.props} />
          <CustomMusic authProps={this.props} />
        </div>
          <Footer />
        </div>
    )
  }
}

export default UserHome
