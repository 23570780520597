import React, { Component } from 'react';
import Navigation from '../Header/Navigation';
//import { UserNav } from '../UserNav/UserNav';
import MasterDashboard from './Sections/MasterDashboard';
import { Footer } from '../Footer/Footer';

class Dashboard extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          {/* {!this.isAuthenticated && (
            <UserNav authProps={this.props} />
          )} */}
          <MasterDashboard authProps={this.props} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Dashboard
