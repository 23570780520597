import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateNowPlaying, updateIsPlaying, updatePlayerPosition, setPlayerStatus } from '../../FlikTraxStore/Actions/audioPlayerActions'
import { Link } from 'react-router-dom';

import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import FastRewind from '@material-ui/icons/FastRewind';
import Forward10 from '@material-ui/icons/Forward10';
import Replay10 from '@material-ui/icons/Replay10';

import ReactWaves from '@dschoon/react-waves';
import './css/ftaudioplayer.css';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wavesurfer: null,
      playing: false,
      pos: 0,
      tracktitle: null,
      trackartist: null,
      tracksource: null,
    };
  }

  formatTrackCounter(time) {
    var minutes = Math.floor(time / 60).toString().padStart(2, '0');
    var seconds = Math.floor(time - minutes * 60).toString().padStart(2, '0');
    return minutes + ':' + seconds;
  }

  getTrackCounterUpdate() {
    if (this.props.audioplayer.isPlaying) {
      if (this.state.wavesurfer !== null) {
        return this.formatTrackCounter(this.props.audioplayer.position);
      }
    } else {
      return '00:00';
    }
  }
  

  getTrackDuration() {
    if (this.state.wavesurfer !== null) {
      return this.formatTrackCounter(this.state.wavesurfer.getDuration());
    } else {
      return '00:00';
    }
  }

  onPosChange = (pos, wavesurfer) => {
    if (pos !== this.props.audioplayer.position) {
      this.setState({ pos, wavesurfer });
      this.props.updatePlayerPosition(pos);
    }
  };

  skipAhead = () => {
    this.state.wavesurfer.seekTo(this.secondsToPosition(this.state.pos + 10));
  };


  secondsToPosition = (sec) => {
    return 1 / this.state.wavesurfer.getDuration() * sec;
  };

  // wsInit(){
  //   // alert('test')
  //   this.state.wavesurfer &&
    
  //   this.state.wavesurfer.on('ready', function () {
  //     this.state.wavesurfer.play();
  //   });
  // }


  render() {

    return (
      <div id="ftaudioplayer" className="audiowrapper" ref="FlikTraxAudioPlayer">
        <div className="row">

          <div style={{ whiteSpace: "nowrap" }} className="col-sm">

            <div className="audiobtn-wrapper">

              <Link onClick={() => { this.state.wavesurfer.seekTo(0) }}></Link>
              <Link onClick={() => { this.state.wavesurfer.seekTo(0) }}><FastRewind fontSize='large' /></Link>
              <Link onClick={() => { this.state.wavesurfer.skipBackward(10) }}><Replay10 fontSize='large' /> </Link>
              <Link id="audioplaybtn" onClick={() => { this.props.updateIsPlaying(!this.props.audioplayer.isPlaying) }}>
                {!this.props.audioplayer.isPlaying ? <PlayCircleOutline fontSize='large' /> : <PauseCircleOutline fontSize='large' />}
              </Link>

              <Link onClick={() => { this.state.wavesurfer.skipForward(10) }}><Forward10 fontSize='large' /></Link>
            </div>
          </div>

          <div className="col-sm  d-none d-sm-block" style={{ margin: "0", width: "100%", marginTop: "-5px" }}>
            <div className="row audio-info">
              <div className="col-sm  startcounter">
                {this.getTrackCounterUpdate()}
              </div>
              <div className="">
                <Link to="/">{this.props.audioplayer.currentTrackTitle + ' - ' + this.props.audioplayer.currentArtist} </Link>
              </div>
              <div className="col-sm endcounter">
                {this.getTrackDuration()}
              </div>
            </div>

            <ReactWaves
              audioFile={this.props.audioplayer.currentTrack}
              className='react-waves'
              //onWaveformReady={this.wsInit.bind(this)}
              options={{
                backend: 'MediaElement',
                barHeight: 1,
                barGap: 2,
                barWidth: 1,
                cursorWidth: 1,
                height: 55,
                hideScrollbar: true,
                progressColor: '#EC407A',
                responsive: true,
                waveColor: '#D1D6DA',
                fillParent: true,
                autoCenter: true,
              }}
              volume={1}
              zoom={1}
              playing={this.props.audioplayer.isPlaying}
              pos={this.props.audioplayer.position}
              onPosChange={this.onPosChange}
            />
          </div>
          <div className="col-sm  d-none d-sm-block">
          </div>
        </div>
        {/* {this.watch()} */}
      </div>
    )
  }
}

AudioPlayer.propTypes = {
  updateNowPlaying: PropTypes.func.isRequired,
  updateIsPlaying: PropTypes.func.isRequired,
  updatePlayerPosition: PropTypes.func.isRequired,
  setPlayerStatus: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  audioplayer: state.audioplayer
})

export default connect(mapStateToProps,
  {
    updateNowPlaying,
    updateIsPlaying,
    updatePlayerPosition,
    setPlayerStatus
  })(AudioPlayer);