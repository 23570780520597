import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlikTraxStore from '../../FlikTraxStore/FlikTraxStore';
import { fetchApiData, setCurrentSearch, setCurrentSearchTerm, switchMasterMode, switchApiUrl, switchApiPage, downloadFliktraxFile } from '../../FlikTraxStore/Actions/apiActions';
import { updateNowPlaying, updateIsPlaying, setPlayerStatus, updatePlayerPosition } from '../../FlikTraxStore/Actions/audioPlayerActions';
import { updateCurrentRoute, addToPlaylist, loadLocalPlaylist } from '../../FlikTraxStore/Actions/userActions';
import { addToShoppingCart } from '../../FlikTraxStore/Actions/cartActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';
import FlikTraxLoader from '../Loader/Loading';
import Mixes from './Mixes';
import { Link, withRouter } from 'react-router-dom';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import SimplePaginator from './Paginators/SimplePaginator';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PlaylistAddIcon from '@material-ui/icons/QueueMusic';
import Tooltip from '@material-ui/core/Tooltip';
import Keywords from '../TrackMetaData/TrackKeywords.js';
import './css/tables.css';
class Tracks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mixStates: []
    }

    this.isDirectAccess = this.props.user.userGroups.indexOf("direct-access") > -1;
    this.updateNowPlaying = this.updateNowPlaying.bind(this);
    this.addToPlaylist = this.addToPlaylist.bind(this);
    this.currentPath = this.props.history.location.pathname;

    this.styles = {
      largeIcon: {
        width: 60,
        height: 60,
      },

    };
  }

  componentWillMount() {
    this.props.fetchApiData();
  }

  componentWillReceiveProps({ someProp }) {
    this.setState({ ...this.state })
  }

  updateNowPlaying(event) {
    event.preventDefault();
    this.setState(this.state);
    var targetId = event.currentTarget.id;
    var nowPlaying = event.currentTarget.dataset;
    this.props.updateNowPlaying(nowPlaying);

    if (this.props.audioplayer.currentTrackId === targetId) {
      this.props.updateIsPlaying(!this.props.audioplayer.isPlaying);
      this.props.updatePlayerPosition(this.props.audioplayer.position);
    } else {
      this.props.updateIsPlaying(true);
      this.props.updatePlayerPosition(0);
    }
  }

  getMixes(trackId) {

    var mixElement = 'mixcontainer-' + trackId;
    var currentStates = this.state.mixStates;

    if (currentStates.indexOf(mixElement) === -1) {
      ReactDOM.render(<Provider store={FlikTraxStore}><Mixes trackid={trackId} getTrackInfoLink={this.props.getTrackInfoLink} baseProps={this.props} /></Provider>, document.getElementById(mixElement));
      currentStates.push(mixElement);
    } else {
      ReactDOM.render('', document.getElementById(mixElement));
      currentStates = currentStates.slice(currentStates.indexOf(mixElement), 1);
    }
    this.setState({ mixStates: currentStates });
    document.getElementById(trackId).scrollIntoView();
  }

  addToPlaylist = (e) => {
    var currentUser = this.props.user;
    var isAuthenticated = currentUser.isAuthenticated;

    switch (isAuthenticated) {
      case true:
        var trackId = e.currentTarget.getAttribute('data-trackid');
        var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

        var notification = trackTitle + ' added to your playlist';
        this.props.addToPlaylist(trackId);

        setTimeout(() => {
          this.props.loadLocalPlaylist();
        }, 1000);


        this.props.enqueueSnackbar({
          message: notification,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            action: key => (
              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
            ),
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            preventDuplicate: true,
            autoHideDuration: 1500
          },
        });
        break;
      default:
        this.props.enqueueSnackbar({
          message: "Please login or register to add to your playlist",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            action: key => (
              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
            ),
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            preventDuplicate: true,
            autoHideDuration: 1500
          },
        });
    }
  }

  addToShoppingCart = (e) => {

    var trackId = e.currentTarget.getAttribute('data-trackid');
    var trackTitle = e.currentTarget.getAttribute('data-tracktitle');

    var notification = trackTitle + ' added to your shopping cart';

    this.props.addToShoppingCart(trackId);

    this.props.enqueueSnackbar({
      message: notification,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        action: key => (
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        preventDuplicate: true,
        autoHideDuration: 1500
      },
    });
  }

  encIOSURL(str) {
    return str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
  }

  onArtistClick = event => {
    window.scrollTo({ top: 30, behavior: 'smooth' });
    var searchterm = event.target.dataset.artistname;
    var trackid = event.target.dataset.trackid;
    var searchstr = searchterm.trim();
    if (searchstr === "") {
      return false;
    } else {
      this.props.setCurrentSearch(trackid);
      this.props.setCurrentSearchTerm('artist', searchterm);
      var iOSstr = this.encIOSURL(searchstr);
      var encsearch = encodeURIComponent(iOSstr);
      var apiUrl = 'search?searchmode=tvpros&searchtype=2&searchterm=' + encsearch;
      this.props.switchMasterMode('tracks');
      this.props.switchApiPage(1);
      this.props.switchApiUrl(apiUrl);
      if (this.currentPath === '/dashboard') {
        this.props.fetchApiData();
      } else {
        this.props.history.push('/dashboard');
      }

    }
  };

  render() {

    var trackValues = this.props.api.embeddedData;
    var tracks = trackValues[0];
    //console.log(tracks && tracks.length)
    var truncate = require('truncate');

    if (tracks && tracks.length === 0) {
      return (
        <SnackbarContent message="Sorry. There are no results for this category." />
      )
    }

    return (

      <div className="container">
        <FlikTraxLoader active={this.props.api.isLoading}>
          <SimplePaginator />
          {tracks &&
            tracks.map((data, index) => {

              let dataAttrs = {
                'data-source': data.preview_url,
                'data-tracktitle': data.track_title,
                'data-artist': data.artist_name,
                'data-description': data.description,
                'data-duration': data.duration,
                'data-vocaltype': data.vocal_type,
                'data-trackid': data.id,
                'data-filecompressed': data.file_path_compressed,
                'data-fileuncompressed': data.file_path_uncompressed
              };

              if (typeof data.id === 'undefined' || data.id === null) {
                data.id = data._id;
              }

              var vcount = (data.variation_count - 1);
              return (
                <div>
                  <div className="row ftTable">
                    <div className="col-sm-1 text-left">
                      <span>
                        <Link id={data.id} {...dataAttrs} onClick={this.updateNowPlaying}>{((this.props.audioplayer) && (this.props.audioplayer.currentTrackId === data.id) && (this.props.audioplayer.isPlaying)) ? <PauseCircleOutline className="svg_icons" fontSize='large' /> : <PlayCircleOutline className="svg_icons" fontSize='large' />}</Link>
                      </span>
                    </div>
                    <div className="col-8 text-left categorytitle">

                      <span className="trackTitle">{data.track_title}</span>  <span className="trackIdTitle">
                        {data.variation_count &&
                          <Tooltip title={'Show ' + vcount + ' alternate mixes'} placement="top">
                            <ButtonGroup className="mixBut" size="small" color="primary">
                              <Button size="small" onClick={() => this.getMixes(data.id)} > + {vcount} </Button>
                            </ButtonGroup>
                          </Tooltip>
                        }
                      </span>
                      <span className="artistNameSpan"><Link className="artistlink" data-trackid={data.id} data-artistname={data.artist_name} onClick={this.onArtistClick}>{data.artist_name}</Link></span>
                      <br />
                      <span className="trackDescript">{truncate(data.description, 200)}</span>
                      <Keywords trackid={data.id}
                        keywords={data.keywords}
                        genres={data.genres_keys}
                        styles={data.styles}
                        moods={data.moods}
                        location={this.props.location}
                        history={this.props.history}
                      />
                    </div>
                    <div id="trackactionblock" className="col-sm-3 text-right text-nowrap">
                      <span className="trackActions">{data.duration}</span>
                      <Tooltip title={'Add ' + data.track_title + ' to your playlist'} placement="top">
                        <Link data-trackid={data.id}  {...dataAttrs} onClick={this.addToPlaylist.bind(this)} className="trackActions"><PlaylistAddIcon /></Link>
                      </Tooltip>
                      {!this.isDirectAccess &&
                        <Tooltip title={'Add to Shopping Cart'} placement="top">
                          <Link id={data.id}  {...dataAttrs} onClick={this.addToShoppingCart.bind(this)} className="trackActions"><ShoppingCartIcon /></Link>
                        </Tooltip>
                      }
                      {this.isDirectAccess &&
                        <React.Fragment>
                          <Tooltip title="Download MP3" aria-label="artist" placement="center-top">
                            <Link style={{ marginRight: 15, color: "#8575ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-filecompressed'], false)}><DownloadIcon /></Link>
                          </Tooltip>
                          <Tooltip title="Download Uncompressed" aria-label="artist" placement="center-top">
                            <Link style={{ color: "#ca75ff" }} onClick={() => this.props.downloadFliktraxFile(dataAttrs['data-fileuncompressed'], false)} > <DownloadIcon /></Link>
                          </Tooltip>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Divider />
                  </div>

                  {/* Start Mixes */}
                  <div className="mixcontainer" id={'mixcontainer-' + data.id} style={{ width: "100%" }}></div>
                  {/* End Mixes */}

                </div>


              )
            })
          }
          <SimplePaginator />
        </FlikTraxLoader>
      </div>
    );
  }
}

Tracks.propTypes = {
  fetchApiData: PropTypes.func.isRequired,
  switchMasterMode: PropTypes.func.isRequired,
  switchApiUrl: PropTypes.func.isRequired,
  switchApiPage: PropTypes.func.isRequired,
  switchPlayerMode: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  updateNowPlaying: PropTypes.func.isRequired,
  updateIsPlaying: PropTypes.func.isRequired,
  setPlayerStatus: PropTypes.func.isRequired,
  updateCurrentRoute: PropTypes.func.isRequired,
  updatePlayerPosition: PropTypes.func.isRequired,
  updateDialogMessage: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
  addToPlaylist: PropTypes.func.isRequired,
  addToShoppingCart: PropTypes.func.isRequired,
  loadLocalPlaylist: PropTypes.func.isRequired,
  downloadFliktraxFile: PropTypes.func.isRequired,
  setCurrentSearch: PropTypes.func.isRequired,
  setCurrentSearchTerm: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  api: state.api,
  audioplayer: state.audioplayer,
  user: state.user,
  cart: state.cart,
  notifications: state.snackbar
})

export default connect(mapStateToProps,
  {
    fetchApiData,
    switchMasterMode,
    switchApiUrl,
    switchApiPage,
    updateNowPlaying,
    updateIsPlaying,
    setPlayerStatus,
    updateCurrentRoute,
    updatePlayerPosition,
    enqueueSnackbar,
    closeSnackbar,
    removeSnackbar,
    addToPlaylist,
    addToShoppingCart,
    loadLocalPlaylist,
    downloadFliktraxFile,
    setCurrentSearch,
    setCurrentSearchTerm
  })(withRouter(Tracks));