import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class CouponDialog extends React.Component {

  constructor(props) {
    super(props);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }  

  state = {
    open: false,
    coupon: {}
  };

  componentDidMount(){
    var ccapplied = localStorage.getItem('couponapplied');
    if(ccapplied){
        let couponfirstrun = localStorage.getItem('couponFirstRun') || ''
        if(couponfirstrun === 'true'){
          if( this.props.dialogopen === 'true' ){
            this.handleClickOpen();
          }
        }
        let coupon = JSON.parse(localStorage.getItem('appliedcoupon'));
        this.setState({ coupon: coupon});
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    localStorage.setItem('couponFirstRun', false);
  };

  render() {
    return (
      <div>
        <Dialog 
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle style={{backgroundColor:"#081824"}} id="customized-dialog-title" onClose={this.handleClose}>
            <span style={{color:"black"}}>Welcome MoFilm Creators!</span>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
                <p>Coupon Code <strong style={{color:"green"}} >{this.state.coupon.code}</strong> applied to your cart! </p>
                <p className="text-center" ><strong style={{color:"black"}} >{this.state.coupon.discount * 100}%</strong> off all license orders.</p>
              <button onClick={this.handleClose} className="btn btn-lg btn-primary btn-block btn-resend" type="button">Start Searching</button>
            </Typography>
          </DialogContent>

        </Dialog>
      </div>
    );
  }
}

export default CouponDialog;
