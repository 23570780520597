import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUserBpm } from '../../../FlikTraxStore/Actions/userActions';


import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const bpmObj = {
  '0:80': '80 and Below',
  '81:100': '81 - 100',
  '101:120': '101 - 120',
  '121:140': '121 - 140',
  '140:1000': '141 and Above',
};

const BpmRadios = ({ data }) =>
  Object.entries(bpmObj).map(([k, v]) => (
    <FormControlLabel className="filter-radios" value={k} control={<Radio />} label={v} />
  ));

function BeatsPerMinute(props) {

  const classes = useStyles();

  function handleBPMChange(event) {
    props.updateUserBpm(event.target.value);
    console.log(props.user);
  }

  return (

    <div className={classes.root}>
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <FormLabel component="legend">Vocal Type</FormLabel> */}
            <RadioGroup
              aria-label="BeatsPerMinute"
              name="bpm"
              className={classes.group}
              //value={value}
              onChange={handleBPMChange}
            >
              <BpmRadios />
            </RadioGroup>
          </FormControl>
    </div>
  );
}

BeatsPerMinute.propTypes = {
  updateUserBpm : PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return { user: state.user };
} 

export default connect(mapStateToProps, { updateUserBpm })(BeatsPerMinute);
