import React, { Component } from 'react'
import Slider from "react-slick";


class TVProsClientCarousel extends React.Component {

    constructor(props) {
        super(props)
        this.images = [
            {
                original: '/images/icons/client_icons/DiscoveryId.png',
            },
            {
                original: '/images/icons/client_icons/JokeProd.jpg',
            },
            {
                original: '/images/icons/client_icons/TradingSpaces.png',
            },            
            {
                original: '/images/icons/client_icons/Animal_Planet.png',
            },
            {
                original: '/images/icons/client_icons/ATT.png',
            },
            {
                original: '/images/icons/client_icons/Channel_Frederator.jpeg',
            },
            {
                original: '/images/icons/client_icons/CNBC.png',
            },
            {
                original: '/images/icons/client_icons/tlc-logo.jpg',
            },
            {
                original: '/images/icons/client_icons/5096_1523390949.png',
            }
        ];
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 3
        };
        return (
            <Slider {...settings}>
                {this.images.map((image, key) =>
                    <div>
                        <img class="clientimg" src={image.original} alt="" />
                    </div>
                )}


            </Slider>
        );
    }
}


export default TVProsClientCarousel