import React, { Component } from 'react'
// import '../css/Clients.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "../css/image-gallery.css";
import TVProsClientCarousel from './TVProsClientCarousel';


export class Clients extends Component {

    // constructor(props) {
    //     super(props)
    //   } 
    
    render() {
        return (
            <section id="clients">
                <div className="content">
                        <Box alignItems="center" width={800} height={55} textAlign="center">
                        <TVProsClientCarousel />     
                        </Box>
                </div>
                {/* <a href="#werealize" className="goto-next scrolly">Next</a> */}
            </section>

        )
    }
}

export default Clients