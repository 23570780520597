import React, { Component } from 'react'
import Box from '@material-ui/core/Box';
import { UserBox } from './UserBox';
import LoginForm from './Forms/LoginForm';
import RegisterForm  from './Forms/RegisterForm';
import { Link } from 'react-router-dom';

export class UserNav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formActive: false,
      boxType: null
    }

    this.boxClass = ["masterBox"];
    this.boxId = ["#unavigation"];
    this.escFunction = this.escFunction.bind(this);
    this.frmCancel = this.frmCancel.bind(this);

  }

  escFunction(event) {
    // console.log(event.keyCode)
    if (event.keyCode === 27) {
      this.toggleForm('default');
    }
  }


  frmCancel() {
    this.toggleForm('');
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  toggleForm(type) {
    // console.log(type);
    this.setState({ formActive: true, boxType: type });
  }

  renderLogin() {
    let boxClass = this.boxClass;
    boxClass.push('mbExpanded');
    return (

      <Box className={boxClass.join(' ')}
        bgcolor="#C4DBE3"
        width={100}
        position="absolute"
        minHeight={100}
        top={10}
        right={10}
        zIndex="tooltip"
      >

        <Link style={{ fontSize: "25px", color: "black", marginRight: "50px", marginTop: "10px", border: "none" }} className="float-right" onClick={() => this.toggleForm('default')} >X</Link>

        <LoginForm {...this.props}  />

      </Box>
    );
  }

  renderRegistration() {
    let boxClass = this.boxClass;
    boxClass.push('mbExpanded');
    //boxClass.push('unExpanded');
    //console.log(boxClass);
    return (
      <Box className={boxClass.join(' ')}
        bgcolor="#C4DBE3"
        width={100}
        position="absolute"
        minHeight={100}
        top={10}
        right={10}
        zIndex="modal"
      >
        <Link style={{ fontSize: "25px", color: "black", marginRight: "50px", marginTop: "10px", border: "none" }} className="float-right" onClick={() => this.toggleForm('default')} >X</Link>

        <RegisterForm {...this.props} />

      </Box>
    );
  }

  renderDefault() {
    
    this.boxClass.splice(1, 1)
    let currentClass = this.boxClass.join(' ');
    return (
      <Box id="unavigation" className={this.boxClass}
        // borderRadius={5}
        bgcolor="#C4DBE3"
        width={65}
        position="fixed"
        minHeight={245}
        top={10}
        right={0}
        zIndex="tooltip"
        alignItems="center"
        justify="center"        
      >

        
          <React.Fragment>
            <Link style={{color:"black"}} className="userBoxes" onClick={() => this.toggleForm('login')} >
              <UserBox boxType="login" />
            </Link>
            <Link className="userBoxes" onClick={() => this.toggleForm('register')} >
              <UserBox boxType="register" />
            </Link>
          </React.Fragment>

        <Link className="userBoxes" >
          <UserBox boxType="newsletter" />
        </Link>
        <Link className="userBoxes">
          <UserBox boxType="cart" />
        </Link>
      </Box>
    );
  }


  render() {
    //console.log(this.props);
    switch (this.state.boxType) {
      case 'login':
        return this.renderLogin();
      case 'register':
        return this.renderRegistration();
      default:
        return this.renderDefault();
    }
    // if(( this.state.formActive )  && (this.state.boxType == 'login') )
    // return this.renderDefault();

    // let boxClass = ["d-none d-md-block masterBox"];
    // if (this.state.formActive) {
    //   boxClass.push('mbExpanded');
    // }
  }

}