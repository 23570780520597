import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchFilterData } from '../../../FlikTraxStore/Actions/filterActions';
import { updateUserFilters } from '../../../FlikTraxStore/Actions/userActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Styles extends Component {

    handleStyleCheckboxChange = (event) => {
        var selectedStyle = event.target.value;
        var inArray = require('in-array');
        var tchecked = event.target.checked;

        var currentStyles = this.props.user.userStyles;

        if (tchecked && !inArray(currentStyles, selectedStyle)) {
            currentStyles && currentStyles.push(event.target.value);
            this.props.updateUserFilters(currentStyles);
        }

        if( !tchecked && inArray(currentStyles, selectedStyle) ){
            var rmIndex = currentStyles.indexOf(selectedStyle);
            currentStyles.splice(rmIndex, 1);
            this.props.updateUserFilters(currentStyles);
        }

        console.log(this.props.user)
    }

    componentDidMount() {
        this.props.fetchFilterData('styles');
    }

    render() {
        var stylesData = this.props.filters.stylesData;
        var stylesArray = stylesData[0];

        return (
            <div style={{ marginTop: 25 }}>
                {stylesArray && stylesArray.map((style, index) => {
                    return (

                        <ul className=""><li>
                            <FormControlLabel className="filtercheck"
                                control={
                                    <Checkbox
                                        onChange={this.handleStyleCheckboxChange}
                                        color="secondary"
                                        value={style.style_id}
                                        checked={this.props.user.userStyles.includes(style.style_id)}
                                    />}
                                label={style.title}
                                labelPlacement="end"
                            />
                        </li></ul>
                    );
                })}
            </div>
        )
    }


}

Styles.propTypes = {
    fetchFilterData: PropTypes.func.isRequired,
    updateUserFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    filters: state.filters,
    user: state.user
})

export default connect(mapStateToProps,
    { fetchFilterData, updateUserFilters })
    (Styles);
