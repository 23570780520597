import React, { Component } from 'react'
import Navigation from '../Header/Navigation';
import { VoiceOver } from './Sections/VoiceOver';
import VoiceArtists from './Sections/VoiceArtists';
import { Footer } from '../Footer/Footer';
import AudioPlayer from '../Dashboard/AudioPlayer/AudioPlayer';

export class About extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="About">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          <VoiceOver />
          <VoiceArtists />
          <AudioPlayer />
        </div>

        <Footer />
      </div>

    )


  }
}

export default About
