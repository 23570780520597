import {
    ADD_TO_SHOPPING_CART,
    REMOVE_FROM_SHOPPING_CART,
    FORCE_DUMMY_STATE,
    LOAD_SHOPPING_CART,
    CLEAR_SHOPPING_CART,
    LOAD_LICENSES,
    UPDATE_ORDER,
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';
import { Auth } from 'aws-amplify'

const getRemoteCart = (userId) => {
    axios({
        baseURL: apiconfig.api.APIURL + 'usercart',
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        params: {
            user_id: userId,
        },
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            let userCart = Object.values(response.data._embedded.usercart[0]);
            if (localStorage.getItem("cartItems")) {
                var localcart = JSON.parse(localStorage.getItem('cartItems'));
            }
            
            let localIds = [];
            if(localcart && localcart.length > 0){
                    
                    localcart.map((data, index) => {
                        let localId = data.id;
                        if(userCart.indexOf(localId) === -1){
                            localIds.push(data.id);
                        }
                    });
            }
            let localcartcount = localIds.length;
            if(localcartcount > 0){
                userCart = [...userCart, ...localIds];
            }
            if (userCart.length > 0) {
                userCart.map((trackid) => {
                    FlikTraxStore.dispatch(addToShoppingCart(trackid));
                });
            }
        });
}

export const mergeRemoteCart = () => {
    Auth.currentAuthenticatedUser()
        .then(user => getRemoteCart(user.username))
        .catch(err => console.log(err));
}

const remoteUpdateShoppingCart = (command, track) => {

    Auth.currentAuthenticatedUser()
        .then(user => {
            axios({
                baseURL: apiconfig.api.APIURL + 'usercart',
                method: 'post',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                data: {
                    command:command,
                    user_id: user.username,
                    item_id: track
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    //console.log(response.data)
                })
        })
        .catch(err => console.log(err));
}

export const loadShoppingCart = (cart) => dispatch => {
    dispatch({
        type: LOAD_SHOPPING_CART,
        payload: cart
    })
}

export const addToShoppingCart = (track) => dispatch => {

    var authenticated = FlikTraxStore.getState().user.isAuthenticated;

    var currentTrackIds = [];
    var currentSc = FlikTraxStore.getState().cart.cartContents;
    for (var i = 0; i < currentSc.length; i++) {
        currentTrackIds.push(currentSc[i].id);
    }

    if (currentTrackIds.includes(track)) {
        return;
    }

    axios({
        baseURL: apiconfig.api.APIURL + 'track/' + track,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        //timeout: 2000,
    })
        .then(response => {

            dispatch({
                type: ADD_TO_SHOPPING_CART,
                payload: response.data
            })

            switch (authenticated) {
                case true:
                    remoteUpdateShoppingCart('add', track);
                    setTimeout(() => {
                        localStorage.setItem('cartItems', JSON.stringify(FlikTraxStore.getState().cart.cartContents));
                    }, 1000);                    
                    break;

                default:
                    setTimeout(() => {
                        localStorage.setItem('cartItems', JSON.stringify(FlikTraxStore.getState().cart.cartContents));
                    }, 1000);
            }

        });
}

export const clearShoppingCart = () => dispatch => {
    Auth.currentAuthenticatedUser()
    .then(user => {
        axios({
            baseURL: apiconfig.api.APIURL + 'usercart',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                command:'purge',
                user_id: user.username,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                dispatch({
                    type: CLEAR_SHOPPING_CART,
                })
            })
    })
    .catch(err => console.log(err));
}

export const removeShoppingCartItem = (track) => dispatch => {

    var authenticated = FlikTraxStore.getState().user.isAuthenticated;

    dispatch({
        type: REMOVE_FROM_SHOPPING_CART,
        payload: track
    })

    switch (authenticated) {
        case true:
            remoteUpdateShoppingCart('delete',track);
            setTimeout(() => {
                localStorage.setItem('cartItems', JSON.stringify(FlikTraxStore.getState().cart.cartContents));
            }, 1000);
            break;

        default:
            setTimeout(() => {
                localStorage.setItem('cartItems', JSON.stringify(FlikTraxStore.getState().cart.cartContents));
            }, 1000);
    }


}

export const forceUpdate = (dstate) => dispatch => {
    dispatch({
        type: FORCE_DUMMY_STATE,
        payload: !dstate
    })
}

export const loadLicenses = (licenses) => dispatch => {
    dispatch({
        type: LOAD_LICENSES,
        payload: licenses
    })
}

export const updateOrder = (type, data) => dispatch => {
    
    var cOrder = FlikTraxStore.getState().cart.currentOrder;
    var kOrder = Object.keys(FlikTraxStore.getState().cart.currentOrder);

    kOrder.map((codata, index) => {
        cOrder[type] = data;
        dispatch({
            type: UPDATE_ORDER,
            payload: cOrder
        })

    });

}
