import React, { Component } from 'react';
import { Auth } from 'aws-amplify'
import { updateOrder, clearShoppingCart } from '../../FlikTraxStore/Actions/cartActions';
import { enqueueSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';
import { apiIsLoading } from '../../FlikTraxStore/Actions/apiActions';
import DropIn from "braintree-web-drop-in-react";
import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import FlikTraxLoader from '../../Dashboard/Loader/Loading';
import Button from '@material-ui/core/Button';
import Confirmation from './Confirmation';
import Typography from '@material-ui/core/Typography';

class BraintreePayment extends Component {

    constructor(props) {
        super(props)

        this.state = {
            viewpage: "payment",
            clientToken: null,
            paymentToken: null,
            paybuttontxt: "PLACE ORDER"
        }

    }

    goBack() {
        window.location.reload();
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const cartlisten = document.createElement("script");
        cartlisten.src = "/assets/js/cart-listener.js";
        cartlisten.async = true;
        document.body.appendChild(cartlisten);
        this.props.updateOrder('orderTracks', this.props.cart.cartContents);
        this.props.apiIsLoading(true);
        this.props.updateOrder('clientNoonce', '');

        Auth.currentAuthenticatedUser()
            .then(user => {
                var cartObj = []
                this.props.cart.cartContents.map((data, index) => {
                    cartObj.push(data.id);
                });
                this.props.updateOrder('orderTrackIds', cartObj);
                this.cartJson = JSON.stringify(cartObj);
                this.username = user.username;
                this.useremail = user.attributes.email;
            })
            .then(response => {
                //console.log(response.data)
            })
            .catch(err => console.log(err));

        const tokenresponse = await axios({
            baseURL: 'https://api.fliktrax.com/services',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                service: 'braintree-noonce',
            },
            headers: {
                "Content-Type": "application/json"
            }
        });

        const clientJSON = tokenresponse;
        this.props.updateOrder('clientNoonce', clientJSON.data.token);
        this.setState({
            clientToken: clientJSON.data.token
        });
        this.props.apiIsLoading(false);
    }

    async triggerTransaction() {
        this.props.apiIsLoading(true);
        try {
            const { nonce } = await this.instance.requestPaymentMethod();
            this.props.updateOrder('saleToken', nonce);
            this.setState({ paymentToken: nonce })

            axios({
                baseURL: apiconfig.api.APIURL + 'services',
                method: 'post',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                data: {
                    service: 'braintree-transaction',
                    noonce: nonce,
                    amount: this.props.cart.currentOrder.total,
                    userid: this.username,
                    useremail: this.useremail,
                    license : this.props.cart.currentOrder.selectedLicense,
                    cart: this.cartJson
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.data.response.success) {
                        this.props.updateOrder('transaction', response.data);
                        this.setState({ viewpage: "paymentsuccess", })
                        this.props.clearShoppingCart();
                        localStorage.setItem('cartItems', []);
                        this.props.enqueueSnackbar({
                            message: "Thank you for your order!",
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                preventDuplicate: true,
                                autoHideDuration: 10000
                            },
                        });
                    } 
                    else {
                        //console.log(response.data)
                        this.props.enqueueSnackbar({
                            message: "Error: " + response.data.response.message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                preventDuplicate: true,
                                autoHideDuration: 10000
                            },
                        });
                    }
                });
        } catch (err) {
            this.props.apiIsLoading(false);
            this.props.enqueueSnackbar({
                message: "Please select a payment method!",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    preventDuplicate: true,
                    autoHideDuration: 1500
                },
            });
            return;
        }
        this.props.apiIsLoading(false);
    }

    render() {

        if (this.state.viewpage === 'paymentsuccess') {
            localStorage.removeItem("appliedcoupon");
            localStorage.removeItem("couponapplied");
            localStorage.removeItem("couponFirstRun");
            return (
                <FlikTraxLoader active={this.props.api.isLoading}>
                    <Confirmation />
                </FlikTraxLoader>
            )
        } else {

            if (!this.state.clientToken) {
                return (
                    <div>
                        <h1>Loading...</h1>
                    </div>
                );
            } else {
                var clientConfig = { authorization: this.props.cart.currentOrder.clientNoonce, paypal: { flow: 'vault' } }
                return (
                    <FlikTraxLoader active={this.props.api.isLoading}>
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <Typography style={{ color: "black" }} variant="h5" gutterBottom>
                                    Total: ${this.props.cart.currentOrder.total.toFixed(2)}
                                </Typography>
                            </div>
                            <DropIn
                                options={clientConfig}
                                onInstance={instance => (this.instance = instance)}
                            />
                            <div style={{ padding: 25 }}>
                                <Button
                                    id="cartPaymentButton"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.triggerTransaction.bind(this)}
                                >
                                    {this.state.paybuttontxt}
                                </Button>
                            </div>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={this.goBack.bind(this)}
                            >
                                Back
                        </Button>
                        </div>
                    </FlikTraxLoader>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    api: state.api,
    notifications: state.snackbar,
    user: state.user
})

BraintreePayment.propTypes = {
    updateOrder: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.func.isRequired
}


export default connect(mapStateToProps, { updateOrder, clearShoppingCart, apiIsLoading, enqueueSnackbar })(BraintreePayment)