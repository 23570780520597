import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import FlikTraxStore from '../../FlikTraxStore/FlikTraxStore';
import { enqueueSnackbar} from '../../FlikTraxStore/Actions/snackbarActions';
import { applyCoupon } from '../../FlikTraxStore/Actions/ordersActions';
import OrderList from './OrderComps/OrderList';
import couponsconfig from '../../../config/coupons-config.js';
import CloseIcon from '@material-ui/icons/Close';

function mapStateToProps(state) {

  return {
    cart: state.cart,
    user: state.user,
    api: state.api,
    notifications: state.snackbar
  };
}

function OrderTable() {

  const [state, setState] = useState({
    count: 0,
    couponApplied:false
  });

  function handleCouponChange(e) {
    //console.log(e.target.value)
    setState({ couponCode: e.target.value });
  }

  function applyOrderCoupon(){
    var currentCoupons = couponsconfig.coupons;
    var currentLicenseId = localStorage.getItem('selectedLicenseId');

  
    Object.keys(currentCoupons).map((value, index) => {
      if( state.couponCode  === currentCoupons[value].code){
        var affiliate = currentCoupons[value].affiliate;
        //console.log(affiliate)

        FlikTraxStore.dispatch(applyCoupon(affiliate));

        FlikTraxStore.dispatch(
          enqueueSnackbar({
            message: "Coupon code" + state.couponCode + " successfully applied! Please wait ....",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              action: key => (
                <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
              ),
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              preventDuplicate: true,
              autoHideDuration: 1500
            },
          })
        );
      }
    });

    if(currentLicenseId !== null){
        var radiobtn = document.getElementById(currentLicenseId);
        radiobtn.click();
    }

  }

  function renderCouponForm(state) {

    var ccapplied = localStorage.getItem('couponapplied');
    if (ccapplied) {
      var appliedcc = JSON.parse(localStorage.getItem('appliedcoupon'));
      return (
        <div class="row">
          <div style={{ fontSize: "0.85em" }} className="col-md-auto mx-auto">
            Applied coupon <span style={{ color: "green" }}>{appliedcc.code}</span> {(appliedcc.discount * 100).toFixed(2)}% off
        </div>
        </div>
      );

    } else {
      return (
        <React.Fragment>
          <div class="row">
            <div style={{ width: 450 }} className="input-group col-md-auto mx-auto">
              <input id="couponCode" value={state.couponCode} onChange={handleCouponChange} style={{ backgroundColor: "#bfdeff" }} type="text" class="form-control" placeholder="Enter Coupon Code" aria-label="Coupon Code" aria-describedby="coupon" />
              <div class="input-group-append">
                <button onClick={applyOrderCoupon}  class="btn btn-primary" type="button">Apply</button>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }


  }

  useEffect(() => {
    mapStateToProps({ state });
  }, [state]);

  return (
    <div>
      <OrderList />
      {renderCouponForm(state)}
    </div>
  );
}

export default connect(mapStateToProps, {})(OrderTable)
