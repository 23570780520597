// Video Carousel GitHub link:
// https://github.com/xiaolin/react-image-gallery#readme
// Example: https://github.com/xiaolin/react-image-gallery/blob/master/example/app.js

import React from 'react'
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import "../css/image-gallery.css";

//const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

class TvProsCarousel extends React.Component {

  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: true,
      //showVideo: true,
      infinite: true,
      showThumbnails: true,
      autoPlay: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
    };

    this.images = [
      {
        thumbnail: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/thumbnails/hih_tvpros.thumb.jpg`,
        original: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/hih_tvpros_1920.jpg`,
        embedUrl: 'https://tvprosvideo.s3.amazonaws.com/FlikTrax_11418.mp4',
        description: 'Hit It Hard/TVPros',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/thumbnails/frederator_thumb.jpg`,
        original: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/frederator.jpg`,
        embedUrl: 'https://dvtcbpcfza5s9.cloudfront.net/showcase/video/fortnite.mp4',
        description: 'Channel Frederator',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/extinct_alive.jpg`,
        original: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/thumbnails/extinct_alive_thumb.jpg`,
        embedUrl: 'https://dvtcbpcfza5s9.cloudfront.net/showcase/video/aplanet.mp4',
        description: 'Animal Planet',
        renderItem: this._renderVideo.bind(this)
      },
      {
        thumbnail: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/thumbnails/ModernInvestigations_thumb.png`,
        original: `https://dvtcbpcfza5s9.cloudfront.net/showcase/images/ModernInvestigations.png`,
        embedUrl: 'https://dvtcbpcfza5s9.cloudfront.net/showcase/video/Modern_Investigations_FH.mp4',
        description: 'Modern Investigations',
        renderItem: this._renderVideo.bind(this)
      },
    ];
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

//   _handleInputChange(state, event) {
//     this.setState({[state]: event.target.value});
//   }

//   _handleCheckboxChange(state, event) {
//     this.setState({[state]: event.target.checked});
//   }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }

  // _getStaticImages() {
  //   let images = [];
  //   for (let i = 2; i < 12; i++) {
  //     images.push({
  //       original: `${PREFIX_URL}${i}.jpg`,
  //       thumbnail:`${PREFIX_URL}${i}t.jpg`
  //     });
  //   }

  //   return images;
  // }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className='image-gallery-image'>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='embed-responsive embed-responsive-4by3'>
                <Link
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </Link>
                <iframe
                  className="video-wrapper"
                  title="TVPros Showcase"
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <a style={{cursor:"pointer"}} onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div style={{cursor:"pointer"}} className='play-button'></div>
              <img alt="" src={item.original}/>
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (

      
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          lazyLoad={false}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
        />


    );
  }
}

export default TvProsCarousel