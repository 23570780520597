export default {
	coupons: {
		mofilm: {
			affiliate: 'mofilm',
			code: 'MO20',
			discount: .20
		},
		fliktrax: {
			affiliate: 'fliktrax',
			code: 'FLIK20',
			discount: .20
		}
	},
};
