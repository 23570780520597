import {
    FETCH_USER_ORDERS
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import couponsconfig from '../../../config/coupons-config.js';
import FlikTraxStore from '../FlikTraxStore';
import { apiIsLoading } from './apiActions';

export const fetchUserOrders = () => {

    return (dispatch) => {

        dispatch(apiIsLoading(true));
        var currentUser = FlikTraxStore.getState().user.user;
        var userName = currentUser.username;

        var axiosUrl = apiconfig.api.APIURL + 'userorders/' + userName;

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {

                //console.log(response.data)
                dispatch({
                    type: FETCH_USER_ORDERS,
                    payload: response.data
                });
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
}

export const applyCoupon = (affiliate) => dispatch => {
    var coupon = couponsconfig['coupons'][affiliate];
    if (coupon !== undefined) {
        //console.log(coupon)
        var couponpayload = {
            affiliate: affiliate,
            code: coupon.code,
            discount: coupon.discount
        }
        localStorage.setItem('appliedcoupon', JSON.stringify(couponpayload));
        localStorage.setItem('couponapplied', true);
        localStorage.setItem('couponFirstRun', true); 
    }
}
