import React, { Component } from 'react';
import Navigation from '../Header/Navigation';
import  TVProsBanner  from './Sections/TVProsBanner';
import  TVProsShowcase  from './Sections/TVProsShowcase';
import  TVProsClients  from './Sections/TVProsClients';
import { Footer } from '../Footer/Footer';

class TVPros extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          <TVProsBanner authProps={this.props} />
          <TVProsShowcase authProps={this.props} />
          <TVProsClients />

        </div>
          <Footer />
        </div>
    )
  }
}

export default TVPros
