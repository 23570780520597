import React, { Component } from 'react';
import FormErrors from "../../../FormErrors.js";
import Validate from "../../../utility/FormValidation";
import { Auth } from 'aws-amplify';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

class ForgotPassword extends Component {


    constructor(props) {
        super(props);
        console.log(props);
    }


    state = {
        email: "",
        errors: {
            cognito: null,
            blankfield: false
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    }

    forgotPasswordHandler = async event => {
        event.preventDefault();

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }

        // AWS Cognito integration here
        try {
            await Auth.forgotPassword(this.state.email);
            //this.props.history.push('/forgotpasswordverification');
            this.props.switchForgotVerify();
        } catch (error) {
            console.log(error);
        }
    }

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value.trim()
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    }

    render() {
        return (


            <div className="card card-container">

                <h2>
                    Reset Password
                    <Tooltip className="float-right" title="Canel/Close" aria-label="Log In" placement="top">
                        <Button onClick={this.props.closeLoginForm} size="small">
                            <CloseIcon />
                        </Button>
                    </Tooltip>
                </h2>
                <p>
                    Please enter the email address associated with your account and we'll
                    email you verification code to reset your password.
                </p>

                <FormErrors formerrors={this.state.errors} />
                <form onSubmit={this.forgotPasswordHandler}>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.username}
                        onChange={this.onInputChange}
                    />

                    <div style={{ marginTop: "25px" }} className="field">
                        <p className="control">
                            <button className="btn btn-lg btn-primary btn-block btn-signin">
                                Submit
                            </button>
                        </p>
                    </div>

                </form>

            </div>


            //   <section className="section auth">
            //     <div className="container">
            //       <h1>Forgot your password?</h1>
            //       <p>
            //         Please enter the email address associated with your account and we'll
            //         email you a password reset link.
            //       </p>
            //       <FormErrors formerrors={this.state.errors} />

            //       <form onSubmit={this.forgotPasswordHandler}>
            //         <div className="field">
            //           <p className="control has-icons-left has-icons-right">
            //             <input
            //               type="email"
            //               className="input"
            //               id="email"
            //               aria-describedby="emailHelp"
            //               placeholder="Enter email"
            //               value={this.state.email}
            //               onChange={this.onInputChange}
            //             />
            //             <span className="icon is-small is-left">
            //               <i className="fas fa-envelope"></i>
            //             </span>
            //           </p>
            //         </div>
            //         <div className="field">
            //           <p className="control">
            //             <a href="/forgotpassword">Forgot password?</a>
            //           </p>
            //         </div>
            //         <div className="field">
            //           <p className="control">
            //             <button className="button is-success">
            //               Submit
            //             </button>
            //           </p>
            //         </div>
            //       </form>
            //     </div>
            //   </section>
        );
    }
}

export default ForgotPassword;