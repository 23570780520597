import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import renderHTML from 'react-render-html'
import Tooltip from '@material-ui/core/Tooltip';
// import Person from '@material-ui/icons/Person';
// import PersonAdd from '@material-ui/icons/PersonAdd';
// import MenuIcon from '@material-ui/icons/Menu';


export class UserBox extends Component {

    getUserIcon() {

        var boxType = this.props.boxType;
        var icon = '<i style="center-block" className="mx-auto fas ';
        var userIcon = '';
        var tiptitle = '';
        

        switch (boxType) {
            case 'login':
                icon = icon + 'fa-user';
                tiptitle = 'Login';
                break;

            case 'register':
                icon = icon +  'fa-user-plus';
                tiptitle = 'Register';
                break;

            case 'newsletter':
                icon = icon +  'fa-file-alt';
                tiptitle = 'Sign Up for Newsletter';
                break;

            case 'cart':
                icon = icon +  'fa-shopping-cart';
                tiptitle = 'Your Shopping Cart';
                break;

            default:  
        }

        userIcon = icon + ' fa-1x"></i>';
        this.tiptitle = tiptitle;
        
        return renderHTML(userIcon);
    }

    render() {
        
        var userIcon = '';
        userIcon = this.getUserIcon();
        return (
            <Tooltip title={this.tiptitle} placement="left">
            <Box className="text-center h-100 justify-content-center align-self-center"
                bgcolor="#ffffff"
                color="black"
                width={50}
                height={50}
                p={1}
                m={1}
                alignItems="center"
                justify="center"                
                mx="auto"
                zIndex="modal"
            >

                {userIcon}

            </Box>
            </Tooltip>
        )
    }
}

export default UserBox
