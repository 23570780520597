import React, { Component } from 'react'
import '../css/WeKnow.css';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export class WeKnow extends Component {

    render() {

        return (
            <section id="weknow">
                <div className="content">
                    <header>

                        <Typography component="h2">
                            <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                <h2>WE KNOW OUR ARTISTS</h2>
                            </Box>
                        </Typography>
                        <Typography component="h4">
                            <Box textAlign="center" fontWeight={400} fontFamily="Gibson W01 Regular">
                                AND MUSIC LIBRARY THOROUGHLY
                            </Box>
                        </Typography>


                        <Box className="personal" style={{ backgroundColor: "rgba(255, 255, 255, 0.25)", color: "black" }} textAlign="left" fontWeight={400} fontFamily="Gibson W01 Regular" >
                            We personally review and approve every artist and track that we make available on FlikTrax.com to ensure only the highest quality music is offered. This firsthand experience assists us in consistently locating the most viable solutions for you.
                        </Box>


                    </header>
                </div>
                <a href="#werealize" className="goto-next scrolly">Next</a>
            </section>

        )
    }
}

export default WeKnow