export default {
    voartists: [
        [
            {
                "_id": "vo4",
                "track_title": "Narration Demo V2 Mix",
                "artist_name": "Connie Martino",
                "profile_image": "/images/voartists/connie_martino.jpg",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/connie_martino_narration_demo_v2_mix_103019.mp3"
            },
            {
                "_id": "vo5",
                "track_title": "Commercial Demo V2 Mix",
                "artist_name": "Connie Martino",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/connie_martino_commercial_demo_v2_mix_082319.mp3"
            }
        ],
        [
            {
                "_id": "vo3",
                "track_title": "Voiceover Demo",
                "artist_name": "Colin Cosell",
                "profile_image": "/images/voartists/colin_coswell.jpg",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/colin_cosell_vo_demo.mp3"
            }
        ],
        [
            {
                "_id": "vo6",
                "track_title": "Commercial Voiceover",
                "artist_name": "Patrick Finnegan",
                "profile_image": "/images/voartists/patrick_finnegan.jpg",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/pat_finnegan_commercial_vo.mp3"
            },
            {
                "_id": "vo7",
                "track_title": "Corporate Voiceover",
                "artist_name": "Patrick Finnegan",
                "profile_image": "/images/voartists/patrick_finnegan.jpg",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/pat_finnegan_corporate_voice_over_.mp3"
            },
            {
                "_id": "vo8",
                "track_title": "Science Narration",
                "artist_name": "Patrick Finnegan",
                "profile_image": "/images/voartists/patrick_finnegan.jpg",
                "preview_url": "https://dvtcbpcfza5s9.cloudfront.net/voiceovers/Patrick-Finnegan-Science+Narration.mp3"
            }
        ]
    ]
}