import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchApiData, switchMasterMode, switchApiPage, switchApiUrl } from '../../FlikTraxStore/Actions/apiActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import HomeSearch from '../../Header/HomeSearch';
import UnAuthPlaylistCard from './UnAuthPlaylistCard';
import './css/UserPlaylist.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import './css/UserPlaylist.css';

class UserPlaylistUnAuth extends Component {

    constructor(props) {
        super(props);

        this.props = props;

        this.isAuthenticated = props.user.isAuthenticated;
    }

    render() {

        return (
            <div>
                <section id="userplaylist">
                    <div class="container">

                        <div className="content">
                            <header>
                                <Typography component="h2">
                                    <Box textAlign="center" fontWeight={900} fontFamily="Gibson W01 SemiBold">
                                        <h2>MY PLAYLIST</h2>
                                    </Box>
                                </Typography>
                            </header>
                            <div>
                                <Container style={{ marginTop: "10vh" }}>

                                    <Grid container spacing={1}>
                                        <Grid className="searchbar" item sm={12}>
                                            <HomeSearch rdxProps={this.props} />
                                        </Grid>
                                        <Grid style={{ marginTop: 25, minHeight: 500, color: "black" }} item sm={12}>
                                            <div className="mx-auto">
                                                <UnAuthPlaylistCard />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        )
    }
}

UserPlaylistUnAuth.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    fetchApiData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user,
    api: state.api,
})

export default connect(mapStateToProps,
    {
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
        fetchApiData
    })(UserPlaylistUnAuth);    