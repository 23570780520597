import React, { Component } from 'react'

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchMiniNewestData } from '../../FlikTraxStore/Actions/apiActions';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import '../css/SearchTracks.css'

class SearchTracks extends Component {

    redirectDash = (e) => {
        var dataset = e.currentTarget.dataset;
        var mode = dataset.mode;
        var rdUrl = "/dashboard?mode=" + mode;
        window.location.replace(rdUrl);
      }


    componentWillMount() {
        this.props.fetchMiniNewestData();
    }


    render() {
        var tindex = 0
        return (
            <section id="searchtracks">
                <div className="content">
                    <header>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>Search Tracks</h2>
                            </Grid>
                            <Grid item xs={12} only={['md', 'xl']}>
                                <h4>Search Tracks by Categories or Keywords &amp; use filters to narrow your search</h4>
                            </Grid>
                        </Grid>
                    </header>
                    <div className="linkblock">

                        <Grid container spacing={5}>

                            <Grid item xs={12} sm={6}>
                                <h4>Music Genres</h4>
                                <Table className="linkblock2" fluid >
                                    <TableRow style={{ whiteSpace: "nowrap" }}><TableCell align="left"><Link data-mode="categories" onClick={this.redirectDash}>Production Genres</Link></TableCell><TableCell><Link data-mode="moods" onClick={this.redirectDash}>Moods &amp; Emotion</Link></TableCell></TableRow>
                                    <TableRow ><TableCell align="left" ><Link data-mode="instruments" onClick={this.redirectDash}>Instrumentation</Link></TableCell><TableCell align="left"><Link data-mode="styles" onClick={this.redirectDash}>Musical Styles</Link></TableCell></TableRow>
                                    <TableRow ><TableCell align="left"><Link to="/new-releases">New Releases</Link></TableCell><TableCell align="left"><Link to="my-playlist">My Playlist</Link></TableCell></TableRow>
                                    <TableRow ><TableCell align="left">&nbsp;</TableCell><TableCell align="left"></TableCell></TableRow>

                                </Table>
                                <h5><Link to="/dashboard">Search All Genres ></Link></h5>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h4>New Tracks</h4>
                                <Table className="linkblock2" fluid>
                                    {this.props.api && this.props.api.miniNewestData.map((data, index) => {
                                        tindex++;
                                        if (tindex < 5)
                                            return (
                                                <TableRow><TableCell align="left" >{data.track_title}</TableCell><TableCell align="left">{data.artist_name}</TableCell></TableRow>
                                            )

                                    })}
                                </Table>
                                <h5><Link to="/dashboard">Search All Tracks ></Link></h5>
                            </Grid>

                        </Grid>
                    </div>

                    <div className="row actions center-block">

                        <Grid container style={{ marginTop: 100 }} spacing={5}>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/dashboard" className="searchButtons btn-block">SEARCH TRACKS</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Link to="/custom" className="searchButtons btn-block" >CUSTOM MUSIC</Link>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            </Grid>
                        </Grid>

                    </div>

                </div>

                <a style={{ color: 'black' }} href="#whatsnew" className="goto-next-black scrolly">Next</a>
            </section>
        )
    }
}

SearchTracks.propTypes = {
    fetchMiniNewestData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
})

export default connect(mapStateToProps,
    {
        fetchMiniNewestData
    })(SearchTracks);
