export const FETCH_API_DATA = 'FETCH_API_DATA';
export const SWITCH_MASTER_MODE = 'SWITCH_MASTER_MODE';
export const SWITCH_API_URL = 'SWITCH_API_URL';
export const SWITCH_API_PAGE = 'SWITCH_PAGE';
export const UPDATE_API_DATA = 'UPDATE_API_DATA';
export const UPDATE_PAGE_CHANGE = 'UPDATE_PAGE_CHANGE';
export const UPDATE_TRACK_PAGE_TITLE = 'UPDATE_TRACK_PAGE_TITLE';
export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const IS_PLAYLIST_PAGE = 'IS_PLAYLIST_PAGE';
export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const UPDATE_AUTHENTICATING = 'UPDATE_AUTHENTICATING';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_GROUPS = 'SET_AUTH_GROUPS';
export const FETCH_USER_FILTERS = 'FETCH_USER_FILTERS';
export const CLEAR_USER_FILTERS = 'CLEAR_USER_FILTERS';
export const UPDATE_USER_FILTERS = 'UPDATE_USER_FILTERS';
export const SWITCH_PLAYER_MODE = 'SWITCH_PLAYER_MODE';
export const UPDATE_USER_VOCAL_TYPE = 'UPDATE_USER_VOCAL_TYPE';
export const UPDATE_USER_BPM = 'UPDATE_USER_BPM';
export const UPDATE_USER_DURATION = 'UPDATE_USER_DURATION';
export const UPDATE_CURRENT_ROUTE = 'UPDATE_CURRENT_ROUTE';
export const ADD_TO_PLAYLIST = 'ADD_TO_PLAYLIST';
export const LOAD_LOCAL_PLAYLIST  = 'LOAD_LOCAL_PLAYLIST';
export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const CLEAR_SHOPPING_CART  = 'CLEAR_SHOPPING_CART';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';
export const GET_USER_SHOPPING_CART = 'GET_USER_SHOPPING_CART';
export const REMOVE_FROM_PLAYLIST = 'REMOVE_FROM_PLAYLIST';
export const UPDATE_LOCAL_PLAYLIST = 'UPDATE_LOCAL_PLAYLIST';
export const UPDATE_FILTER_DRAWER_STATUS =  'UPDATE_FILTER_DRAWER_STATUS'; 
export const UPDATE_NOW_PLAYING = 'UPDATE_NOW_PLAYING';
export const UPDATE_IS_PLAYING = 'UPDATE_IS_PLAYING'; 
export const UPDATE_POSITION = 'UPDATE_POSITION'; 
export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_AXIOS_LOADING = 'UPDATE_AXIOS_LOADING'; 
export const AXIOS_IS_LOADING = 'AXIOS_IS_LOADING';
export const FETCH_MINI_NEWEST = 'FETCH_MINI_NEWEST';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const FORCE_DUMMY_STATE =  'FORCE_DUMMY_STATE';
export const  LOAD_SHOPPING_CART = 'LOAD_SHOPPING_CART';
export const  LOAD_LICENSES = 'LOAD_LICENSES';
export const  UPDATE_ORDER = 'UPDATE_ORDER';
export const  APPLY_COUPON = 'UPDATE_SAVED_PLAYLIST_COUNT';
export const  FETCH_USER_ORDERS = 'APPLY_COUPON';
export const  UPDATE_SAVED_PLAYLIST_COUNT = 'UPDATE_SAVED_PLAYLIST_COUNT';
export const SET_CURRENT_SEARCH =  'SET_CURRENT_SEARCH';
export const SET_CURRENT_SEARCH_TERM =  'SET_CURRENT_SEARCH_TERM';

