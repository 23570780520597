import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../../FlikTraxStore/Actions/snackbarActions';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import '../css/SearchBar.css';

class SearchBar extends Component {

  constructor(props) {
    super(props);

    this.smode = React.createRef();

    this.rdxProps = props.rdxProps;

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);

    this.searchTerms = React.createRef();

    this.state = {
      smode: 1,
      searchterms: ''
    };
  }

  encIOSURL(str) {
    var encStr = str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
    return encStr;
  }

  onSearchClick = e => {
    var mixlist = document.getElementsByClassName('mixcontainer');
    for (var i = 0; i < mixlist.length; i++) {
      mixlist[i].innerHTML = "";
    }
    var searchType = this.state.smode;
    var searchterm = document.getElementById("dashsearch");
    var searchstr = searchterm.value.trim();
    if (searchstr === "") {
      e.preventDefault();
      this.props.enqueueSnackbar({
        message: "Please enter a search term!",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          action: key => (
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
          ),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          preventDuplicate: true,
          autoHideDuration: 1500
        },
      });
      return;
    }
    var iOSstr =  this.encIOSURL(searchstr);
    var encsearch = encodeURIComponent(iOSstr);
    var apiUrl = 'search?searchtype=' + searchType + '&searchterm=' + encsearch;
    this.rdxProps.switchMasterMode('tracks');
    this.rdxProps.switchApiPage(1);
    this.rdxProps.switchApiUrl(apiUrl);
    this.rdxProps.fetchApiData();
  };


  onKeyPressed(e) {
    //e.preventDefault();
    var mixlist = document.getElementsByClassName('mixcontainer');
    for (var i = 0; i < mixlist.length; i++) {
      mixlist[i].innerHTML = "";
    }
    if (e.key === "Enter") {
      var searchType = this.state.smode;
      var searchterm = e.currentTarget.value;
      var searchstr = searchterm.trim();
      if (searchstr === "") {
        e.preventDefault();
        this.props.enqueueSnackbar({
          message: "Please enter a search term!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            action: key => (
              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
            ),
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            preventDuplicate: true,
            autoHideDuration: 1500
          },
        });
        return;
      }
      var iOSstr =  this.encIOSURL(searchstr);
      var encsearch = encodeURIComponent(iOSstr);
      var apiUrl = 'search?searchtype=' + searchType + '&searchterm=' + encsearch;
      this.rdxProps.switchMasterMode('tracks');
      this.rdxProps.switchApiPage(1);
      this.rdxProps.switchApiUrl(apiUrl);
      this.rdxProps.fetchApiData();
    }
  }

  handleModeChange(e) {
    var checkMode = e.target.checked === true ? 2 : 1
    this.setState({ smode: checkMode });
    //this.setState({ ...this.state, [name]: event.target.checked });
    //console.log(this.state);
  };

  render() {
    return (
      // <input style={{borderRadius:"0px", marginBottom:25}} onKeyDown={this.onKeyPressed} type="text" name="home_search" id="home_search" placeholder="Exotic Dreamy Compassionate"></input>

      <div className="input-group mb-3">
        <input ref={this.searchTerms} id="dashsearch" onKeyDown={this.onKeyPressed} style={{ height: 45 }} type="text" className="form-control" placeholder="Exotic Dreamy Compassionate" aria-label="Search" aria-describedby="basic-addon2" />
        <div className="input-group-append mx-auto" style={{ height: 45 }}>
          <span style={{ height: 45, fontFamily: "Gibson W01 SemiBold", color: "#74777a" }} className="input-group-text" id="basic-addon2">
            <span class="input-group-append">
              <Link onClick={this.onSearchClick} class="input-group-text" id="searchbtn" style={{ color: "white", backgroundColor: "#0e6bae" }}><i class="fas fa-search fa-1x"></i></Link>
            </span>


            {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
            <FormControl className="d-none d-sm-block" component="fieldset" style={{ height: 45 }}>
              <FormGroup aria-label="position" name="position" value={this.value} onChange={this.handleModeChange} row>
                <FormControlLabel
                  ref={this.smode}
                  id="smode"
                  name="smode"
                  value="exact"
                  control={<Switch color="primary" />}
                  label="Exact Search"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>

          </span>
        </div>
      </div>


    )
  }
}


SearchBar.propTypes = {
  removeSnackbar: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  notifications: state.snackbar
})

export default connect(mapStateToProps,
  {
    enqueueSnackbar,
    closeSnackbar,
    removeSnackbar
  })(SearchBar);
