import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../FlikTraxStore/Actions/userActions';
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from '../FlikTraxStore/Actions/snackbarActions';

// import UserSettings from './Forms/UserSettings/UserSettings'; 
import ProfileOptions from './Forms/UserSettings/ProfileOptions';
import MiniShoppingCart from './Forms/MiniShoppingCart';

import { Auth } from 'aws-amplify';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import Settings from '@material-ui/icons/Settings';
import LibraryMusic from '@material-ui/icons/LibraryMusic';
import SavedPlaylistsIcon from '@material-ui/icons/FeaturedPlayList';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Videocam from '@material-ui/icons/Videocam';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';

//import SettingsForm from './Forms/SettingsForm';
//import RegisterForm  from './Forms/RegisterForm';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import './css/UserNav.css';


class ProfileTabsUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0,
            isSettingsHidden: true,
            isOptionsHidden: true,
            isPlaylistsHidden: true,
            isNewsHidden: true,
            isShoppingHidden: true,
            isCartHidden: true,
            playlistCount: null
        }

        this.redirectPage = this.redirectPage.bind(this);
    }

    componentDidMount(){
        this.setState({playlistCount:this.props.user.userPlaylist.length});
    }

    handleClickAway() {
        this.resetHidden();
    }

    resetHidden() {
        this.setState({
            isSettingsHidden: true,
            isPlaylistsHidden: true,
            isNewsHidden: true,
            isShoppingHidden: true,
            isCartHidden: true
        });
    }

    closeOptionsTab() {
        this.setState({ isOptionsHidden: true })
      }

    toggleSettingsHidden() {
        this.setState({
            isSettingsHidden: !this.state.isSettingsHidden
        })
    }

    togglePlaylistsHidden() {
        this.setState({
            isPlaylistsHidden: !this.state.isPlaylistsHidden
        })
    }

    toggleCartHidden() {
        this.setState({
            isCartHidden: !this.state.isCartHidden
        })
    }

    SettingsContainer() {
        return (
            <TabContainer >
                <div style={{minHeight:400}} className="tabContainer">
                    {/* <UserSettings /> */}
                    <ProfileOptions closeOptionsForm={this.closeOptionsTab.bind(this)} />
                </div>
            </TabContainer>
        );
    }

    RegisterContainer() {
        return (
            <TabContainer >
                <div className="tabContainer">
                    {/* <RegisterForm /> */}
                </div>
            </TabContainer>
        );
    }

    CartContainer() {
        return (
            <TabContainer >
                <div className="tabContainer">
                    <MiniShoppingCart />
                </div>
            </TabContainer>
        );
    }

    handleChange = (_, activeIndex) => this.setState({ activeIndex });


    redirectPage() {
        switch (window.location.pathname) {
            case '/':
                window.location.reload();
                break;

            default:
                this.props.history.push('/');
        }
    }


    handleLogOut = event => {
        event.preventDefault();
        try {
            Auth.signOut();
            this.props.updateAuthStatus(false);
            this.props.setAuthUser(null);
            this.redirectPage();
        } catch (error) {
            console.log(error)
        }

        setTimeout(() => {
            this.redirectPage();
        }, 100);
    }

    getScIcon() {
        if (this.props.cart.cartContents.length > 0) {
            return (
                <Badge badgeContent={this.props.cart.cartContents.length} color="primary">
                    <ShoppingCart style={{ fontSize: 30, color: 'black' }} />
                </Badge>
            )
        } else {
            return (
                <ShoppingCart style={{ fontSize: 30, color: 'black' }} />
            )
        }
    }

    getPlaylistIcon() {
        if (this.props.user.userPlaylist.length > 0) {
            return (
                <Badge badgeContent={this.props.user.userPlaylist.length} color="primary">
                    <LibraryMusic style={{ fontSize: 30, color: 'black' }} />
                </Badge>
            )
        } else {
            return (
                <LibraryMusic style={{ fontSize: 30, color: 'black' }} />
            )
        }
    }

    getSavedPlaylistIcon = () => {
        if (this.props.user.savedPlaylistCount > 0) {
            return (
                <Badge badgeContent={this.props.user.savedPlaylistCount} color="primary">
                    <SavedPlaylistsIcon style={{ fontSize: 30 }} />
                </Badge>
            )
        } else {
            return (
                <SavedPlaylistsIcon style={{ fontSize: 30 }} />
            )
        }
    }

    videoNotify() {
        var notification = 'Coming in November 2019!';
        this.props.enqueueSnackbar({
            message: notification,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                action: key => (
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.props.closeSnackbar(key)} />
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                preventDuplicate: true,
                autoHideDuration: 1500
            },
        });
    }

    render() {
        const { activeIndex } = this.state;

        return (
            <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
                <div
                    style={{
                        display: 'flex',
                        position: 'fixed',
                        top: 15,
                        right: 0
                    }}
                >
                    {activeIndex === 0 && this.resetHidden.bind(this) && !this.state.isSettingsHidden &&
                        this.SettingsContainer()
                    }

                    {activeIndex === 1 && this.resetHidden.bind(this) && !this.state.isRegisterHidden &&
                        this.RegisterContainer()
                    }

                    {activeIndex === 3 && this.resetHidden.bind(this) && !this.state.isCartHidden &&
                        this.CartContainer()
                    }

                    <VerticalTabs id="userTabs"
                        style={{ width: 65, textAlign: 'center', maxHeight: 400 }}
                        value={activeIndex}
                        onChange={this.handleChange}
                    >

                        <ProfileTab onClick={this.toggleSettingsHidden.bind(this)} style={{ marginTop: '-15px' }} label={
                            <Tooltip title="Edit Profile" aria-label="Edit Profile" placement="left-start">
                                <Typography className="userIcons" component="div">
                                    <Settings style={{ fontSize: 30, color: 'black' }} />
                                </Typography>
                            </Tooltip>
                        } />


                        <Link to="/my-playlist">
                            <ProfileTab style={{ marginTop: 10 }} label={
                                <Tooltip title="My Playlist" aria-label="My Playlist" placement="left-start">
                                    <Typography className="userIcons" component="div">
                                        {this.getPlaylistIcon()}
                                    </Typography>
                                </Tooltip>
                            } />
                        </Link>

                        <Link to="/saved-playlists">
                            <ProfileTab style={{ marginTop: 10 }} label={
                                <Tooltip title="Saved Playlists" aria-label="Saved Playlists" placement="left-start">
                                    <Typography className="userIcons" component="div">
                                    {this.getSavedPlaylistIcon()} 
                                    </Typography>
                                </Tooltip>
                            } />
                        </Link>      

                        {/* <Link to="/video"> */}
                            {/* <ProfileTab onClick={this.videoNotify.bind(this)} style={{ marginTop: 10 }} label={
                                <Tooltip title="Video Sync Tool" aria-label="Video Sync Tool" placement="left-start">
                                    <Typography className="userIcons" component="div">
                                        <Videocam style={{ fontSize: 30, color: 'black' }} />
                                    </Typography>
                                </Tooltip>
                            } /> */}
                        {/* </Link> */}

                        <ProfileTab onClick={this.toggleCartHidden.bind(this)} style={{ marginTop: 10 }} label={
                            <Tooltip title="Shopping Cart" aria-label="Shopping Cart" placement="left-start">
                                <Typography className="userIcons" component="div">
                                    {this.getScIcon()}
                                </Typography>
                            </Tooltip>
                        } />

                        <ProfileTab onClick={this.handleLogOut} style={{ marginTop: 10, marginBottom: 25 }} label={
                            <Tooltip title="Log Out" aria-label="Log Out" placement="left-start">
                                <Typography className="userIcons" component="div">
                                    <PowerSettingsNew style={{ fontSize: 30, color: 'black' }} />
                                </Typography>
                            </Tooltip>
                        } />


                    </VerticalTabs>

                </div>
            </ClickAwayListener>
        )
    }
}

const VerticalTabs = withStyles(theme => ({
    flexContainer: {
        flexDirection: 'column',
        marginTop: 25,
    },
    indicator: {
        display: 'none'
    }
}))(Tabs)

const ProfileTab = withStyles(theme => ({
    selected: {

    }
}))(Tab);

function TabContainer(props) {
    return (

        <div id="userNavigation">
            <Typography id="userTabs" component="div">
                <div style={{ marginTop: 55 }}>
                    {props.children}
                </div>
            </Typography>
        </div>
    );
}

ProfileTabsUser.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    user: state.user,
    cart: state.cart,
    notifications: state.snackbar
});

ProfileTabsUser.propTypes = {
    clearShoppingCart: PropTypes.func.isRequired,
  }

export default connect(mapStateToProps,
    {
        updateAuthStatus,
        setAuthUser,
        updateAuthenticating,
        enqueueSnackbar,
        closeSnackbar,
        removeSnackbar
    })(withRouter(ProfileTabsUser));