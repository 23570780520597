import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { switchMasterMode, fetchApiData, switchApiPage, switchApiUrl, setCurrentSearch, setCurrentSearchTerm } from '../../FlikTraxStore/Actions/apiActions';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
//import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

function TrackKeywords(props) {


    const classes = useStyles();

    const [tagsopen, handleTagsOpen] = useState(false);

    const arrayUnique = (arr) => {
        return [...new Set(arr)];
    }

    const onMoreClick = () => {

        const searchArray = arrayUnique(props.genres.concat(props.styles).concat(props.moods));
        const searchString = searchArray.join(" ");
        var apiUrl = 'search?searchmode=similar&searchtype=1&searchterm=' + encodeURIComponent(searchString);
        props.setCurrentSearchTerm('more', null);
        props.switchMasterMode('tracks');
        props.switchApiPage(1);
        props.switchApiUrl(apiUrl);
        props.setCurrentSearch(props.trackid);
        let currentPath = props.location.pathname;
        if (currentPath === '/dashboard') {
            props.fetchApiData();
        } else {
            props.history.push('/dashboard');
        }
        window.scrollTo(0, 0)
    };


    const onTagClick = (item) => {
        var apiUrl = 'search?searchmode=tvpros&searchtype=2&searchterm=' + item;
        props.setCurrentSearchTerm('tag', item);
        props.switchMasterMode('tracks');
        props.switchApiPage(1);
        props.switchApiUrl(apiUrl);
        props.setCurrentSearch(null);
        let currentPath = props.location.pathname;
        if (currentPath === '/dashboard') {
            props.fetchApiData();
        } else {
            props.history.push('/dashboard');
        }
        window.scrollTo(0, 0)
    };

    const handleClickAway = () => {
        handleTagsOpen(false);
    };

    if (props.keywords && props.keywords.length > 0) {
        return (

            <div className={classes.root}>

                <ClickAwayListener onClickAway={handleClickAway}>
                    <Chip
                        size="small"
                        label="Tags"
                        //avatar={tagsopen === false ? <Avatar><i class="fa fa-chevron-circle-down"></i></Avatar> : <Avatar><i class="fa fa-chevron-circle-up"></i></Avatar>}
                        clickable={true}
                        onClick={e => handleTagsOpen(!tagsopen)}
                    />
                </ClickAwayListener>

                <Chip
                    size="small"
                    label="More Like This"
                    //avatar={<Avatar><i class="fa fa-plus-circle"></i></Avatar>}
                    // //color="primary"
                    clickable={true}
                    onClick={() => onMoreClick()}
                />




                <Box component="span" display={tagsopen === true ? "block" : "none"} p={2} m={2} bgcolor="white">
                    {props.keywords.map((keyword) => (
                        <Chip
                            style={{marginRight:2,marginTop:5}}
                            size="small"
                            label={keyword}
                            color="primary"
                            //clickable={true}
                            onClick={() => onTagClick(keyword)}
                        />
                    ))}
                </Box>



            </div>
        );
    } else {
        return (
            <div className={classes.root}></div>
        )
    }
}

function mapStateToProps(state) {
    return { api: state.api };
}

TrackKeywords.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    fetchApiData: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    setCurrentSearch: PropTypes.func.isRequired,
    setCurrentSearchTerm: PropTypes.func.isRequired
}


export default connect(mapStateToProps, {switchMasterMode, 
                                         switchApiPage, 
                                         switchApiUrl, 
                                         fetchApiData, 
                                         setCurrentSearch, 
                                         setCurrentSearchTerm 
                                        })(TrackKeywords);