import React, { Component } from 'react';
import Navigation from '../Header/Navigation';
import  HitItHardBanner  from './Sections/HitItHardBanner';
import  HitItHardShowCase  from './Sections/HitItHardShowCase';
import  Clients  from '../CustomMusic/Sections/Clients';
import { Footer } from '../Footer/Footer';

class TVPros extends Component {

  componentDidMount() {
    const mainscript = document.createElement("script");
    mainscript.src = "/assets/js/main-external.js";
    mainscript.async = true;
    document.body.appendChild(mainscript);

    const bsScript = document.createElement("script");
    bsScript.src = "/node_modules//node_modules/bootstrap/dist/js/bootstrap.min.js";
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="App">
        <div id="page-wrapper">
          <Navigation authProps={this.props} />
          {/* {!this.isAuthenticated && (
           <UserNav authProps={this.props} />
          )} */}
          <HitItHardBanner authProps={this.props} />
          <HitItHardShowCase authProps={this.props} />
          <Clients />

        </div>
          <Footer />
        </div>
    )
  }
}

export default TVPros
