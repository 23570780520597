import {
    FETCH_API_DATA,
    FETCH_MINI_NEWEST,
    SWITCH_MASTER_MODE,
    SWITCH_API_URL,
    SWITCH_API_PAGE,
    UPDATE_TRACK_PAGE_TITLE,
    IS_PLAYLIST_PAGE,
    SET_CURRENT_SEARCH,
    SET_CURRENT_SEARCH_TERM
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';

function getUserFilters() {
    var styles = FlikTraxStore.getState().user.userStyles;
    var moods = FlikTraxStore.getState().user.userMoods;
    var instruments = FlikTraxStore.getState().user.userInstruments;
    var vocaltype = FlikTraxStore.getState().user.userVocalType;
    var duration = FlikTraxStore.getState().user.userDuration;
    var bpm = FlikTraxStore.getState().user.userBpm;
    var retFilters = {
        styles: styles,
        moods: moods,
        instruments: instruments,
        vocaltype: vocaltype,
        duration: duration,
        bpm: bpm
    }

    return JSON.stringify(retFilters);

}

export const setCurrentSearch = (trackid) => dispatch => {

    if(trackid === null){
        dispatch({
            type: SET_CURRENT_SEARCH,
            payload: null
        });   
        return;
    }

    axios({
        baseURL: 'https://api.fliktrax.com/track/' + trackid,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        //timeout: 2000,
    })

        .then(response => {
            dispatch({
                type: SET_CURRENT_SEARCH,
                payload: response.data
            });
        });
}

export const setCurrentSearchTerm = (type, searchterm) => dispatch => {
    dispatch({
        type: SET_CURRENT_SEARCH_TERM,
        payload: { type: type, searchterm: searchterm }
    })
}

export const fetchApiData = () => {

    var url = FlikTraxStore.getState().api.apiUrl;
    var page = FlikTraxStore.getState().api.apiPage;
    var axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    var masterMode = FlikTraxStore.getState().api.masterMode;
    var filters = getUserFilters();

    switch (masterMode) {
        case 'categories':
            axiosUrl = apiconfig.api.APIURL + url + '?page=' + page;
            break;

        case 'trackdetail':
            axiosUrl = url + '&page=' + page;
            break;

        default:
            axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    }

    return (dispatch) => {

        dispatch(apiIsLoading(true))

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            params: {
                filters: filters
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })
            .then(response => {
                dispatch({
                    type: FETCH_API_DATA,
                    payload: response.data
                });
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};



export const fetchMiniNewestData = () => {

    return (dispatch) => {

        axios({
            baseURL: apiconfig.api.APIURL + 'newest?limit=100',
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {
                dispatch({
                    type: FETCH_MINI_NEWEST,
                    payload: response.data
                });
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};


export const switchMasterMode = (mode) => dispatch => {
    dispatch({
        type: SWITCH_MASTER_MODE,
        payload: mode
    })
}

export const switchApiPage = (page) => dispatch => {
    dispatch({
        type: SWITCH_API_PAGE,
        payload: page
    })
}

export const switchApiUrl = (type) => dispatch => {
    dispatch({
        type: SWITCH_API_URL,
        payload: type
    })
}

export const updateTrackPageTitle = (title) => dispatch => {
    dispatch({
        type: UPDATE_TRACK_PAGE_TITLE,
        payload: title
    })
}

export const apiIsLoading = (bool) => {
    return {
        type: "AXIOS_IS_LOADING",
        payload: {
            isLoading: bool
        }
    };
};

export const updateIsPlaylist = (isplaylist) => dispatch => {
    dispatch({
        type: IS_PLAYLIST_PAGE,
        payload: isplaylist
    })
}

export const downloadFliktraxFile = (fileUrl, absolute = true) => dispatch => {
    var path = require('path');
    var absFile = '';
    switch (absolute) {
        case true:
            absFile = fileUrl;
            break;
        case false:
            absFile = 'https://ftmedia.s3.amazonaws.com/' + fileUrl;
            break;
        default:
    }

    var filename = path.basename(fileUrl);
    axios({
        url: absFile,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
}