
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { updateAuthStatus, setAuthUser, updateAuthenticating } from '../FlikTraxStore/Actions/userActions';
import List from '@material-ui/core/List';
import UserSettings from './UserSettings';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';


class ProfileOptions extends Component {

    state = {
        viewform: 'options',
    };

    getLoginDT() {
        var unixdate = this.props.user.user.signInUserSession.accessToken.payload.iat * 1000;
        var date = new Date(unixdate).toLocaleDateString("en-US");
        var time = new Date(unixdate).toLocaleTimeString("en-US");
        var retDate = date + " " + time;
        return retDate;
    }

    componentDidUpdate() {
        console.log(this.state)
        //this.getLoginDT();
    }


    componentDidMount() {
        this.getLoginDT();
    }

    renderOptions() {
        //let $orderBadge = ( (this.props.orders.ordersCount -1) === 0) ? 0 : this.props.orders.ordersCount
        return (
            <div style={{ paddingLeft: 50, paddingRight: 50 }} className={"mx-auto"} >

                <h3 style={{ marginTop: 10, border: 0 }}>
                    Account and Settings
                    {/* <Tooltip className="float-right" title="Cancel/Close" aria-label="Log In" placement="top">
                        <Button onClick={this.props.closeOptionsForm} size="small">
                            <CloseIcon />
                        </Button>
                    </Tooltip> */}
                </h3>
                <Box alignSelf="flex-end" boxShadow={1} mx="auto" bgcolor="white" p={1}>
                    <List className="profilelink" component="nav" aria-label="main mailbox folders">
                        <a href="/orders">
                            <ListItem button component="a">
                                <ListItemIcon>
                                    <Badge badgeContent={this.props.orders.ordersCount} color="primary">
                                        <i class="fas fa-cart-arrow-down"></i>
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary="My Orders" />
                            </ListItem>
                        </a>
                        <Link onClick={() => this.setState({ viewform: 'settings' })}>
                            <ListItem button component="a">
                                <ListItemIcon>
                                    <i class="fas fa-user-cog"></i>
                                </ListItemIcon>

                                    <ListItemText primary="Login Settings" />

                            </ListItem>
                        </Link>
                    </List>

                    <div className="mx-auto" style={{ marginTop: 75, fontSize: "0.85em", color: "#5666a2", fontStyle: "italic", textAlign: "center" }}>
                        Last login: - {this.getLoginDT()}
                    </div>
                </Box>
            </div>
        );
    }

    render() {
        switch (this.state.viewform) {

            case 'settings':
                return (
                    <UserSettings />
                );

            default:
                return this.renderOptions();
        }

    }
}

const mapStateToProps = state => ({
    user: state.user,
    orders: state.orders
})

export default connect(mapStateToProps, {})(ProfileOptions);