import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader';
import './Loader.css';

export default function FlikTraxLoader({ active, children }) {
  return (
    <LoadingOverlay
      className='flikloader'
      active={active}
      color='blue'
      spinner={<PulseLoader/>}
      //text='Loading ...'    
    >
      {children}
    </LoadingOverlay>
  )
}