import {
    UPDATE_AUTH_STATUS,
    UPDATE_AUTHENTICATING,
    SET_AUTH_USER,
    SET_AUTH_GROUPS,
    UPDATE_USER_FILTERS,
    CLEAR_USER_FILTERS,
    SWITCH_PLAYER_MODE,
    UPDATE_USER_VOCAL_TYPE,
    UPDATE_USER_BPM,
    UPDATE_USER_DURATION,
    UPDATE_CURRENT_ROUTE,
    ADD_TO_PLAYLIST,
    LOAD_LOCAL_PLAYLIST,
    UPDATE_SAVED_PLAYLIST_COUNT,
    REMOVE_FROM_PLAYLIST,
    UPDATE_LOCAL_PLAYLIST,
    UPDATE_FILTER_DRAWER_STATUS
} from '../Actions/types';

const initialState = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    userGroups:[],
    userMoods: [],
    userStyles: [],
    userInstruments: [],
    userVocalType: false,
    userBpm: false,
    userDuration: false,
    userPlaylist: [],
    audioPlayerMode: false,
    currentRoute: ['Dashboard'],
    filterDrawerStatus: false,
    savedPlaylistCount: 0
}

export default function (state = initialState, action) {
    switch (action.type) {

        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                isAuthenticated: action.payload
            }

        case UPDATE_AUTHENTICATING:

            return {
                ...state,
                isAuthenticating: action.payload
            }
            

        case SET_AUTH_USER:

            return {
                ...state,
                user: action.payload
            }

        case SET_AUTH_GROUPS:

                return {
                    ...state,
                    userGroups: action.payload
                }            

        case UPDATE_USER_FILTERS:

            return {
                ...state,
                userFilters: action.payload
            }

        case CLEAR_USER_FILTERS:

            return {
                ...state,
                userMoods: [],
                userStyles: [],
                userInstruments: [],
                userVocalType: false,
                userBpm: false,
                userDuration: false,
                userPlaylist: []
            }

        case UPDATE_USER_VOCAL_TYPE:
            return {
                ...state,
                userVocalType: action.payload
            }

        case UPDATE_USER_BPM:
            return {
                ...state,
                userBpm: action.payload
            }

        case UPDATE_USER_DURATION:
            return {
                ...state,
                userDuration: action.payload
            }


        case SWITCH_PLAYER_MODE:
            return {
                ...state,
                audioPlayerMode: action.payload
            }

        case UPDATE_CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.payload
            }

        case ADD_TO_PLAYLIST:
            var newplaylist = [...state.userPlaylist, action.payload];
            return {
                ...state,
                userPlaylist: newplaylist
            }

        case LOAD_LOCAL_PLAYLIST:
            return {
                ...state,
                userPlaylist: action.payload
            }

        case REMOVE_FROM_PLAYLIST:

            var currentPlaylist = state.userPlaylist

            for (var i = 0; i < currentPlaylist.length; i++) {
                if (currentPlaylist[i] === action.payload) {
                    currentPlaylist.splice(i, 1);
                }
            }

            return {
                ...state,
                userPlaylist: currentPlaylist
            }


        case UPDATE_LOCAL_PLAYLIST:
            return {
                ...state,
                userPlaylist: action.payload
            }

        case UPDATE_SAVED_PLAYLIST_COUNT:

            return {
                ...state,
                savedPlaylistCount: action.payload
            }            

        case UPDATE_FILTER_DRAWER_STATUS:
            return {
                ...state,
                filterDrawerStatus: action.payload
            }



        default:
            return state;
    }
}

